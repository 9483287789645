<template>
  <div class="eo-block-heading">
    <div class="eo-block-heading-blockicon-border">
      <img
        class="eo-block-heading-blockicon"
        :src="require('../assets/glasauswahl/' + blockicon + '.svg')"
        alt=""
      />
    </div>
    <div class="eo-block-heading-title-bar">
      <div class="eo-block-heading-title truncate">
        {{ property }}
      </div>
      <property-tooltipp v-if="infotext" :title="infotitle" :text="infotext" />
    </div>
  </div>
</template>

<script>
import PropertyTooltipp from './prescription-pass/PropertyTooltipp';

import translation from '../mixins/translation';

export default {
  name: 'LensBlockHeadline',
  components: { PropertyTooltipp },
  mixins: [translation],
  props: {
    property: {
      type: String,
      default: '',
    },
    blockicon: {
      type: String,
      required: true,
    },
    infotitle: {
      type: String,
      default: '',
    },
    infotext: {
      type: String,
      default: '',
    },
  },
  computed: {},
  methods: {},
};
</script>

<style scoped>
.eo-block-heading {
  padding-bottom: 20px;
  height: 70px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.eo-block-heading-blockicon {
  width: 100%;
  height: 100%;
  user-select: none;
}
.eo-block-heading-blockicon-border {
  position: absolute;
  width: 50px;
  height: 50px;
  padding: 8px;
  border: 1px solid #000;
  border-radius: 1000px;
  background-color: #fff;
  z-index: 10;
}

.eo-block-heading-title {
  padding-left: 32px;
  padding-right: 8px;
  font-size: 1.2em;
  text-transform: uppercase;
  font-weight: 700;
  color: #fff;
  white-space: nowrap;
}

.eo-block-heading-title-bar {
  background-color: #000;
  margin-left: 25px;
  height: 24px;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.eo-block-heading-title-bar svg {
  margin-top: 0;
  width: 16px;
  height: 16px;
}
</style>
