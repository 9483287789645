import lensType from './facets/lensType.json';
import progressiveType from './facets/progressiveType.json';

export default {
  namespaced: true,
  state: {
    lensType,
    progressiveType,
  },
  getters: {
    selected(state) {
      return (facet) => {
        if (state[facet]) {
          return state[facet].options.filter((lensType) => {
            return lensType.active;
          })[0];
        }
        return null;
      };
    },
  },
  mutations: {
    switch(state, payload) {
      state[payload.facet].options.forEach((option) => {
        option.active = option.value === payload.value;
      });
    },
  },
  actions: {
    switch({ commit, state }, payload) {
      if (payload.facet && state[payload.facet]) {
        let value = payload.value;
        if (payload.option && payload.option.value) {
          value = payload.option.value;
        }

        commit('switch', { facet: payload.facet, value });
      }
    },
  },
};
