import flashColors from '../../data/flashColors.json';
import colorNames from '../../data/colors.json';
/* eslint-disable */
export default {
  namespaced: true,

  state: {
    iSelectedBrand: 257,

    aLenses: [],
    oProduct: {},
    allLenses: [],
    oFacets: {},
    shadeColors: {},
    flashColors: {},
    shadePercents: {},

    currentColor: '',
    currentFlashColor: '',
    currentPercent: '',

    birthdate: undefined,

    order: 'asc',
  },
  getters: {
    getLensesByBrand: (state, getters, root) => (brand) => {
      const lenses = state.aLenses
        .filter((oLens) => {
          return oLens.iBrand === brand;
        })
        .sort((k1, k2) => k1.fPrice - k2.fPrice);

      const idTrashholderNew =  2234;

      const result = [];
      for (let i = 0, index = 0; i < lenses.length; i++) {
        if (index < lenses[i].fBrechungsIndex  || lenses[i].iLens > idTrashholderNew) {
         /*
          console.log(
            'index = ' +
              index +
              ' fBrechungsIndex' +
              lenses[i].fBrechungsIndex +
              ' name ' +
              lenses[i].sName +
              ' price ' +
              lenses[i].fPrice
          );*/
          index = lenses[i].fBrechungsIndex;
          result.push(lenses[i]);
        }
      }
      const overhangs = lenses.map((lens) => {
        return parseFloat(lens.fUeberstand.toFixed(1));
      });
      const thicknesses = lenses.map((lens) => {
        return parseFloat(lens.fCenterThickness.toFixed(1));
      });
      const minOverhang = Math.min(...overhangs);
      const minThickness = Math.min(...thicknesses);
      const greenOverhang =
        state.oProduct.pgl_eFrameMaterial === 'metal' ? 1.7 : 1;
      const greenThickness = 2.4;
      const minOverhangLenses = lenses.filter((lens) => {
        if (
          root.query.oConditions.fSphereL > 0 &&
          root.query.oConditions.fSphereR > 0
        ) {
          return (
            parseFloat(lens.fCenterThickness.toFixed(1)) <= greenThickness ||
            parseFloat(lens.fCenterThickness.toFixed(1)) === minThickness
          );
        }
        return (
          parseFloat(lens.fUeberstand.toFixed(1)) <= greenOverhang ||
          parseFloat(lens.fUeberstand.toFixed(1)) === minOverhang
        );
      });

      const bluePolarLens = minOverhangLenses.find((lens) => {
        return lens.bBlueControl || lens.bPolarisiert;
      });
      /**/
      /* eslint-disable */
      if (bluePolarLens && !result.includes(bluePolarLens)) {
        result.push(bluePolarLens);
      }
      let returnResult = result.filter((lens) => {
        if (bluePolarLens) {
          return (
            (lens.fPrice <= bluePolarLens.fPrice &&
            lens.fBrechungsIndex <= bluePolarLens.fBrechungsIndex) || lens.iLens > idTrashholderNew
          );
        }
        return lens.fPrice <= minOverhangLenses[0].fPrice || lens.iLens > idTrashholderNew;
      });
      return returnResult;
    },
    getBrands(state) {
      return [
        ...new Set(
          state.aLenses.map((lens) => {
            return lens.iBrand;
          })
        ),
      ];
    },

    getAllSelectedLenses(state, getters) {
      const brands = getters.getBrands;
      return brands
        .map((brand) => {
          return getters.getLensesByBrand(brand);
        })
        .flat();
    },

    getSelectedLenses(state, getters) {
      return getters.getLensesByBrand(state.iSelectedBrand);
    },

    getLensesWithout: (state) => (exceptProperty) => {
      let aLenses = state.allLenses;
      const facets = state.facets;

      const excludedNoneProperties = [
        'aShadeColor',
        'aFlashColor',
        'bPolarisiert',
      ];
      const excludedGradientProperties = ['bPolarisiert'];
      const excludedPhototropProperties = ['aFlashColor', 'bPolarisiert'];

      for (const property in facets) {
        if (facets.hasOwnProperty(property)) {
          if (
            state.sShade === 'none' &&
            excludedNoneProperties.includes(property)
          ) {
            continue;
          } else if (
            state.sShade === 'gradient' &&
            excludedGradientProperties.includes(property)
          ) {
            continue;
          } else if (
            state.sShade === 'phototrop' &&
            excludedPhototropProperties.includes(property)
          ) {
            continue;
          }

          if (
            aLenses &&
            aLenses[0] &&
            aLenses[0].hasOwnProperty(property) &&
            property !== exceptProperty
          ) {
            aLenses = aLenses.filter((lens) => {
              if (Array.isArray(lens[property])) {
                if (facets[property] === true) {
                  return lens[property].length > 0;
                }
                return lens[property].find((value) => {
                  return value == facets[property];
                });
              }

              return lens[property] == facets[property];
            });
          }
        }
      }
      return aLenses;
    },

    getLensesWith: (state) => (property) => {
      return state.aLenses.filter((lens) => {
        return lens[property];
      });
    },
    cheapestLens: (state, getters) => (expectedProperty) => {
      return;
    },
  },
  mutations: {
    setLenses(state, payload) {
      state.allLenses = payload.aLenses.slice(0);
      state.aLenses = payload.aLenses;
    },
    setProduct(state, payload) {
      state.oProduct = payload.oProduct;
    },
    updateLenses(state) {
      state.facets = this.state.localQuery.oFacets;
      let aLenses = this.state.result.aLenses.slice(0);
      const facets = this.state.localQuery.oFacets;
      state.sShade = this.state.query.oConditions.sShade;

      const excludedNoneProperties = [
        'aShadeColor',
        'aFlashColor',
        'bPolarisiert',
      ];
      const excludedGradientProperties = ['bPolarisiert'];
      const excludedPhototropProperties = ['aFlashColor', 'bPolarisiert'];

      for (const property in facets) {
        if (facets.hasOwnProperty(property)) {
          if (
            state.sShade === 'none' &&
            excludedNoneProperties.includes(property)
          ) {
            continue;
          } else if (
            state.sShade === 'gradient' &&
            excludedGradientProperties.includes(property)
          ) {
            continue;
          } else if (
            state.sShade === 'phototrop' &&
            excludedPhototropProperties.includes(property)
          ) {
            continue;
          }

          if (aLenses[0] && aLenses[0].hasOwnProperty(property)) {
            aLenses = aLenses.filter((lens) => {
              if (Array.isArray(lens[property])) {
                return lens[property].find((value) => {
                  if (facets[property] === true) {
                    return value;
                  }
                  return value == facets[property];
                });
              }
              return lens[property] == facets[property];
            });
          }
        }
      }
      state.aLenses = aLenses;
    },
    updateFacets(state, { getters }) {
      const percents = {};
      const colors = {};
      const flashes = {};

      getters.getLensesWithout('aShadeColor').forEach((lens) => {
        (lens.aShadeColor || []).forEach((colorCode) => {
          if (!colors['c' + colorCode]) {
            colors['c' + colorCode] = {
              name: colorNames[parseInt(colorCode)],
              count: 0,
              type: 'shade',
              brandCount: 0,
              code: 'c' + colorCode,
              isBaseColor: lens.aFlashColor.length === 0,
              isBrandBaseColor: false,
              cheapest: lens.fPrice,
              cheapestBrandLens: { fPrice: Infinity },
            };
          }
          const currentColor = colors['c' + colorCode];
          currentColor.count += 1;
          if (!colors['c' + colorCode].isBaseColor) {
            currentColor.isBaseColor = lens.aFlashColor.length === 0;
          }
          if (state.iSelectedBrand === lens.iBrand) {
            currentColor.brandCount += 1;
          }
          if (lens.fPrice < colors['c' + colorCode].cheapest) {
            currentColor.cheapest = lens.fPrice;
          }
          if (state.iSelectedBrand === lens.iBrand) {
            const cheapestLens = currentColor.cheapestBrandLens;
            if (lens.fPrice < cheapestLens.fPrice) {
              currentColor.cheapestBrandLens = lens;
            }
            if (!currentColor.isBrandBaseColor) {
              currentColor.isBrandBaseColor = lens.aFlashColor.length === 0;
            }
          }
        });
      });

      getters.getLensesWithout('aFlashColor').forEach((lens) => {
        lens.aFlashColor.forEach((color) => {
          if (!flashes[color]) {
            flashes[color] = {
              name: flashColors[color],
              color,
              code: color,
              type: 'flash',
              count: 0,
              brandCount: 0,
              cheapest: lens.fPrice,
              cheapestBrandLens: { fPrice: Infinity },
            };
          }
          const currentFlash = flashes[color];
          currentFlash.count += 1;
          if (state.iSelectedBrand === lens.iBrand) {
            currentFlash.brandCount += 1;
          }
          if (lens.fPrice < flashes[color].cheapest) {
            currentFlash.cheapest = lens.fPrice;
          }
          if (
            state.iSelectedBrand === lens.iBrand &&
            lens.fPrice < currentFlash.cheapestBrandLens.fPrice
          ) {
            currentFlash.cheapestBrandLens = lens;
          }
        });
      });

      if (this.state.localQuery.oFacets.aShadeColor) {
        getters.getLensesWithout('aShadePercent').forEach((lens) => {
          const current = this.state.localQuery.oFacets.aShadeColor;
          if (
            (lens.aShadeColor || []).indexOf(String(current)) !== -1 &&
            lens.aShadePercent
          ) {
            lens.aShadePercent.forEach((percent) => {
              if (!percents[percent]) {
                percents[percent] = {
                  percent,
                  count: 0,
                  type: 'percent',
                  isBaseColor: lens.aFlashColor.length === 0,
                  isBrandBaseColor: false,
                  brandCount: 0,
                  cheapest: lens.fPrice,
                  cheapestBrandLens: { fPrice: Infinity },
                };
              }

              const currentPercent = percents[percent];
              percents[percent].count += 1;

              if (state.iSelectedBrand === lens.iBrand) {
                percents[percent].brandCount += 1;
              }

              if (lens.fPrice < percents[percent].cheapest) {
                percents[percent].cheapest = lens.fPrice;
              }
              const cheapestBrandLens = currentPercent.cheapestBrandLens.fPrice;
              if (
                state.iSelectedBrand === lens.iBrand &&
                lens.fPrice < cheapestBrandLens
              ) {
                currentPercent.cheapestBrandLens = lens;
              }

              if (state.iSelectedBrand === lens.iBrand) {
                if (!percents[percent].isBrandBaseColor) {
                  percents[percent].isBrandBaseColor =
                    lens.aFlashColor.length === 0;
                }
              }
              if (!percents[percent].isBaseColor) {
                percents[percent].isBaseColor = lens.aFlashColor.length === 0;
              }
            });
          }
        });
      }

      const orderedPercents = {};
      Object.keys(percents)
        .filter((percent) => {
          if (!state.currentFlashColor) {
            return percents[percent].isBaseColor;
          }
          return true;
        })
        .sort((k1, k2) => {
          return k1.split('-')[0] - k2.split('-')[0];
        })
        .forEach(function (key) {
          orderedPercents[key] = percents[key];
        });
      const orderedColors = {};
      Object.keys(colors)
        .sort((k1, k2) => {
          if (colors[k2].brandCount === colors[k1].brandCount) {
            return colors[k2].count - colors[k1].count;
          }
          return colors[k2].brandCount - colors[k1].brandCount;
        })
        .forEach((key) => {
          orderedColors[key] = colors[key];
        });

      state.shadeColors = orderedColors;
      state.shadePercents = orderedPercents;
      state.flashColors = flashes;
    },
    selectBrand(state, payload) {
      state.iSelectedBrand = payload.iSelectedBrand;
    },

    setCurrentColor(state, payload) {
      state.currentColor = payload;
    },
    setCurrentFlashColor(state, payload) {
      state.currentFlashColor = payload;
    },
    setCurrentPercent(state, payload) {
      state.currentPercent = payload;
    },

    changeOrder(state) {
      if (state.order === 'asc') {
        state.order = 'desc';
      } else {
        state.order = 'asc';
      }
    },
    setOrder(state, payload) {
      state.order = payload.order;
    },
    updateBirthdate(state, payload) {
      state.birthdate = payload.birthdate;
    },
  },
  actions: {
    setResult({ commit, getters }, result) {
      commit('setLenses', { aLenses: result.aLenses });
      commit('setProduct', { oProduct: result.oProduct });
      commit('updateLenses');
      commit('updateFacets', { getters });
    },
    update({ commit, getters }) {
      commit('updateLenses');
      commit('updateFacets', { getters });
    },
    selectBrand({ commit, getters }, payload) {
      commit('selectBrand', payload);
      commit('updateLenses');
      commit('updateFacets', { getters });
    },

    setCurrentFlashColor({ commit, state, dispatch }, payload) {
      commit('setCurrentFlashColor', payload);
      const unset = !payload || !payload.code;
      this.dispatch('localQuery/setCondition', {
        property: 'aFlashColor',
        value: unset ? undefined : payload.code,
      });

      if (unset) {
        dispatch('setCurrentColor', '');
      }

      dispatch('tryToSelectColor');
    },
    setCurrentColor({ commit, state, dispatch }, payload) {
      commit('setCurrentColor', payload);
      const unset = !payload || !payload.code;
      this.dispatch('localQuery/setCondition', {
        property: 'aShadeColor',
        value: unset ? undefined : parseInt(payload.code.substr(1)),
      });

      if (unset) {
        dispatch('setCurrentPercent', '');
      } else {
        dispatch('tryToSelectPercent');
      }
    },
    setCurrentPercent({ commit }, payload) {
      commit('setCurrentPercent', payload);
      this.dispatch('localQuery/setCondition', {
        property: 'aShadePercent',
        value: payload && payload.percent ? payload.percent : undefined,
      });
    },

    tryToSelectColor({ state, dispatch }) {
      if (Object.values(state.shadeColors).length === 1) {
        const color = Object.values(state.shadeColors)[0];
        dispatch('setCurrentColor', color);
      }
    },
    tryToSelectPercent({ state, dispatch }) {
      const brandShadePercent = Object.values(state.shadePercents).filter(
        (percent) => percent.brandCount > 0
      );
      if (brandShadePercent.length === 1) {
        dispatch('setCurrentPercent', brandShadePercent[0]);
      }
    },

    changeOrder({ commit }) {
      commit('changeOrder');
    },
    setOrder({ commit }, order) {
      commit('setOrder', { order });
    },
    updateBirthdate({ commit }, birthdate) {
      commit('updateBirthdate', { birthdate });
    },
  },
};
