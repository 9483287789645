<template>
  <div>
    <LensBlockHeadline blockicon="plusminus" property="Gleitsichtwerte" />

    <div class="extra-values">
      <div
        v-for="value in values"
        :key="value.property"
        class="extra-values-row"
      >
        <div class="extra-values-label">
          <label :for="value.property"> {{ value.label }}: </label>
        </div>
        <select
          :id="value.property"
          v-model="value.model"
          :name="value.property"
          class="extra-values-select"
          @change="changeProperty(value)"
        >
          <option value="">Auswählen</option>
          <option
            v-for="i in (value.max - value.min) / value.step + 1"
            :key="i"
            :value="value.min + (i - 1) * value.step"
          >
            {{ value.min + (i - 1) * value.step }}
          </option>
        </select>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import LensBlockHeadline from '../LensBlockHeadline';

export default {
  name: 'LensExtraValues',
  components: { LensBlockHeadline },
  data() {
    return {
      values: [
        {
          property: 'iHSA',
          label: 'HSA',
          min: 8,
          max: 17,
          step: 1,
          unit: 'mm',
          model: '',
        },
        {
          property: 'iVorn',
          label: 'VORN',
          min: 0,
          max: 40,
          step: 1,
          unit: 'Grad',
          model: '',
        },
        {
          property: 'iFSW',
          label: 'FSW',
          min: 0,
          max: 40,
          step: 1,
          unit: 'Grad',
          model: '',
        },
        {
          property: 'fVIR',
          label: 'VI-R',
          min: 0,
          max: 4.5,
          step: 0.5,
          unit: 'mm',
          model: '',
        },
        {
          property: 'fVIL',
          label: 'VI-L',
          min: 0,
          max: 4.5,
          step: 0.5,
          unit: 'mm',
          model: '',
        },
        {
          property: 'iON',
          label: 'ON',
          min: 25,
          max: 64,
          step: 1,
          unit: 'cm',
          model: '',
        },
        {
          property: 'iProgression',
          label: 'PROGRESSION',
          min: 10,
          max: 40,
          step: 1,
          unit: 'mm',
          model: '',
        },
        {
          property: 'iESH',
          label: 'ESH',
          min: 10,
          max: 40,
          step: 1,
          unit: 'mm',
          model: '',
        },
      ],
    };
  },
  methods: {
    ...mapActions('localQuery', ['setCondition']),
    changeProperty(value) {
      this.setCondition({
        property: value.property,
        value: value.model,
      });
    },
  },
};
</script>

<style scoped>
.extra-values {
  display: flex;
  flex-direction: column;
  max-width: 400px;
  margin-left: 60px;
}

.extra-values-row {
  margin-bottom: 10px;
  display: flex;
}

.extra-values-select {
  border: 1px solid black;
  padding: 4px 34px 4px 8px;
  appearance: none;
  background: white
    url('data:image/svg+xml;utf8,<svg fill="gray" height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg"><path d="M7 10l5 5 5-5z"/><path d="M0 0h24v24H0z" fill="none"/></svg>')
    no-repeat;
  background-position-x: 100%;
  background-position-y: 2px;
  color: #303030;
  line-height: 20px;
  width: 50%;
}

.extra-values-label {
  font-weight: bold;
  width: 50%;
}
</style>
