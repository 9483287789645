<template>
  <div>
    <LensBlockHeadline
      blockicon="glasdesign"
      :property="txt('ls_glasses_design')"
    />

    <div class="design-options">
      <LensDesignOption
        v-for="option in progressiveType.options.filter((opt) => {
          return (
            oConditions.sLenstype === 'progressive' ||
            (opt.value !== 1 && opt.value !== 5)
          );
        })"
        :key="option.value"
        :option="option"
      />
    </div>
  </div>
</template>

<script>
import translation from '@/mixins/translation';
import LensBlockHeadline from '../LensBlockHeadline';
import LensDesignOption from './LensDesignOption';

import { mapState } from 'vuex';

export default {
  name: 'LensDesign',
  components: { LensDesignOption, LensBlockHeadline },
  mixins: [translation],
  computed: {
    ...mapState('facets', ['progressiveType']),
    ...mapState('query', ['oConditions']),
  },
  methods: {
    txt(indicator) {
      if (!this.getTranslations) {
        return '';
      }
      return this.getTranslations[indicator];
    },
  },
};
</script>

<style scoped>
.design-options {
  display: flex;
  justify-content: space-evenly;
  margin-bottom: 20px;

  @apply flex-col items-center;
}

@screen sm {
  .design-options {
    @apply flex-row;
  }
}
</style>
