<template>
  <div class="eo-lens-option-inclusive">
    <div class="m-lens-option-inclusive-stoerer">
      <div>{{ txt('ls_inklusive') }}</div>
    </div>
    <div class="eo-lens-option-marker radio">
      <input
        v-if="facets[property].type === 'radio'"
        type="radio"
        checked
        disabled
      />
      <input
        v-else-if="facets[property].type === 'checkbox'"
        type="checkbox"
        checked
        disabled
      />
      <label />
    </div>
    <LensOptionContent :option="option" />
  </div>
</template>

<script>
import facets from '../../data/facets.json';
import LensOptionContent from './LensOptionContent';

import translation from '../../mixins/translation';

export default {
  name: 'LensOptionInclusive',
  components: { LensOptionContent },
  mixins: [translation],
  props: {
    property: {
      type: String,
      required: true,
    },
    option: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      facets,
    };
  },
  methods: {},
};
</script>

<style scoped>
.eo-lens-option-marker {
  width: auto;
  align-self: center;
  padding: 0 10px;
  cursor: pointer;
}

.eo-lens-option-inclusive {
  display: flex;
  flex-direction: row;
  position: relative;
  font-weight: bold;
}

.m-lens-option-inclusive-stoerer {
  position: absolute;
  top: -0.3em;
  left: -1em;
  display: block;
  transform: rotate(-7deg);
  z-index: 10;
}

.m-lens-option-inclusive-stoerer div {
  display: inline-block;
  background: #e94e1b;
  color: #fff;
  padding: 0.1em 0.2em;
  text-transform: uppercase;
  font-size: 0.9em;
  user-select: none;
}

@media print, screen and (max-width: 400px) {
  .m-lens-option-inclusive-stoerer {
    left: 0;
  }
}
</style>
