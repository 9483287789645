<template>
  <div class="eo-lens-option-content">
    <div></div>
  </div>
</template>

<script>
export default {
  name: 'LensOptionDesign',
};
</script>

<style scoped></style>
