<template>
  <div class="grid-x eo-lens-list-brands">
    <div v-if="currentBrand" class="mb-3 mx-5 md:ml-2">
      <h4 class="text-lg font-bold">
        {{ getBrandName(currentBrand) }}
      </h4>

      <span v-html="txt('ls_i_marke' + currentBrand.iId)" />
    </div>
    <div class="cell small-12">
      <ul class="facet-brands">
        <li
          v-for="oBrand in notEmptyBrands"
          :key="oBrand.iId"
          class="fbrand"
          :class="{
            selected: oBrand.iId === iSelectedBrand,
            'eo-brand-more': more,
          }"
          @click="brandClick(oBrand)"
        >
          <img
            class="brand-logo hide-for-small-only"
            :src="getBrandSvg(oBrand)"
            :class="{ rayban: isRayBan(oBrand) }"
            alt=""
          />
          <!--          <PropertyTooltipp-->
          <!--            class="logo-tooltipp show-for-medium"-->
          <!--            :title="getBrandName(oBrand)"-->
          <!--            :text="txt('ls_i_marke' + oBrand.iId)"-->
          <!--          />-->
          <div class="grid-x pricewrap">
            <div class="show-for-small-only eo-brand-radio">
              <div class="radio">
                <input
                  :id="oBrand.iId"
                  type="radio"
                  class="show-for-small-only radio"
                  :checked="oBrand.iId === iSelectedBrand"
                  name="brand"
                />
                <label :for="oBrand.iId" />
              </div>
            </div>
            <div class="show-for-small-only eo-brand-name flex-1">
              <img
                class="brand-logo show-for-small-only"
                style="min-width: 2.5rem"
                :src="getBrandSvg(oBrand)"
                :class="{
                  rayban:
                    isRayBan(oBrand) ||
                    oBrand.iId === 258 ||
                    oBrand.iId === 254,
                }"
                alt=""
              />
            </div>
            <!--            <div class="show-for-small-only eo-brand-name-tooltipp">-->
            <!--              <PropertyTooltipp-->
            <!--                :title="getBrandName(oBrand)"-->
            <!--                :text="txt('ls_i_marke' + oBrand.iId)"-->
            <!--              />-->
            <!--            </div>-->
            <div class="small-6 medium-12 fromprice flex-0 flex-shrink">
              <div>
                <em>{{ txt('ls_from') }}</em>
                {{ Math.max(0, oBrand.dMinPrice * 2) | formatPrice }}
              </div>
            </div>
          </div>
        </li>
        <li class="m-fbrands-lastli" />
      </ul>
      <div v-if="getBrands.length > 3" class="eo-brands-more">
        <span @click="more = !more">
          <b v-if="more">{{ txt('ls_more') }}</b>
          <b v-else>{{ txt('ls_less') }}</b>
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import { dragscroll } from 'vue-dragscroll';
import { mapActions, mapGetters, mapState } from 'vuex';

import translation from '../../mixins/translation';
// import PropertyTooltipp from '../prescription-pass/PropertyTooltipp';

export default {
  name: 'LensListBrands',
  components: {
    // PropertyTooltipp
  },
  directives: {
    dragscroll,
  },
  mixins: [translation],
  data() {
    return {
      more: true,
      windowWidth: screen.width,
    };
  },
  computed: {
    ...mapState({
      aLenses: (state) => state.localResult.aLenses,
      oProduct: (state) => state.result.oProduct,
      iSelectedBrand: (state) => state.localResult.iSelectedBrand,
      currencySign: (state) => state.result.sign,
    }),
    ...mapGetters({
      getLensesByBrand: 'localResult/getLensesByBrand',
    }),
    getBrands() {
      const lenses = this.$store.state.localResult.aLenses;
      const brands = {};

      lenses.forEach((lens) => {
        const iBrand = lens.iBrand;
        const sBrand = lens.sBrand || '';
        const fPrice = lens.fPrice;

        if (!brands[iBrand]) {
          brands[iBrand] = {
            iId: iBrand,
            sName: sBrand,
            dMinPrice: fPrice,
          };
        } else {
          brands[iBrand].dMinPrice = Math.min(brands[iBrand].dMinPrice, fPrice);
        }
      });

      return Object.values(brands).sort((a, b) => {
        return a.dMinPrice - b.dMinPrice;
      });
    },
    notEmptyBrands() {
      const brands = this.getBrands.filter((brand) => {
        return this.hasLenses(brand.iId);
      });

      const glazingBrands = [759, 760, 761, 763, 764, 765, 767, 773];
      const hasGlazing = brands.some((brand) => {
        return glazingBrands.includes(brand.iId);
      });

      return brands.filter((brand) => {
        if (this.windowWidth < 880 && this.more && brands.length > 3) {
          const cheapest = brands[0].iId;
          const selected = this.iSelectedBrand;
          const second = brands[1].iId;
          const third = brands[2].iId;

          return (
            brand.iId === cheapest ||
            (cheapest !== selected &&
              !glazingBrands.includes(selected) &&
              brand.iId === selected) ||
            ((cheapest === selected || glazingBrands.includes(selected)) &&
              brand.iId === second) ||
            (hasGlazing && glazingBrands.includes(brand.iId)) ||
            (!hasGlazing && brand.iId === second) ||
            (!hasGlazing && brand.iId === third)
          );
        }
        return true;
      });
    },
    currentBrand() {
      return this.notEmptyBrands.find(
        (brand) => brand.iId === this.iSelectedBrand
      );
    },
    isBoutique() {
      return !!this.$route.query.bBoutique;
    },
  },
  watch: {
    oProduct(_, prev) {
      if (!prev) {
        this.tryToSelectBoutique();
        this.tryToSelectRayBan();
      }
    },
    aLenses(lenses) {
      if (this.getLensesByBrand(this.iSelectedBrand).length === 0) {
        if (lenses.length > 0) {
          this.selectBrand(
            lenses.reduce((result, lens) => {
              const minPrice = parseFloat(result.fPrice);
              const lensPrice = parseFloat(lens.fPrice);
              return minPrice <= lensPrice ? result : lens;
            }).iBrand
          );
          this.tryToSelectBoutique();
          this.tryToSelectRayBan();
        }
      }
    },
  },
  mounted() {
    this.tryToSelectBoutique();
    this.tryToSelectRayBan();
    window.addEventListener('resize', () => {
      this.windowWidth = screen.width;
    });
  },
  methods: {
    ...mapActions('result', ['selectBrand']),

    hasLenses(iId) {
      return this.getLensesByBrand(iId).length > 0;
    },

    getBrandName(oBrand) {
      return oBrand.sName
        .replace('ROX Clear', 'Ray-Ban')
        .replace('ROX Sun', 'Ray-Ban')
        .replace('OTD Clear', 'Oakley')
        .replace('OTD Sun Standard', 'Oakley')
        .replace('OTD Complex High', 'Oakley')
        .replace('OTD Complex', 'Oakley')
        .replace('OTD Shield', 'Oakley');
    },
    getBrandSvg(oBrand) {
      if (!oBrand.sName) {
        return '';
      }
      return (
        'https://img.edel-optics.de/brands/svg/' +
        oBrand.sName.replace(' ', '-') +
        '.svg'
      );
    },
    brandClick(oBrand) {
      if (this.getLensesByBrand(oBrand.iId).length > 0) {
        this.selectBrand(oBrand.iId);
      }
    },
    tryToSelectBoutique() {
      const hoyaId = 218;
      if (this.isBoutique) {
        this.brandClick(hoyaId);
      }
    },
    tryToSelectRayBan() {
      if (this.oProduct) {
        if (parseInt(this.oProduct.prd_iBrand) === 8) {
          if (this.getLensesByBrand(761).length > 0) {
            this.selectBrand(761);
          } else if (this.getLensesByBrand(760).length > 0) {
            this.selectBrand(760);
          } else if (this.getLensesByBrand(759).length > 0) {
            this.selectBrand(759);
          }
        } else if (parseInt(this.oProduct.prd_iBrand) === 511) {
          if (this.getLensesByBrand(761).length > 0) {
            this.selectBrand(761);
          } else if (this.getLensesByBrand(760).length > 0) {
            this.selectBrand(760);
          } else if (this.getLensesByBrand(759).length > 0) {
            this.selectBrand(759);
          }
        } else if (parseInt(this.oProduct.prd_iBrand) === 2) {
          if (this.getLensesByBrand(763).length > 0) {
            this.selectBrand(763);
          } else if (this.getLensesByBrand(764).length > 0) {
            this.selectBrand(764);
          } else if (this.getLensesByBrand(765).length > 0) {
            this.selectBrand(765);
          } else if (this.getLensesByBrand(767).length > 0) {
            this.selectBrand(767);
          } else if (this.getLensesByBrand(773).length > 0) {
            this.selectBrand(773);
          }
        }
      }
    },
    isRayBan(oBrand) {
      return [759, 760, 761].includes(oBrand.iId);
    },

    txt(indicator) {
      if (!this.getTranslations) {
        return '';
      }
      return this.getTranslations[indicator];
    },
  },
};
</script>

<style scoped>
.facet-brands {
  display: flex;
}

.eo-lens-list-brands {
  margin-left: 30px;
}

.eo-brands-more {
  width: 100%;
  border-top: 1px solid #000;
  display: flex;
  justify-content: center;
  margin-bottom: 10px;
}

.eo-brands-more span {
  background-color: #fff;
  color: #000;
  border: 1px solid #000;
  text-transform: uppercase;
  font-weight: bold;
  padding: 2px 4px;
  cursor: pointer;
  margin-top: -1px;
}

.eo-brands-more span:hover {
  background-color: #000;
  color: #fff;
}

ul {
  width: 100%;
  height: auto;
  white-space: nowrap;
  overflow: hidden;
  position: relative;
}

li {
  display: inline-block;
  border: 1px solid #ccc;
  padding: 8px 8px 6px 8px;
  position: relative;
  cursor: pointer;
  opacity: 0.65;
}

.m-fbrands-lastli {
  border-bottom: 1px solid #000;
  border-left: none !important;
  user-select: none;
}

li:first-child:not(.selected) {
  border-bottom: 1px solid #000;
  border-left: 1px solid #ccc;
}

li:not(.selected) {
  border-bottom: 1px solid #000;
  border-left: none;
}

li.selected {
  background-color: #fff;
  border-bottom: 0;
  border-color: #000;
  cursor: default;
  opacity: 1;
  box-shadow: 0 -6px 5px 0 rgba(0, 0, 0, 0.33), 0 -3px 2px 0 rgba(0, 0, 0, 0.22);
}

li .fromprice {
  margin-top: 8px;
  font-size: 13px;
  color: #666;
  margin-left: auto;
}

li .fromprice em {
  font-style: normal;
  font-size: 10px;
  color: #999;
}

ul img {
  width: 64px;
  height: 30px;
  display: block;
  margin: auto;
}

.fbrand {
  height: 74px;
  text-align: center;
}

.m-fbrands-lastli {
  border: none;
  background: none;
  cursor: default;
  flex-grow: 1;
}

.brand-logo {
  user-select: none;
}

@media print, screen and (max-width: 880px) {
  .facet-brands {
    flex-direction: column;
  }

  .eo-brand-more:nth-child(n + 4) {
    display: none;
  }

  .fbrand.selected {
    border: 0;
    box-shadow: none;
  }

  .brand-logo {
    margin: 0;
  }

  .fbrand {
    height: 90px;
    text-align: left;
    opacity: 1;
  }

  .m-fbrands-lastli {
    display: none;
  }

  ul {
    user-select: none;
    width: 100%;
    position: relative;
    margin-bottom: 10px;
  }

  ul img {
    width: auto;
    margin-bottom: 4px;
  }

  li {
    display: block;
    padding: 0.75rem 1rem;
    position: relative;
    cursor: pointer;
    opacity: 0.45;
  }

  li:first-child:not(.selected) {
    border: none;
  }

  li:not(.selected) {
    border: none;
  }

  li .pricewrap {
    font-size: 14px;
    padding: 5px 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex-flow: row;
    height: 60px;
  }

  li.selected .pricewrap {
    font-weight: bold;
    background: rgba(233, 80, 29, 0.15);
  }

  li .fromprice {
    text-align: right;
    color: #000;
    width: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 0;
    flex-grow: 1;
    flex-shrink: 0;
  }

  li .fromprice em {
    font-style: normal;
    font-size: 10px;
    color: #666;
  }

  .brand-logo.rayban {
    height: 53px;
    max-height: 53px;
    margin-top: -1px;
  }

  .brand-logo {
    height: auto;
  }

  .eo-brand-name {
    display: flex;
    flex-shrink: 2;
    @apply items-center;
  }
  .eo-brand-name img {
    max-height: 46px;
    max-width: 100%;
  }

  .eo-lens-list-brands {
    margin-left: -2px;
    margin-top: 10px;
    margin-bottom: 20px;
  }
}

li:hover:not(.m-fbrands-lastli):not(.selected) {
  opacity: 1;
}

@media print, screen and (min-width: 881px) {
  li:hover:not(.m-fbrands-lastli):not(.selected) {
    box-shadow: 0 -6px 5px 0 rgba(0, 0, 0, 0.33),
      0 -3px 2px 0 rgba(0, 0, 0, 0.22);
  }

  .eo-brands-more {
    display: none;
  }
  .eo-brand-name {
    display: inline-flex;
  }
}

.eo-brand-name {
  margin-left: 10px;
}

.eo-brand-name > span {
  margin-right: 5px;
}

.radio {
  height: 18px;
  cursor: pointer;
}

.radio input {
  position: absolute;
  cursor: pointer;
  opacity: 0;
}

.radio input + label:before {
  content: '';
  background: #f4f4f4;
  border-radius: 100%;
  border: 1px solid #b4b4b4;
  display: inline-block;
  width: 18px;
  height: 18px;
  cursor: pointer;
  text-align: center;
  transition: all 250ms ease;
}

.radio input[type='checkbox'] + label:before {
  border-radius: 2px;
}

.radio input:checked + label:before {
  background-color: #e94e1b;
  box-shadow: inset 0 0 0 3px #f4f4f4;
}

.radio input:focus + label:before {
  outline: none;
  border-color: #e94e1b;
}

.radio input:disabled + label:before {
  box-shadow: inset 0 0 0 3px #f4f4f4;
  border-color: #b4b4b4;
  background: none;
}

.logo-tooltipp {
  position: absolute;
  right: 4px;
  top: 4px;
}

.eo-brand-name-tooltipp {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0 6px;
  flex-shrink: 1;
  flex-grow: 0;
}

.eo-brand-radio {
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex-shrink: 1;
  flex-grow: 0;
}
</style>

<style>
.logo-tooltipp svg {
  width: 12px !important;
  height: 12px !important;
}
</style>
