<template>
  <div class="eo-lens-option-content">
    <div class="eo-lens-option-content-img">
      <img
        v-if="option.img0"
        :src="require('../../assets/' + option.img0)"
        alt=""
        class="m-lop-imgovl3"
      />
      <img
        v-if="option.img1"
        :src="require('../../assets/' + option.img1)"
        alt=""
        class="m-lop-imgovl1"
      />
      <img
        v-if="option.img3"
        :src="require('../../assets/' + option.img3)"
        alt=""
        class="m-lop-imgovl3"
      />
      <img
        v-if="option.imgn"
        :src="require('../../assets/' + option.imgn)"
        alt=""
        class="m-lop-imgovln"
      />
      <img
        v-if="option.img"
        :src="require('../../assets/' + option.img)"
        alt="option.name"
      />
    </div>
    <div class="eo-lens-option-content-title">
      <!-- eslint-disable -->
      <div v-html="parseJsonTemplate(option.name)" />
      <!-- eslint-enable -->
    </div>
    <property-tooltipp
      v-if="getTranslations && option.infotext"
      class="eo-lens-option-content-tooltipp"
      :title="parseJsonTemplate(option.name)"
      :text="parseJsonTemplate(option.infotext)"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import PropertyTooltipp from '../prescription-pass/PropertyTooltipp';

import translation from '../../mixins/translation';

export default {
  name: 'LensOptionContent',
  components: { PropertyTooltipp },
  mixins: [translation],
  props: {
    option: {
      type: Object,
      required: true,
    },
  },
  computed: {
    ...mapGetters({}),
  },
  methods: {
    parseJsonTemplate(value) {
      const regexp = RegExp(/\{\{\s*(\w+)\s*\}\}/, 'g');
      let matches;
      while ((matches = regexp.exec(value)) !== null) {
        value = value.replace(matches[0], this.txt(matches[1]));
      }
      return value;
    },
  },
};
</script>

<style scoped>
.m-lop-imgovl1,
.m-lop-imgovl2,
.m-lop-imgovl3 {
  position: absolute;
  left: 0;
  top: 0;
}

.m-lop-imgovl1 {
  opacity: 0.5;
}

.m-lop-imgovl2 {
  z-index: 888;
}

.eo-lens-option-content {
  display: flex;
  width: 100%;
  margin: 4px 0 2px 0;
}

.eo-lens-option-content-img {
  display: flex;
  align-self: center;
  justify-content: center;
  position: relative;
  user-select: none;
  width: 45px;
  height: 35px;
  flex-shrink: 0;
}

.eo-lens-option-content-img img {
  height: 100%;
}

.eo-lens-option-content-title {
  padding: 0 0.4075rem;
  align-self: center;
  font-size: 1.1em;
  overflow: hidden;
  word-break: break-word;
  margin-left: 10px;
}

.eo-lens-option-content-tooltipp {
  margin-left: auto;
  align-self: center;
}
</style>
