<template>
  <section class="llist">
    <loading
      :active.sync="bLoading"
      :can-cancel="false"
      :is-full-page="false"
      color="#e94e1b"
    />

    <lens-list-brands />

    <div class="show-for-small-only">
      <lens-block-headline property="Gläser" blockicon="glaeser" />
    </div>

    <div class="eo-lens-list-content">
      <div class="grid-x m-llist-paginationcontainer">
        <div class="cell small-2 m-posrel">
          <div
            slot="button-prev"
            class="swiper-button-prev"
            :style="{ 'background-image': prevImg }"
          />
        </div>
        <div class="cell auto align-center-middle">
          <div slot="pagination" class="swiper-pagination">
            <span>
              {{ aLenses.length }}
            </span>
            <span v-if="aLenses.length === 1">
              {{ txt('ls_fitting_lens') }}
            </span>
            <span v-else>
              {{ txt('ls_fitting_lenses') }}
            </span>
            <span
              v-if="aLenses.length > 1"
              class="eo-pagination-order space-x-1"
              @click="changeOrder"
            >
              <span>[</span>
              <span v-if="order === 'asc'">
                {{ txt('ls_asc') }}
              </span>
              <span v-else-if="order === 'desc'">
                {{ txt('ls_desc') }}
              </span>
              <img
                :src="require('../../assets/updown-gray.svg')"
                :style="{
                  transform: 'rotateZ(' + (order === 'asc' ? 180 : 0) + 'deg)',
                }"
                style="transition: transform 500ms"
                alt="v"
              />
              <span>]</span>
            </span>
          </div>
        </div>
        <div class="cell small-2 m-posrel">
          <div
            slot="button-next"
            class="swiper-button-next"
            :style="{ 'background-image': nextImg }"
          />
        </div>
      </div>
      <div class="grid-x m-llist-container">
        <div class="cell medium-3 hide-for-small-only">
          <div class="grid-y m-llist-idx">
            <div class="cell eo-lens-list-row eo-row-material">
              {{ txt('ls-material') }}
            </div>
            <div
              v-if="showOverhang"
              class="cell eo-lens-list-row eo-row-overhang"
            >
              {{ txt('ls_max_overhang') }}
            </div>
            <div
              v-if="showCenterThickness"
              class="cell eo-lens-list-row eo-row-overhang"
            >
              {{ txt('ls_divider_mittendicke') }}
            </div>
            <div class="cell eo-lens-list-row eo-row-focus">
              {{ txt('ls_focus_size') }}
            </div>
            <div class="cell eo-lens-list-row eo-row-refinement">
              {{ txt('ls_lens_refinement') }}
            </div>
            <div
              v-if="isHoya"
              class="cell eo-lens-list-row eo-row-warranty"
            ></div>
            <div
              v-if="sShade !== 'none'"
              class="cell eo-lens-list-row eo-row-shade"
            >
              {{ txt('ls_shade') }}
            </div>
            <div class="cell eo-lens-list-row red eo-row-price">
              {{ txt('ls_price') }}
            </div>
            <div class="cell eo-row-cart">
              <div
                v-if="
                  (!viewingGlazing || !isPrescriptionable) && isRxAble && false
                "
                class="eo-cheapest-button"
                :class="{ de: language === 'de' }"
                @click="gotoOriginal"
              >
                <span v-if="language === 'de'" class="goto">Zu den</span>
                <img
                  :src="originalIcon"
                  :class="{ 'no-border': hover || !brandAvailable }"
                  alt=""
                  @mouseover="hover = true"
                  @mouseleave="hover = false"
                />
              </div>
              <div
                v-else-if="
                  !(isSchulz && sShade === 'complete') &&
                  isPrescriptionable &&
                  false
                "
                class="eo-cheapest-button"
                :class="{ de: language === 'de' }"
                @click="gotoCheapest"
              >
                <span v-if="language === 'de'" class="goto">Zu den</span>
                <img
                  :src="cheapestImg"
                  :class="{ 'no-border': hover }"
                  alt=""
                  @mouseover="hover = true"
                  @mouseleave="hover = false"
                />
              </div>
            </div>
          </div>
        </div>
        <div class="cell auto m-llist-swipecontainer">
          <swiper ref="mySwiper" :options="swiperOption">
            <div class="swiper-slide hide-for-small-only no-hover">
              <div class="grid-y m-llist-slide">
                <div class="cell eo-lens-list-row eo-row-material">&nbsp;</div>
                <div
                  v-if="showOverhang"
                  class="cell eo-lens-list-row eo-row-overhang"
                >
                  &nbsp;
                </div>
                <div
                  v-if="showCenterThickness"
                  class="cell eo-lens-list-row eo-row-overhang"
                >
                  &nbsp;
                </div>
                <div class="cell eo-lens-list-row eo-row-focus">&nbsp;</div>
                <div class="cell eo-lens-list-row eo-row-refinement">
                  &nbsp;
                </div>
                <div
                  v-if="isHoya"
                  class="cell eo-lens-list-row eo-row-warranty"
                >
                  &nbsp;
                </div>
                <div
                  v-if="sShade !== 'none'"
                  class="cell eo-lens-list-row eo-row-shade"
                />
                <div class="cell eo-lens-list-row red eo-row-price" />
                <div class="cell eo-row-cart" />
              </div>
            </div>

            <swiper-slide
              is="lens-list-item"
              v-for="(oLens, index) in sortedLenses"
              :key="oLens.pid + '#' + index"
              :class="{
                single: showSingleItem,
              }"
              :o-lens="oLens"
            />
          </swiper>
        </div>
      </div>
    </div>
    <div v-if="false" class="show-for-small-only">
      <div
        v-if="!viewingGlazing && isRxAble"
        class="eo-cheapest-button mobile"
        :class="{ de: language === 'de' }"
        @click="gotoOriginal"
      >
        <span v-if="language === 'de'" class="goto">Zu den</span>
        <img
          :src="originalIcon"
          :class="{ 'no-border': hover || !brandAvailable }"
          alt=""
          @mouseover="hover = true"
          @mouseleave="hover = false"
        />
      </div>
      <div
        v-else-if="!(isSchulz && sShade === 'complete')"
        class="eo-cheapest-button mobile"
        :class="{ de: language === 'de' }"
        @click="gotoCheapest"
      >
        <span v-if="language === 'de'" class="goto">Zu den</span>
        <img
          :src="cheapestImg"
          :class="{ 'no-border': hover }"
          alt=""
          @mouseover="hover = true"
          @mouseleave="hover = false"
        />
      </div>
    </div>
  </section>
</template>

<script>
import LensBlockHeadline from '../LensBlockHeadline';
import LensListItem from './LensListItem';
import LensListBrands from './LensListBrands';
import { swiper, swiperSlide } from 'vue-awesome-swiper';
import 'swiper/dist/css/swiper.css';
import { mapActions, mapState, mapGetters } from 'vuex';

import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';

import translation from '../../mixins/translation';

export default {
  name: 'LensList',
  components: {
    LensListBrands,
    LensListItem,
    swiper,
    swiperSlide,
    LensBlockHeadline,
    Loading,
  },
  mixins: [translation],
  props: {},
  data() {
    return {
      windowWidth: screen.width,
      swiperOption: {
        slidesPerView: 'auto',
        slidesOffsetBefore: 0,
        centeredSlides: false,
        grabCursor: true,
        keyboard: {
          enabled: true,
          onlyInViewport: false,
        },
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
        },
      },
      hover: false,
    };
  },
  computed: {
    ...mapState({
      oProduct: (state) => state.result.oProduct,
      iSelectedBrand: (state) => state.result.iSelectedBrand,
      bLoading: (state) => state.result.bLoading,
      sShade: (state) => state.query.oConditions.sShade,
      order: (state) => state.localResult.order,
    }),
    ...mapGetters({
      showCenterThickness: 'query/hasPositiveValues',
      showOverhang: 'query/isNotZero',
      getBrands: 'localResult/getBrands',
      language: 'profile/language',
    }),
    aLenses() {
      return this.$store.getters['localResult/getSelectedLenses'];
    },

    sortedLenses() {
      const unsortedLenses = this.aLenses;
      const lenses = unsortedLenses.sort((a, b) => {
        if (parseFloat(a.fPrice) === parseFloat(b.fPrice)) {
          return a.fBrechungsIndex - b.fBrechungsIndex;
        }
        return parseFloat(a.fPrice) - parseFloat(b.fPrice);
      });
      if (this.order === 'asc') {
        return lenses;
      }
      return lenses.reverse();
    },

    swiper() {
      return this.$refs.mySwiper.swiper;
    },
    showSingleItem() {
      return (
        this.aLenses.length === 1 ||
        (this.windowWidth > 0 && this.windowWidth < 510)
      );
    },
    prevImg() {
      return 'url(' + require('../../assets/m-prev.svg') + ')';
    },
    nextImg() {
      return 'url(' + require('../../assets/m-next.svg') + ')';
    },
    isSchulz() {
      return this.iSelectedBrand === 257;
    },
    isHoya() {
      return this.iSelectedBrand === 218;
    },
    isRxAble() {
      return this.oProduct.pgl_bRxAble && this.language === 'de';
    },
    isPrescriptionable() {
      return (
        this.oProduct.prd_eType === 'eoProductsGlassesPrescription' ||
        this.oProduct.psu_bPrescriptionAvailable
      );
    },
    viewingGlazing() {
      const rxBrands = [759, 760, 761, 763, 764, 765, 767, 773];
      return rxBrands.includes(this.iSelectedBrand);
    },
    cheapestImg() {
      if (this.language !== 'de') {
        if (this.hover) {
          return require('../../assets/shortcuts/14_Bestprice-p.svg');
        }
        return require('../../assets/shortcuts/14_Bestprice-s.svg');
      }

      if (this.sShade === 'none') {
        if (this.hover) {
          return require('../../assets/shortcuts/14_Bestprice1990-p.svg');
        }
        return require('../../assets/shortcuts/14_Bestprice1990-s.svg');
      }
      if (this.hover) {
        return require('../../assets/shortcuts/14_Bestprice3490-p.svg');
      }
      return require('../../assets/shortcuts/14_Bestprice3490-s.svg');
    },
    originalIcon() {
      if (!this.brandAvailable) {
        switch (this.oProduct.prd_iBrand) {
          case 2:
            return require('../../assets/shortcuts/' +
              '14_Original_Oakley_grey.svg');
          case 511:
          case 8:
            return require('../../assets/shortcuts/' +
              '14_Original_RayBan_grey.svg');
        }
      } else {
        switch (this.oProduct.prd_iBrand) {
          case 2:
            if (this.hover) {
              return require('../../assets/shortcuts/' +
                '14_Original_Oakley-p.svg');
            }
            return require('../../assets/shortcuts/14_Original_Oakley-s.svg');
          case 511:
          case 8:
            if (this.hover) {
              return require('../../assets/shortcuts/' +
                '14_Original_RayBan-p.svg');
            }
            return require('../../assets/shortcuts/14_Original_RayBan-s.svg');
        }
      }
      return '';
    },
    brandAvailable() {
      const brands = this.getBrands;

      const glazingBrands = [759, 760, 761, 763, 764, 765, 767, 773];
      return brands.some((brand) => {
        return glazingBrands.includes(brand);
      });
    },
  },
  mounted() {
    window.addEventListener('resize', () => {
      this.windowWidth = screen.width;
    });
  },
  updated() {
    this.swiper.slideTo(0, 230, false);
  },
  methods: {
    ...mapActions({
      resetLocalQuery: 'localQuery/resetLocalQuery',
      initializeGlazing: 'localQuery/initializeGlazing',
      selectCheapestBrand: 'result/selectCheapestBrand',
      selectBrand: 'result/selectBrand',
      getLensesByBrand: 'localResult/getLensesByBrand',
      setCondition: 'query/setCondition',
      detectShade: 'query/detectShade',
      changeOrder: 'localResult/changeOrder',
      setOrder: 'localResult/setOrder',
    }),
    gotoCheapest() {
      this.resetLocalQuery().then(() => {
        if (this.sShade !== 'none') {
          this.setCondition({
            sKey: 'sShade',
            mValue: 'complete',
          });
        }
        this.selectCheapestBrand();
        this.setOrder('asc');
      });
    },
    gotoOriginal() {
      if (this.brandAvailable) {
        this.resetLocalQuery().then(() => {
          this.tryToSelectOriginals();
          this.setOrder('desc');
          this.detectShade();
          this.initializeGlazing();
        });
      }
    },
    tryToSelectOriginals() {
      const brands = this.getBrands;

      const glazingBrands = [759, 760, 761, 763, 764, 765, 767, 773];
      const glazingBrand = brands.find((brand) => {
        return glazingBrands.includes(brand);
      });

      this.selectBrand(glazingBrand);
    },
    txt(indicator) {
      return this.getTranslations[indicator];
    },
  },
};
</script>

<style>
.vld-overlay {
  z-index: 1000;
}

section.llist {
  margin-top: 0;
}

.m-llist-flexcellauto {
  flex: 0 auto;
}

.eo-lens-list-content {
  margin-left: 30px;
}

.m-llist-container {
  background-color: #fff;
  border-left: 1px solid #000;
  border-right: 1px solid #000;
  border-bottom: 1px solid #000;
  position: relative;
  padding-top: 0.5rem;
  overflow: hidden;
}

@media print, screen and (max-width: 880px) {
  .lens .grid-y .cell {
    height: auto;
  }

  .eo-lens-list-content {
    padding: 0 1rem;
    margin-left: 0;
  }

  .m-llist-container {
    border: none;
    padding: 0;
  }
}

.m-llist-paginationcontainer {
  background-color: #fff;
  border-left: 1px solid #000;
  border-right: 1px solid #000;
  position: relative;
  padding: 0.5rem 0 0 240px;
  user-select: none;
  height: 32px;
}

@media print, screen and (max-width: 880px) {
  .m-llist-paginationcontainer {
    border-left: none;
    border-right: none;
    border-bottom: 1px solid #000;
    padding: 0 0 1rem 0;
  }
}

.m-llist-idx {
  position: relative;
  z-index: 9;
  max-width: 230px;
  height: 100%;
  min-height: 600px;
  background: rgb(255, 255, 255);
}

.m-llist-swipecontainer {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

.m-llist-slide {
  height: 100%;
}

.swiper-slide.no-hover:hover {
  background: none;
}

.m-llist-idx .cell,
.m-llist-mobittl {
  font-size: 1.1em;
  font-weight: bold;
  text-transform: uppercase;
}

.m-llist-idx .cell,
.m-llist-slide .cell {
  padding: 8px;
}

.eo-lens-list-row {
  border-bottom: #999 1px solid;
}

.eo-lens-list-row.red {
  border-bottom: #e94e1b 1px solid;
}

.eo-row-material {
  height: 110px;
}

.eo-row-overhang {
  height: 60px;
}

.eo-row-focus {
  height: auto;
  min-height: 61px;
}

.eo-row-warranty {
  height: auto;
  min-height: 61px;
}

.eo-row-refinement {
  height: auto;
  min-height: 228px;
  flex: 1;
}

.eo-row-shade {
  height: auto;
  min-height: 154px;
}

.eo-row-price {
  height: auto;
  min-height: 120px;
}

.eo-row-cart {
  height: auto;
  min-height: 100px;
  margin: auto;
}

.m-litem-bindex {
  margin-top: 0.5em;
}

.m-litem-stoerer div {
  display: inline-block;
  background: #000;
  color: #fff;
  font-weight: bold;
  padding: 0.2em 0.4em;
  text-transform: uppercase;
  margin-bottom: 0.2em;
}

.m-litem-stoerer div.red {
  background: #e94e1b;
}

.m-llist-slide .far {
  font-size: 1.3em;
}

.m-llist-slide .fa-smile {
  color: green;
}

.m-llist-slide .fa-meh {
  color: darkorange;
}

.m-llist-slide .fa-frown {
  color: crimson;
}
</style>

<style>
.swiper-container {
  height: 100%;
  padding-top: 0.5rem;
  box-sizing: border-box;
  position: relative;
  z-index: 5;
}

@media print, screen and (max-width: 880px) {
  .swiper-container {
    padding: 0;
  }
}

.swiper-slide {
  background-color: #fff;
  text-align: left;
  box-sizing: border-box;
  height: 100%;
  width: 25%;
  cursor: pointer;
}

@media print, screen and (min-width: 1920px) {
  .swiper-slide {
    max-width: 230px;
  }
}

@media print, screen and (max-width: 880px) {
  .m-llist-swipecontainer {
    position: initial;
  }

  .swiper-slide {
    width: 50%;
    max-width: none;
  }

  .swiper-slide.single {
    width: 100%;
    max-width: none;
  }

  .llist {
    margin-bottom: 0;
  }
}

@media print, screen and (min-width: 881px) {
  .swiper-slide:hover {
    background: linear-gradient(
      to bottom,
      rgba(233, 80, 29, 0.1) 0%,
      rgba(233, 80, 29, 0) 80%
    );
  }
}

.swiper-button-disabled {
  display: none;
}

.swiper-button-prev {
  left: 0;
}

.swiper-button-next {
  right: 0;
}

@keyframes bounce {
  0% {
    transform: scale(1, 1) translate(0px, 0px);
  }
  3% {
    transform: scale(1, 0.8) translate(0px, 10px);
  }
  15% {
    transform: scale(1, 1.1) translate(0px, -10px);
  }
  22% {
    transform: scale(1, 1) translate(0px, 0px);
  }
}

.swiper-button-next {
  animation: bounce 5s infinite;
}

.swiper-button-prev,
.swiper-button-next {
  padding: 12px 0;
  position: relative;
  top: initial;
  width: auto;
  height: auto;
  margin-top: initial;
  background-size: contain;
  background-position: initial;
  user-select: none;
}

.swiper-button-next {
  background-position: right 0.6rem top;
}

.swiper-pagination {
  position: relative;
  bottom: initial;
  left: initial;
  color: rgba(0, 0, 0, 0.65);
}

@media print, screen and (max-width: 880px) {
  .swiper-button-prev,
  .swiper-button-next {
    padding: 12px 0;
    width: 100%;
    height: auto;
  }

  .swiper-button-next {
    background-position: right center;
  }

  .swiper-button-prev {
    background-position: left center;
    left: 0;
  }

  .swiper-pagination {
    padding: 0.2rem;
  }
}

.eo-cheapest-button {
  cursor: pointer;
  top: 0.4rem;
  position: relative;
}

.eo-cheapest-button.mobile {
  text-align: center;
  top: 0;
}

.eo-cheapest-button.de {
  top: 0;
  margin-top: -4px;
}

.eo-cheapest-button img {
  height: 51px;
  border: 1px solid #e94e1b;
}

.eo-pagination-order {
  cursor: pointer;
  margin-left: 10px;
  display: inline-flex;
  white-space: nowrap;
  color: rgba(0, 0, 0, 0.65);
  left: 68%;
  z-index: 1000;
}

@media print, screen and (max-width: 470px) {
  .swiper-pagination {
    top: -10px;
  }

  .eo-pagination-order {
    margin-left: 0;
  }
}

.no-border {
  border: 1px solid white !important;
}

.goto {
  color: #e94e1b;
}
</style>
