export default {
  namespaced: true,

  state: {
    oTranslation: null,
  },

  getters: {
    getTranslation(state) {
      return state.oTranslation;
    },
  },

  mutations: {
    set(state, oTranslation) {
      state.oTranslation = oTranslation;
    },
  },

  actions: {
    set({ commit }, oTranslation) {
      commit('set', oTranslation);
    },
  },
};
