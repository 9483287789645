<template>
  <div class="eo-pass">
    <div id="eo-prescription-pass-heading">
      <lens-block-headline
        v-if="windowWidth < 330"
        property="Werte"
        blockicon="plusminus"
        :infotitle="txt('ls_divider_korrektionswerte')"
        :infotext="txt('ls_i_ttlkorrektionswerte')"
      />
      <lens-block-headline
        v-else
        :property="txt('ls_divider_korrektionswerte')"
        blockicon="plusminus"
        :infotitle="txt('ls_divider_korrektionswerte')"
        :infotext="txt('ls_i_ttlkorrektionswerte')"
      />
    </div>

    <div class="eo-pass-content" @mouseleave="resetPPass()">
      <div class="eo-pass-entry">
        <div class="eo-pass-entry-row eo-pass-title-row">
          <div class="eo-pass-entry-row-l" />
          <div class="eo-pass-entry-row-r eo-pass-entry-split-view">
            <div class="eo-pass-entry-r-split eo-pass-entry-title">
              {{ txt('ls_unten_dickerechts') }}
            </div>
            <div class="eo-pass-entry-r-split eo-pass-entry-title">
              {{ txt('ls_unten_dickelinks') }}
            </div>
          </div>
        </div>
        <div class="eo-pass-entry-row" @mouseenter="movePPass('fSphere')">
          <div class="eo-pass-entry-row-l" @mouseenter="movePPass('fSphere')">
            <div class="eo-pass-entry-property-title">
              <strong> {{ txt('ls_sphaere') }} </strong>&nbsp;
              {{ txt('ls_sphere_short') }}
              <PropertyTooltipp
                class="eo-pass-entry-tooltipp"
                :title="txt('ls_sphaere')"
                :text="txt('ls_brillenpass_sphaere')"
              />
            </div>
          </div>
          <div class="eo-pass-entry-row-r eo-pass-entry-split-view">
            <div
              class="eo-pass-entry-r-split"
              @mouseenter="movePPass('fSphereR')"
            >
              <div v-if="language === 'sv'" class="eo-selection-title">H</div>
              <div v-else class="eo-selection-title">R</div>
              <PrescriptionPassSelect
                class="eo-pass-entry-select"
                property="fSphereR"
                :signed="true"
                :decimals="2"
              />
            </div>

            <div
              class="eo-pass-entry-r-split"
              @mouseenter="movePPass('fSphereL')"
            >
              <div v-if="language === 'sv'" class="eo-selection-title">V</div>
              <div v-else class="eo-selection-title">L</div>
              <PrescriptionPassSelect
                class="eo-pass-entry-select"
                property="fSphereL"
                :signed="true"
                :decimals="2"
              />
            </div>
          </div>
        </div>
        <div class="eo-pass-entry-row" @mouseenter="movePPass('fCylinder')">
          <div class="eo-pass-entry-row-l" @mouseenter="movePPass('fCylinder')">
            <div class="eo-pass-entry-property-title">
              <strong> {{ txt('ls_zylinder') }} </strong>&nbsp;
              {{ txt('ls_cylinder_short') }}
              <PropertyTooltipp
                class="eo-pass-entry-tooltipp"
                :title="txt('ls_zylinder')"
                :text="txt('ls_brillenpass_zylinder')"
              />
            </div>
          </div>
          <div class="eo-pass-entry-row-r eo-pass-entry-split-view">
            <div
              class="eo-pass-entry-r-split"
              @mouseenter="movePPass('fCylinderR')"
            >
              <div v-if="language === 'sv'" class="eo-selection-title">H</div>
              <div v-else class="eo-selection-title">R</div>
              <PrescriptionPassSelect
                class="eo-pass-entry-select"
                property="fCylinderR"
                :signed="true"
                :decimals="2"
                :optional="optionalAxis"
              />
            </div>
            <div
              class="eo-pass-entry-r-split"
              @mouseenter="movePPass('fCylinderL')"
            >
              <div v-if="language === 'sv'" class="eo-selection-title">V</div>
              <div v-else class="eo-selection-title">L</div>
              <PrescriptionPassSelect
                property="fCylinderL"
                class="eo-pass-entry-select"
                :signed="true"
                :decimals="2"
                :optional="optionalAxis"
              />
            </div>
          </div>
        </div>
        <div class="eo-pass-entry-row" @mouseenter="movePPass('fAxis')">
          <div class="eo-pass-entry-row-l" @mouseenter="movePPass('fAxis')">
            <div class="eo-pass-entry-property-title">
              <strong>
                {{ txt('ls_achse') }}
              </strong>
              &nbsp;
              {{ txt('ls_axis_short') }}
              <PropertyTooltipp
                class="eo-pass-entry-tooltipp"
                :title="txt('ls_brillenpass_achse_headline')"
                :text="txt('ls_i_achse')"
              />
            </div>
          </div>
          <div class="eo-pass-entry-row-r eo-pass-entry-split-view">
            <div
              class="eo-pass-entry-r-split"
              @mouseenter="movePPass('fAxisR')"
            >
              <div v-if="language === 'sv'" class="eo-selection-title">H</div>
              <div v-else class="eo-selection-title">R</div>
              <PrescriptionPassSelect
                class="eo-pass-entry-select"
                property="fAxisR"
                unit="°"
                :optional="optionalAxis"
              />
            </div>
            <div
              class="eo-pass-entry-r-split"
              @mouseenter="movePPass('fAxisL')"
            >
              <div v-if="language === 'sv'" class="eo-selection-title">V</div>
              <div v-else class="eo-selection-title">L</div>
              <PrescriptionPassSelect
                class="eo-pass-entry-select"
                property="fAxisL"
                unit="°"
                :optional="optionalAxis"
              />
            </div>
          </div>
        </div>
        <div
          class="eo-pass-entry-row"
          :class="{ disabled: bUnknownPupilDistance }"
          @click="enablePupilDistance()"
          @mouseenter="movePPass('fPDist')"
        >
          <div class="eo-pass-entry-row-l" @mouseenter="movePPass('fPDist')">
            <div class="eo-pass-entry-property-title">
              <strong>
                {{ txt('ls_pupillendistanz') }}
              </strong>
              &nbsp;
              {{ txt('ls_pupil_distance_short') }}
              <PropertyTooltipp
                class="eo-pass-entry-tooltipp"
                :title="txt('ls_pupillendistanz')"
                :text="txt('ls_i_pupillendistanz')"
              />
            </div>
          </div>
          <div class="eo-pass-entry-row-r eo-pass-entry-split-view">
            <div
              class="eo-pass-entry-r-split"
              @mouseenter="movePPass('fPDistR')"
            >
              <div v-if="language === 'sv'" class="eo-selection-title">H</div>
              <div v-else class="eo-selection-title">R</div>
              <PrescriptionPassSelect
                class="eo-pass-entry-select"
                property="fDistanceR"
                unit=""
                :decimals="1"
              />
            </div>
            <div
              class="eo-pass-entry-r-split"
              @mouseenter="movePPass('fPDistL')"
            >
              <div v-if="language === 'sv'" class="eo-selection-title">V</div>
              <div v-else class="eo-selection-title">L</div>
              <PrescriptionPassSelect
                class="eo-pass-entry-select"
                property="fDistanceL"
                unit=""
                :decimals="1"
              />
            </div>
          </div>
        </div>
        <div
          v-if="hasLensDesign"
          class="eo-pass-entry-row"
          @mouseenter="movePPass('fAddition')"
        >
          <div class="eo-pass-entry-row-l" @mouseenter="movePPass('fAddition')">
            <div class="eo-pass-entry-property-title">
              <strong>
                {{ txt('ls_addition') }}
              </strong>
              &nbsp;
              {{ txt('ls_addition_short') }}
              <PropertyTooltipp
                class="eo-pass-entry-tooltipp"
                :title="txt('ls_brillenpass_addition_headline')"
                :text="txt('ls_i_addition')"
              />
            </div>
          </div>
          <div class="eo-pass-entry-row-r eo-pass-entry-split-view">
            <div
              class="eo-pass-entry-r-split"
              @mouseenter="movePPass('fAdditionR')"
            >
              <div v-if="language === 'sv'" class="eo-selection-title">H</div>
              <div v-else class="eo-selection-title">R</div>
              <PrescriptionPassSelect
                class="eo-pass-entry-select"
                property="fAdditionR"
                unit=""
                :optional="false"
                :decimals="2"
              />
            </div>
            <div
              class="eo-pass-entry-r-split"
              @mouseenter="movePPass('fAdditionL')"
            >
              <div v-if="language === 'sv'" class="eo-selection-title">V</div>
              <div v-else class="eo-selection-title">L</div>
              <PrescriptionPassSelect
                class="eo-pass-entry-select"
                property="fAdditionL"
                unit=""
                :optional="false"
                :decimals="2"
              />
            </div>
          </div>
        </div>
        <div v-if="error.code && !inputCompleted" class="eo-pass-entry-row">
          <div class="eo-pass-entry-row-l" />
          <div class="eo-pass-entry-row-r">
            <div class="error-message">
              {{ txt('ls_e_choose_values') }}
            </div>
          </div>
        </div>
        <div class="eo-pass-entry-row">
          <div class="eo-pass-entry-row-l" @mouseenter="movePPass('fPDist')">
            <div
              class="eo-unknown-pupil-text"
              @click="bUnknownPupilDistance = !bUnknownPupilDistance"
            >
              ⮞
              {{ txt('ls_measure_pupil_distance') }}
              <span class="text-white"></span>
            </div>
          </div>
          <div class="eo-pass-entry-row-r eo-unknown-pupil-row">
            <span class="eo-reset-values-text" @click="resetValues()">
              {{ txt('ls_reset_values') }}
            </span>
          </div>
        </div>
        <div class="eo-pass-entry-row">
          <div v-if="bUnknownPupilDistance" class="eo-pupil-infobox">
            <div class="eo-pupil-infobox-img">
              <img src="../../assets/classic_claim_tel.svg" alt="" />
            </div>
            <div class="eo-pupil-infobox-content">
              <a
                :href="
                  'https://img.edel-optics.de/pdf/pd_instructions_' +
                  language +
                  '.pdf'
                "
                target="_blank"
              >
                {{ txt('ls_i_measure_pupil_distance') }} </a
              >.
            </div>
          </div>
        </div>
      </div>
      <div class="eo-pass-passes eo-pass-passes-active">
        <div class="eo-pass-passes-active-img">
          <img
            v-if="currentPass"
            :src="require('../../' + currentPass.bgfl)"
            :class="{ hidden: initializedBackground }"
            alt=""
          />
        </div>
        <div
          v-if="currentPassImage"
          class="eo-pass-passes-active-img-zoom"
          :style="[
            backgroundStyle,
            {
              backgroundImage: currentPassImage,
            },
          ]"
        />

        <img
          class="eo-pass-passes-circle"
          :class="{ visible: showCircle }"
          :style="circleStyle"
          src="../../assets/ppasscircle.svg"
          alt=""
        />
        <transition name="info">
          <div v-if="showMessage" class="eo-pass-passes-message">
            <span
              v-if="
                showMessage === 'fAdditionR' || showMessage === 'fAdditionL'
              "
            >
              {{ txt('ls_i_no_add') }}
            </span>
            <span v-else>
              {{ txt('ls_i_no_pd') }}
            </span>
          </div>
        </transition>
      </div>
      <div class="eo-pass-passes eo-pass-passes-other">
        <div
          v-for="ppass in ppasses"
          :key="ppass"
          class="eo-pass-passes-other-pass"
        >
          <img
            v-if="pmove[ppass]"
            :src="require('../../' + pmove[ppass].bgfl)"
            alt=""
            @click="movePPassChange(ppass)"
          />
        </div>
      </div>
    </div>

    <Warning
      v-if="
        ((fSphereR > 0 && fSphereL < 0) || (fSphereR < 0 && fSphereL > 0)) &&
        language === 'de'
      "
      class="prescription-pass-warning"
      title="Unterschiedliche Vorzeichen (SPH)"
    >
      Ihre Sphären-Werte unterscheiden sich im Vorzeichen. Wir bitten Sie die
      Werte noch einmal gründlich zu überprüfen, da dies sehr selten vorkommt.
      Falls noch Unklarheiten bestehen rufen sie uns kostenfrei unter der 0800
      5893427583 an oder schreiben Sie eine Email an:
      <a href="mailto:info@edel-optics.de"> info@edel-optics.de </a>
    </Warning>

    <Warning
      v-if="Math.abs(fSphereR - fSphereL) > 4 && language === 'de'"
      class="prescription-pass-warning"
      title="Stark voneinander abweichende Werte (SPH)"
    >
      Die Werte Ihrer Sphäre unterscheiden sich recht stark von einander. Bitte
      überprüfen Sie die Werte noch einmal genau, um einen Fehler zu vermeiden.
      Falls noch Unklarheiten bestehen rufen sie uns kostenfrei unter der 0800
      5893427583 an oder schreiben Sie eine Email an:
      <a href="mailto:info@edel-optics.de"> info@edel-optics.de </a>
    </Warning>

    <Warning
      v-if="
        ((fAxisR > 0 &&
          (!fCylinderR ||
            fCylinderR === 'null' ||
            fCylinderR === 'undefined')) ||
          ((!fCylinderL ||
            fCylinderL === 'null' ||
            fCylinderL === 'undefined') &&
            fAxisL > 0)) &&
        language === 'de'
      "
      class="prescription-pass-warning"
      title="Zylinderwert fehlt (CYL)"
    >
      Aufgrund der Eingabe eines Werts bei der Achse, ist eine Eingabe des
      Zylinders zwingend erforderlich. Falls noch Unklarheiten bestehen rufen
      sie uns kostenfrei unter der 0800 5893427583 an oder schreiben Sie eine
      Email an:
      <a href="mailto:info@edel-optics.de"> info@edel-optics.de </a>
    </Warning>

    <Warning
      v-if="
        ((fCylinderR > 0 && fCylinderL < 0) ||
          (fCylinderR < 0 && fCylinderL > 0)) &&
        language === 'de'
      "
      class="prescription-pass-warning"
      title="Unterschiedliche Vorzeichen (CYL)"
    >
      Ihre Zylinder-Werte unterscheiden sich im Vorzeichen. Wir bitten Sie die
      Werte noch einmal gründlich zu überprüfen, da dies sehr selten vorkommt.
      Falls noch Unklarheiten bestehen rufen sie uns kostenfrei unter der 0800
      5893427583 an oder schreiben Sie eine Email an:
      <a href="mailto:info@edel-optics.de"> info@edel-optics.de </a>
    </Warning>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import prescriptionMove from '../../data/prescription-pass.json';
import translation from '../../mixins/translation';
import Warning from '../alerts/Warning';
import LensBlockHeadline from '../LensBlockHeadline';
import PrescriptionPassSelect from './PrescriptionPassSelect';
import PropertyTooltipp from './PropertyTooltipp';

export default {
  name: 'PrescriptionPass',
  components: {
    PrescriptionPassSelect,
    LensBlockHeadline,
    PropertyTooltipp,
    Warning,
  },
  mixins: [translation],
  props: {},
  data() {
    return {
      selectedPass: null,
      pmove: prescriptionMove,
      showMessage: false,
      messageText: '',
      showCircle: false,
      circleStyle: {
        left: 0,
        top: 0,
        width: 0,
        height: 0,
        transform: '',
      },
      backgroundStyle: {
        backgroundImage: '',
        backgroundSize: 0,
        backgroundPosition: 0,
      },

      initializedBackground: false,
    };
  },
  computed: {
    bUnknownPupilDistance: {
      get() {
        return this.$store.state.localQuery.bUnknownPupilDistance;
      },
      set(value) {
        this.setPupilDistance(value);
      },
    },
    ...mapState({
      fSphereR: (state) => state.query.oConditions.fSphereR,
      fSphereL: (state) => state.query.oConditions.fSphereL,
      fAxisR: (state) => state.query.oConditions.fAxisR,
      fAxisL: (state) => state.query.oConditions.fAxisL,
      fCylinderL: (state) => state.query.oConditions.fCylinderL,
      fCylinderR: (state) => state.query.oConditions.fCylinderR,
      windowWidth: (state) => state.window.innerWidth,
      error: (state) => state.error,
    }),
    ...mapGetters({
      getTranslations: 'translation/getTranslation',
      inputCompleted: 'query/inputCompleted',
      language: 'profile/language',
    }),
    ...mapGetters('facets', ['selected']),
    currentPass() {
      return this.pmove[this.activePass];
    },
    currentPassImage() {
      if (this.currentPass) {
        return 'url(' + require('../../' + this.currentPass.bgfl) + ')';
      }
      return '';
    },
    optionalAxis() {
      return (
        isNaN(this.fCylinderL) &&
        isNaN(this.fCylinderR) &&
        isNaN(this.fAxisL) &&
        isNaN(this.fAxisR)
      );
    },
    activePass() {
      return this.selectedPass || this.ppasses[0];
    },

    ppasses() {
      switch (this.language) {
        case 'de':
          return ['optiker1', 'optiker2', 'rezept'];
        case 'pl':
          return ['pl-ppass-3', 'pl-ppass-2', 'pl-ppass-1'];
        case 'fr':
          return ['fr-ppass-1', 'fr-ppass-2'];
        case 'hu':
          return ['hu-ppass-2', 'hu-ppass-1'];
        case 'ro':
          return ['ro-ppass-4', 'en-ppass-2'];
        case 'sv':
          return ['sv-ppass-1', 'sv-ppass-2'];
        case 'da':
        case 'nl':
        case 'en':
          return ['en-ppass-1', 'en-ppass-2', 'en-ppass-3'];
      }
      return [];
    },
    hasLensDesign() {
      const types = ['progressive', 'workingplace'];
      return types.includes(this.selected('lensType').value);
    },
  },
  watch: {
    inputCompleted(completed) {
      if (completed) {
        this.setError({ code: 0, message: '' });
      }
    },
  },
  mounted() {
    this.resetPPass();
  },
  methods: {
    ...mapActions({
      setCondition: 'query/setCondition',
      setLocalCondition: 'localQuery/setCondition',
      setPupilDistance: 'localQuery/setPupilDistance',
      resetValues: 'query/resetValues',
      setError: 'error/setError',
    }),
    movePPass(key) {
      this.resetPPass();

      if (!this.currentPass) {
        return;
      }

      if (this.currentPass[key].ovsx === 'none') {
        this.showMessage = key;
      } else {
        this.showCircle = true;

        const x = this.currentPass[key].bgdx || this.currentPass.bgdx;
        const y = this.currentPass[key].bgdy || this.currentPass.bgdy;

        this.backgroundStyle.backgroundSize = this.currentPass.bgsc;
        this.backgroundStyle.backgroundPosition = x + 'px ' + y + 'px';

        this.circleStyle.left = this.currentPass[key].ovpx + 'px';
        this.circleStyle.top = this.currentPass[key].ovpy + 'px';
        this.circleStyle.width = this.currentPass[key].ovsx + 'px';
        this.circleStyle.height = this.currentPass[key].ovsy + 'px';
        this.circleStyle.transform =
          'rotateY(' + this.currentPass[key].roty + 'deg)';
      }
    },
    resetPPass() {
      this.initializedBackground = true;

      this.showMessage = false;
      this.showCircle = false;

      this.backgroundStyle.backgroundSize = 'auto 100%';
      this.backgroundStyle.backgroundPosition = '0 0';
    },
    movePPassChange(activePass) {
      this.selectedPass = activePass;
      this.resetPPass();
    },
    txt(sIndicator) {
      if (!this.getTranslations) {
        return '';
      }
      return this.getTranslations[sIndicator];
    },
    enablePupilDistance() {
      if (this.bUnknownPupilDistance) {
        this.bUnknownPupilDistance = false;
      }
    },
  },
};
</script>

<style>
.multiselect__content-wrapper {
  min-height: 260px;
}
</style>

<style scoped>
.eo-pass {
  margin-top: 20px;
}

.eo-pass-content {
  margin-left: 30px;
  display: flex;
  justify-content: center;
}

.prescription-pass-warning {
  margin-left: 30px;
  margin-bottom: 20px;
}

.eo-pass-entry {
  display: flex;
  flex-direction: column;
  flex: 1 0 462px;
  max-width: 500px;
  margin-right: 30px;
}

.eo-pass-entry-title {
  background-color: #000;
  color: #fff;
  font-weight: bold;
  font-size: 1.05em;
  min-height: 24px;
  padding: 0.2rem 0.4075rem;
  text-transform: uppercase;
}

.eo-pass-entry-row {
  display: flex;
  flex-direction: row;
  margin-bottom: 13px;
}

.eo-pass-entry-row-l {
  flex: 0 0 185px;
  max-width: 185px;
  text-align: right;
  font-size: 1.1em;
  margin-right: 10px;
  align-content: center;
}

.eo-pass-entry-row-r {
  width: 280px;
  display: flex;
  flex-direction: row;
}

.eo-unknown-pupil-row {
}

.eo-unknown-pupil-text {
  cursor: pointer;
  user-select: none;
  font-size: 10pt;
  justify-self: end;
  text-align: center;
  white-space: nowrap;
}

.eo-selection-title {
  background-color: #000;
  color: #fff;
  font-weight: bold;
  font-size: 1em;
  padding: 6px 8px;
  margin-bottom: 4px;
  flex-shrink: 0;
  display: none;
}

.eo-pass-entry-split-view {
  justify-content: space-between;
}

.eo-pass-entry-r-split {
  width: 45%;
  display: flex;
  flex-direction: row;
}

.eo-pass-entry-select {
  flex-grow: 1;
}

.eo-pass-entry-property-title {
  padding-top: 6px;
  display: flex;
  justify-content: flex-end;
  white-space: nowrap;
}

.eo-pass-entry-property-title .infoicon {
  flex: 0 0 auto;
  margin: -1px 5px 0 10px;
}

.eo-reset-values-text {
  cursor: pointer;
  background-color: #fff;
  color: #e94e1b;
  border: 1px solid #e94e1b;
  text-transform: uppercase;
  font-weight: bold;
  padding: 6px 10px;
  margin-top: 3px;
  margin-left: auto;
  margin-right: -10px;
}

.eo-reset-values-text:hover {
  background-color: #e94e1b;
  color: white;
}

@media screen and (max-width: 330px) {
  .multiselect__select {
    display: none !important;
    width: 0 !important;
  }
}

@media screen and (max-width: 880px) {
  .eo-pass-entry {
    flex: 1;
    max-width: 500px;
  }

  .eo-pass-passes {
    display: none !important;
  }

  .eo-pass-title-row {
    display: none !important;
  }

  .eo-pass-entry-row {
    flex-direction: column;
  }

  .eo-pass-entry-row-l {
    flex: 1;
    margin-bottom: 5px;
  }

  .eo-pass-entry-row-r {
    width: auto;
  }

  .eo-pass-entry-r-split {
    width: 48%;
  }

  .eo-pass-entry-property-title {
    justify-content: flex-start;
  }

  .eo-selection-title {
    display: block;
  }

  .eo-reset-values-text {
    margin: 10px auto;
  }
}

.error-message {
  color: red;
  margin: -4px auto -6px auto;
}

@media screen and (max-width: 420px) {
  .eo-pass-entry-r-split {
    width: 45%;
  }
}

@media screen and (max-width: 380px) {
  .eo-pass-entry-r-split {
    width: 48%;
  }
}

.eo-pass-passes-active {
  height: 255px;
  overflow-x: hidden;
  overflow-y: hidden;
  position: relative;
}

.eo-pass-passes-active-img {
  height: 100%;
  width: 400px;
}

.eo-pass-passes-active-img-zoom {
  position: absolute;
  background-repeat: no-repeat;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-size: 0;
  background-position: 0;

  transition: all 0.2s ease;
}

.eo-pass-passes-circle {
  display: none;
  position: absolute;
  z-index: 100;
  transition: all 0.2s;
}

.eo-pass-passes-circle.visible {
  display: block;
}

.eo-pass-passes-message {
  position: absolute;
  z-index: 70;
  bottom: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.7);
  color: #fff;
  font-size: 1.1em;
  padding: 8px;
}

.eo-pass-passes-other {
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-left: 10px;
  width: 125px;
  overflow-x: hidden;
  direction: rtl;
  height: 255px;
}

.eo-pass-passes-other-pass {
  width: 125px;
  cursor: pointer;
  max-height: 85px;
  overflow-y: hidden;
}

.eo-pass-passes-other-pass img {
  position: relative;
  width: 100%;
}

.eo-pupil-infobox {
  display: flex;
  margin-top: 10px;
  background-color: #eee;
  padding: 5px 10px 5px 20px !important;
}

.eo-pupil-infobox-img {
  margin-top: 12px;
  width: 28px;
  flex-shrink: 0;
}

.eo-pupil-infobox-img img {
  width: 100%;
}

.eo-pupil-infobox-content {
  padding: 8px 14px;
}

.disabled {
  opacity: 0.4;
  cursor: default;
}

.disabled > * {
  pointer-events: none;
}

.radio {
  display: flex;
  height: 18px;
  margin: 2px 0;
  cursor: pointer;
}

.radio input {
  position: absolute;
  cursor: pointer;
  opacity: 0;
}

.radio input + label:before {
  content: '';
  background: #f4f4f4;
  border-radius: 100%;
  border: 1px solid #b4b4b4;
  display: inline-block;
  width: 18px;
  height: 18px;
  cursor: pointer;
  text-align: center;
  transition: all 250ms ease;
}

.radio input[type='checkbox'] + label:before {
  border-radius: 2px;
}

.radio input:checked + label:before {
  background-color: #e94e1b;
  box-shadow: inset 0 0 0 3px #f4f4f4;
}

.radio input:focus + label:before {
  outline: none;
  border-color: #e94e1b;
}

.radio input:disabled + label:before {
  box-shadow: inset 0 0 0 3px #f4f4f4;
  border-color: #b4b4b4;
  background: none;
}

.info-enter-active {
  transition: all 0.2s;
}

.info-leave-active {
  transition: all 0.4s;
}

.info-leave,
.info-enter-to {
  max-height: 80px;
  max-width: 400px;
}

.info-enter,
.info-leave-to {
  max-width: 0;
  max-height: 0;
  padding: 0;
}

.hidden {
  opacity: 0;
}

.eo-reset-values {
  margin-left: auto;
}

.eo-pass-entry-tooltipp {
  margin-left: 6px;
}
</style>
