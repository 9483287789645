<template>
  <div class="warning-box">
    <div class="warning-box-icon">
      <!-- eslint-disable -->
      <svg fill="currentColor" viewBox="0 0 20 20">
        <path
          fill-rule="evenodd"
          d="M8.257 3.099c.765-1.36 2.722-1.36 3.486 0l5.58 9.92c.75 1.334-.213 2.98-1.742 2.98H4.42c-1.53 0-2.493-1.646-1.743-2.98l5.58-9.92zM11 13a1 1 0 11-2 0 1 1 0 012 0zm-1-8a1 1 0 00-1 1v3a1 1 0 002 0V6a1 1 0 00-1-1z"
          clip-rule="evenodd"
        />
      </svg>
      <!-- eslint-enable -->
    </div>
    <div class="warning-box-content">
      <h3 class="warning-box-content-title">
        Achtung!
        <span v-if="title">
          &mdash;
          {{ title }}
        </span>
      </h3>
      <div class="warning-box-content-slot">
        <p>
          <slot />
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Warning',
  props: {
    title: {
      type: String,
      default: '',
    },
  },
};
</script>

<style scoped>
.warning-box {
  border-radius: 0.375rem;
  background-color: #fdfdea;
  padding: 1rem;
  display: flex;
}

.warning-box-icon {
  flex-shrink: 1;
}

.warning-box-icon svg {
  height: 1.25rem;
  width: 1.25rem;
  color: #e3a008;
}

.warning-box-content {
  margin-left: 0.75rem;
  font-size: 0.875rem;
}

.warning-box-content-title {
  line-height: 1.25rem;
  color: #723b13;
  font-weight: 500;
}

.warning-box-content-slot {
  margin-top: 0.5rem;
  line-height: 1.25rem;
  color: #8e4b10;
}
</style>
