export default {
  namespaced: true,

  state: {
    iSelectedBrand: 257,

    aLenses: [],
    aLensesByBrand: [],

    sign: '€',

    oFacets: {
      aSpheres: {
        aValues: [],
        aCounts: [],
        aPrices: [],
      },
      aCylinders: {
        aValues: [],
        aCounts: [],
        aPrices: [],
      },
      aAxes: {
        aValues: [],
        aCounts: [],
        aPrices: [],
      },
      fDistanceL: {
        aValues: Array.from({ length: 27 }, (_, i) => i * 0.5 + 25),
        aCounts: [],
        aPrices: [],
      },
      fDistanceR: {
        aValues: Array.from({ length: 27 }, (_, i) => i * 0.5 + 25),
        aCounts: [],
        aPrices: [],
      },
      aAddition: {
        aValues: Array.from({ length: 10 }, (_, i) => Number(i)),
        aCounts: [],
        aPrices: [],
      },
    },
    oProduct: null,
    sProductUrl: '/',
    iTotalLensCount: 0,

    bLoading: false,
  },

  getters: {
    getProductCount(state) {
      return state.aLenses.length;
    },

    getTotalProductCount(state) {
      return state.iTotalLensCount;
    },

    getSelectedLenses(state) {
      const aSelectedLenses = [];

      state.aLenses.forEach(function (oLens) {
        if (oLens.iBrand === state.iSelectedBrand) {
          aSelectedLenses.push(oLens);
        }
      });

      return aSelectedLenses;
    },

    isRxOnly(state) {
      return (
        state.oProduct &&
        state.oProduct.prd_eType === 'eoProductsGlassesSunglasses' &&
        !state.oProduct.psu_bPrescriptionAvailable &&
        state.oProduct.pgl_bRxAble
      );
    },
  },

  mutations: {
    setResult(state, oResult) {
      state.oProduct = oResult.oProduct;
      state.sProductUrl = oResult.sProductUrl;
      state.aLenses = oResult.aLenses;

      for (const sKey in oResult.oFacets) {
        if (oResult.oFacets.hasOwnProperty(sKey)) {
          state.aLenses = oResult.aLenses;
          state.oFacets[sKey] = oResult.oFacets[sKey];
        }
      }

      state.iTotalLensCount = oResult.iTotalLensCount;

      state.bLoading = false;

      state.sign = oResult.sign;
    },

    reset(state) {
      state.oFacets.aSpheres.aValues = [];
      state.oFacets.aCylinders.aValues = [];
      state.oFacets.aAxes.aValues = [];
    },

    selectBrand(state, payload) {
      state.iSelectedBrand = payload.iSelectedBrand;
    },

    requestQuery(state) {
      state.bLoading = true;
    },
  },

  actions: {
    setResult({ commit }, oResult) {
      commit('setResult', oResult);
      this.dispatch('localResult/setResult', oResult);
      this.dispatch('localResult/tryToSelectColor');
      this.dispatch('localResult/tryToSelectPercent');
    },

    reset({ commit }) {
      commit('reset');
    },

    requestQuery({ commit }) {
      commit('requestQuery');
    },

    selectBrand({ commit }, iBrand) {
      commit('selectBrand', { iSelectedBrand: iBrand });
      const payload = { iSelectedBrand: iBrand };
      this.dispatch('localResult/selectBrand', payload);
    },

    selectCheapestBrand({ state, dispatch }) {
      const brand = 257;
      dispatch('selectBrand', brand);
    },
  },
};
