import progressiveType from '@/store/modules/facets/progressiveType.json';
import Vue from 'vue';
import router from '../../router.js';
import glazingColors from '../../data/glazing-colors';
import facets from '../../data/facets.json';

export default {
  namespaced: true,
  state: {
    oConditions: {
      iProduct: null,
      fSphereL: null,
      fSphereR: null,
      fCylinderL: null,
      fCylinderR: null,
      fAxisL: null,
      fAxisR: null,
      fDistanceL: null,
      fDistanceR: null,
      fAdditionL: null,
      fAdditionR: null,
      sLenstype: 'singlevision',
    },
    oDefaultConditions: {
      fSphereL: null,
      fSphereR: null,
      fCylinderL: null,
      fCylinderR: null,
      fAxisL: null,
      fAxisR: null,
      fDistanceL: 31.5,
      fDistanceR: 31.5,
      fAdditionL: null,
      fAdditionR: null,
    },
    iLimit: 24,
    iOffset: 0,
  },
  getters: {
    get(state) {
      return state;
    },

    getRoute(state) {
      const oRoute = {};
      for (const sKey in state.oConditions) {
        if (state.oConditions.hasOwnProperty(sKey)) {
          const mValue = state.oConditions[sKey];
          if (
            !(
              mValue === 'null' ||
              mValue === null ||
              mValue === undefined ||
              mValue === 'undefined'
            )
          ) {
            oRoute[sKey] = state.oConditions[sKey];
          }
        }
      }
      return oRoute;
    },

    hasPositiveValues(state) {
      return state.oConditions.fSphereL > 0 || state.oConditions.fSphereR > 0;
    },
    isNotZero(state) {
      return (
        parseFloat(state.oConditions.fSphereL) !== 0 ||
        parseFloat(state.oConditions.fSphereR) !== 0 ||
        parseFloat(state.oConditions.fCylinderL) !== 0 ||
        parseFloat(state.oConditions.fCylinderR) !== 0
      );
    },
    additionCompleted(state) {
      return (
        state.oConditions.sLenstype === 'singlevision' ||
        (!isNaN(parseFloat(state.oConditions.fAdditionL)) &&
          !isNaN(parseFloat(state.oConditions.fAdditionR)))
      );
    },

    inputCompleted(state) {
      return (
        !isNaN(parseFloat(state.oConditions.fSphereL)) &&
        !isNaN(parseFloat(state.oConditions.fSphereR)) &&
        ((isNaN(parseFloat(state.oConditions.fCylinderL)) &&
          isNaN(parseFloat(state.oConditions.fCylinderR)) &&
          isNaN(parseInt(state.oConditions.fAxisL)) &&
          isNaN(parseInt(state.oConditions.fAxisR))) ||
          (!isNaN(parseFloat(state.oConditions.fCylinderL)) &&
            !isNaN(parseFloat(state.oConditions.fCylinderR)) &&
            !isNaN(parseInt(state.oConditions.fAxisL)) &&
            !isNaN(parseInt(state.oConditions.fAxisR))))
      );
    },
  },

  mutations: {
    setCondition(state, oCondition) {
      Vue.set(state.oConditions, oCondition.sKey, oCondition.mValue);
      if (oCondition.sKey !== 'sShade') {
        localStorage.setItem(oCondition.sKey, oCondition.mValue);
      }
    },

    removeCondition(state, sKey) {
      // state.oConditions[sKey] = null
      Vue.delete(state.oConditions, sKey);
    },

    setLimit(state, iLimit) {
      state.iLimit = iLimit;
    },

    setOffset(state, iOffset) {
      state.iOffset = iOffset;
    },
  },

  actions: {
    initialize({ commit, state, getters }, oConditions) {
      for (const sKey in oConditions) {
        if (oConditions.hasOwnProperty(sKey)) {
          const mValue = oConditions[sKey];
          commit('setCondition', { sKey: sKey, mValue: mValue });
        }
      }
      for (const sKey in state.oConditions) {
        if (state.oConditions.hasOwnProperty(sKey)) {
          const mValue = localStorage.getItem(sKey);
          if (mValue && !state.oConditions[sKey]) {
            commit('setCondition', { sKey, mValue });
          }
        }
      }
      for (const sKey in state.oDefaultConditions) {
        if (state.oDefaultConditions.hasOwnProperty(sKey)) {
          const mValue = state.oDefaultConditions[sKey];
          if (!state.oConditions[sKey]) {
            commit('setCondition', { sKey, mValue });
          }
        }
      }

      if (!state.oConditions['iProgressiveType']) {
        if (state.oConditions['sLenstype'] === 'workingplace') {
          commit('setCondition', { sKey: 'iProgressiveType', mValue: 2 });
          this.dispatch('facets/switch', {
            facet: 'progressiveType',
            value: 2,
          });
        } else {
          commit('setCondition', { sKey: 'iProgressiveType', mValue: 1 });
          this.dispatch('facets/switch', {
            facet: 'progressiveType',
            value: 1,
          });
        }
      }

      router
        .replace({
          path: '/',
          query: getters.getRoute,
        })
        .catch((e) => {
          // ignore
        });
    },

    setCondition({ commit, getters }, oCondition) {
      commit('setCondition', oCondition);
      this.dispatch('localQuery/setCondition', oCondition);
      if (oCondition.sKey === 'sShade') {
        this.dispatch('localQuery/setShade', oCondition);
      }
      router
        .replace({
          path: '/',
          query: getters.getRoute,
        })
        .catch((e) => {
          // ignore
        });
    },

    initializeShade({ dispatch }) {
      if (!router.history.current.query.sShade) {
        dispatch('detectShade');
      } else {
        this.dispatch('localQuery/setShade', {
          sKey: 'sShade',
          mValue: router.history.current.query.sShade,
        });
      }
    },

    detectShade() {
      const type = 'eoProductsGlassesSunglasses';
      const product = this.state.result.oProduct;

      if (this.state.result.oProduct.prd_eType === type) {
        const glazings = glazingColors[product.prd_iBrand];
        const lensColorName = product.psu_sLensColorName;

        if (
          (glazings &&
            glazings[lensColorName] &&
            glazings[lensColorName][0].mgc_bGradient) ||
          product.psu_bGradientLenses
        ) {
          this.dispatch('query/setCondition', {
            sKey: 'sShade',
            mValue: 'gradient',
          });
        } else {
          this.dispatch('query/setCondition', {
            sKey: 'sShade',
            mValue: 'complete',
          });
        }
      } else {
        this.dispatch('query/setCondition', {
          sKey: 'sShade',
          mValue: 'none',
        });
      }
    },

    setConditionSilent({ commit }, oCondition) {
      commit('setCondition', oCondition);
      this.dispatch('localQuery/setCondition', oCondition);
    },

    /*
     * used by vue router to set url query conditions
     */
    setConditions({ commit }, oConditions) {
      for (const sKey in oConditions) {
        if (oConditions.hasOwnProperty(sKey)) {
          const mValue = oConditions[sKey];
          const oCondition = { sKey: sKey, mValue: mValue };
          commit('setCondition', oCondition);
          this.dispatch('localQuery/setCondition', oCondition);
        }
      }
      this.dispatch('result/requestQuery');
      this.dispatch('db/executeQuery');
    },

    removeCondition({ commit, getters }, sKey) {
      commit('removeCondition', sKey);
      router
        .replace({
          path: '/',
          query: getters.getRoute,
        })
        .catch((e) => {
          // ignore
        });
    },

    removeConditionByKeyValue({ dispatch }, sKeyValue) {
      const aParts = sKeyValue.split('.');
      const sKey = aParts[0];
      dispatch('removeCondition', sKey);
    },

    removeAllConditions({ commit, state, getters }) {
      for (const sKey in state.oConditions) {
        if (state.oConditions.hasOwnProperty(sKey)) {
          commit('removeCondition', sKey);
        }
      }
      router
        .replace({
          path: '/',
          query: getters.getRoute,
        })
        .catch((e) => {
          // ignore
        });
    },

    setLimit({ commit }, iLimit) {
      commit('setLimit', iLimit);
      this.dispatch('result/reset');
      this.dispatch('db/executeQuery');
    },

    setOffset({ commit }, iOffset) {
      commit('setOffset', iOffset);
      this.dispatch('db/executeQuery');
    },

    resetValues({ state, getters }) {
      const conditions = Object.entries(state.oDefaultConditions);
      for (const [sKey, mValue] of conditions) {
        this.dispatch('query/setConditionSilent', {
          sKey,
          mValue: String(mValue),
        });
      }
      router.push({ path: '/', query: getters.getRoute }).catch((e) => {
        // ignore
      });
      this.dispatch('localQuery/setPupilDistance', false);
    },
  },
};
