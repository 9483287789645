<template>
  <div class="design-option" @click="switchFacet">
    <EoRadio property="progressiveType" :active="option.active" />
    <div class="design-option-content">
      <img
        v-if="oConditions().sLenstype === 'workingplace'"
        :src="
          require('../../assets/' +
            option.image.replace('.svg', '_workingplace.svg'))
        "
        alt=""
        class=""
      />
      <img
        v-else
        :src="require('../../assets/' + option.image)"
        alt=""
        class=""
      />
      <div class="">
        <b>
          {{ parseJsonTemplate(option.name) }}
        </b>
        <br />
        <span>
          {{ parseJsonTemplate(option.subtitle) }}
        </span>
      </div>
      <div>
        <PropertyTooltipp
          :title="parseJsonTemplate(option.name)"
          :text="parseJsonTemplate(option.description)"
          class="design-option-tool-tip"
        />
      </div>
    </div>
  </div>
</template>

<script>
import translation from '@/mixins/translation';
import { mapActions, mapState } from 'vuex';
import PropertyTooltipp from '../prescription-pass/PropertyTooltipp';

import EoRadio from '../util/EoRadio';

export default {
  name: 'LensDesignOption',
  components: { PropertyTooltipp, EoRadio },
  mixins: [translation],
  props: {
    option: {
      type: Object,
      required: true,
    },
  },
  methods: {
    ...mapActions('facets', ['switch']),
    ...mapState('query', ['oConditions']),
    switchFacet() {
      this.switch({ facet: 'progressiveType', option: this.option });
      try {
        this.$router.replace({
          query: {
            ...this.$route.query,
            iProgressiveType: this.option.value,
          },
        });
      } catch (e) {}
    },
    parseJsonTemplate(value) {
      const regexp = RegExp(/\{\{\s*(\w+)\s*\}\}/, 'g');
      let matches;
      while ((matches = regexp.exec(value)) !== null) {
        value = value.replace(matches[0], this.txt(matches[1]));
      }
      return value;
    },
    txt(indicator) {
      if (!this.getTranslations) {
        return '';
      }
      return this.getTranslations[indicator];
    },
  },
};
</script>

<style lang="scss" scoped>
.design-option {
  width: 220px;
  text-align: center;
  display: flex;
  position: relative;
}

.design-option-content {
  cursor: pointer;
  width: 100%;
}

.design-option-tool-tip {
  position: absolute;
  right: 0;
  bottom: 14px;
}
</style>
