export default {
  namespaced: true,
  state: {
    code: 0,
    message: '',
    count: 0,
  },
  getters: {},
  mutations: {
    setError(state, payload) {
      state.code = payload.code;
      state.message = payload.message;
      state.count += 1;
    },
  },
  actions: {
    setError({ commit }, error) {
      commit('setError', error);
    },
  },
};
