<template>
  <div
    class="eo-lens-option"
    :class="{ 'eo-options-brand': isBrandOption }"
    @click="toggleOptionActive(option)"
  >
    <div class="eo-lens-option-marker radio">
      <input
        v-if="facets[property].type === 'radio'"
        v-model="facets[property].selected"
        type="radio"
        :value="option.value"
        :name="raw"
      />
      <input
        v-else-if="facets[property].type === 'checkbox'"
        v-model="option.active"
        type="checkbox"
        :value="option.value"
        :name="raw"
      />
      <label />
    </div>
    <LensOptionContent :option="option" />
  </div>
</template>

<script>
import facets from '../../data/facets.json';
import LensOptionContent from './LensOptionContent';
import { mapGetters, mapActions, mapState } from 'vuex';

import translation from '../../mixins/translation';

export default {
  name: 'LensOptionSelectable',
  components: { LensOptionContent },
  mixins: [translation],
  props: {
    bluecheck: {
      type: Boolean,
      default: false,
    },
    raw: {
      type: String,
      required: true,
    },
    option: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      facets,
    };
  },
  computed: {
    ...mapState({
      oProduct: (state) => state.result.oProduct,
    }),
    ...mapGetters({
      getSelectedLenses: 'localResult/getSelectedLenses',
    }),
    property() {
      return this.raw.includes('open-') ? this.raw.substr(5) : this.raw;
    },
    isBrandOption() {
      const value = this.option.value;

      if (this.option.property) {
        return this.getSelectedLenses.some((lens) => {
          return Number(lens[this.option.property]) === value;
        });
      } else if (this.raw) {
        return this.getSelectedLenses.some((lens) => {
          return Number(lens[this.raw]) === value;
        });
      }

      return false;
    },
  },
  mounted() {
    if (this.bluecheck) {
      this.toggleOptionActive(this.option);
    }
  },
  methods: {
    ...mapActions({
      setCondition: 'localQuery/setCondition',
    }),
    toggleOptionActive: function (option) {
      const facet = this.facets[this.property];
      if (facet.type === 'radio') {
        Object.values(facet.options).forEach((facetOption) => {
          if (facetOption.value !== option.value) {
            facetOption.active = false;
          }
        });
        option.active = true;
        this.facets[this.property].selected = option.value;
      } else {
        option.active = !option.active;
      }

      if (option.active && option.value !== null) {
        this.setCondition({
          sKey: this.property,
          property: this.property,
          value: option.value,
          mValue: option.value,
          option,
        });
      } else {
        this.setCondition({
          sKey: this.property,
          property: this.property,
          value: undefined,
          mValue: option.value,
          option,
        });
      }
    },
  },
};
</script>

<style scoped>
.eo-lens-option-marker {
  width: auto;
  align-self: center;
  padding: 0 10px;
  cursor: pointer;
}

.eo-lens-option {
  display: flex;
  flex-direction: row;
}

.eo-options-brand {
  font-weight: bold;
}

.eo-lens-option-content {
  padding-right: 10px;
}
</style>
