<template>
  <span v-if="windowWidth > 300">
    <svg
      ref="infoicon"
      version="1.1"
      class="infoicon"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      width="160px"
      height="160px"
      viewBox="0 0 160 160"
      enable-background="new 0 0 20 20"
      xml:space="preserve"
    >
      <!-- eslint-disable -->
      <circle cx="79.662" cy="79.662" r="59.662" />
      <path
        d="M80,15c-35.88,0-65,29.12-65,65s29.12,65,65,65s65-29.12,65-65S115.88,15,80,15z M80,25c30.359,0,55,24.641,55,55 s-24.641,55-55,55s-55-24.641-55-55S49.641,25,80,25z"
      />
      <path
        class="m-infoicon-on"
        d="M89.998,51.25c0,6.214-5.036,11.251-11.25,11.251c-6.213,0-11.25-5.037-11.25-11.251c0-6.213,5.037-11.25,11.25-11.25 C84.962,40,89.998,45.037,89.998,51.25z"
      />
      <path
        class="m-infoicon-on"
        d="M90.665,110.96c-0.069,2.729,1.211,3.5,4.327,3.819L100,114.88V120H60.927v-5.12l5.503-0.101 c3.291-0.1,4.082-1.379,4.327-3.819V80.146c0.035-4.879-6.296-4.112-10.757-3.967v-5.074L90.665,70"
      />
      <!-- eslint-disable -->
    </svg>
  </span>
</template>

<script>
import tippy from 'tippy.js';

import { mapState } from 'vuex';

import translation from '../../mixins/translation';

export default {
  name: 'PropertyTooltipp',
  mixins: [translation],
  props: {
    title: {
      type: String,
      default: '',
    },
    text: {
      type: String,
      default: '',
    },
  },
  data() {
    return {};
  },
  computed: {
    ...mapState({
      windowWidth: (state) => state.window.innerWidth,
    }),
    type() {
      switch (this.title) {
        case this.txt('ls_sphaere'):
          return 'sph';
        case this.txt('ls_zylinder'):
          return 'cyl';
        case this.txt('ls_achse'):
          return 'ach';
        case this.txt('ls_pupillendistanz'):
          return 'pd';
        case this.txt('ls_addition'):
          return 'add';
        default:
          return '';
      }
    },
  },
  watch: {
    title() {
      this.updateTippy();
    },
    text() {
      this.updateTippy();
    },
  },
  mounted() {
    this.updateTippy();
  },
  methods: {
    updateTippy() {
      if (this.text) {
        const content =
          '<div class="infoicon-tooltipp-content">' +
          '<div class="infoicon-tooltipp-content-title">' +
          '<strong>' +
          this.title +
          '</strong>' +
          '</div>' +
          '<div class="infoicon-tooltipp-content-body">' +
          this.text +
          (this.isPrescriptionPassTooltipp()
            ? '<div>' +
              '<img src="' +
              require('../../assets/info/info-' + this.type + '.png') +
              '" alt=""' +
              ' style="width: 100%; margin-top: 10px"' +
              '>' +
              '</div>'
            : '') +
          '</div>' +
          '</div>';

        tippy(this.$refs.infoicon, {
          delay: [0, 200],
          placement: 'bottom',
          arrow: true,
          zIndex: 777,
          content,
        });
      }
    },
    isPrescriptionPassTooltipp() {
      return [
        this.txt('ls_sphaere'),
        this.txt('ls_zylinder'),
        this.txt('ls_achse'),
        this.txt('ls_addition'),
        this.txt('ls_pupillendistanz'),
      ].includes(this.title);
    },
  },
};
</script>

<style>
.infoicon-tooltipp-content {
  text-align: left;
  font-family: 'Monserat', sans-serif;
}

.infoicon-tooltipp-content-title {
  text-transform: uppercase;
  margin-bottom: 2px;
}

.infoicon-tooltipp-content-body {
}

@media print, screen and (max-width: 500px) {
  .infoicon-tooltipp-content {
    width: 230px;
  }
}
</style>

<style scoped>
svg.infoicon {
  margin-top: -1px;
  width: 20px;
  height: 20px;
  flex-shrink: 0;
  cursor: help;
  display: block;
}

svg.infoicon path,
svg.infoicon circle,
svg.infoicon .m-infoicon-on {
  fill: #777777;
  transition: fill 0.4s ease;
}

svg.infoicon circle {
  fill: #ffffff;
}

svg.infoicon:hover path,
svg.infoicon:hover circle {
  fill: #e94e1b;
}

svg.infoicon:hover .m-infoicon-on {
  fill: #ffffff;
}
</style>
