<template>
  <div class="lens-type-selection">
    <LensBlockHeadline
      blockicon="glasdesign"
      :property="txt('ls_glasses_type')"
    />

    <div class="lens-type-selection-content">
      <LensTypeSelectionOption
        v-for="option in lensType.options"
        :key="option.value"
        :option="option"
      />
    </div>
  </div>
</template>

<script>
import translation from '@/mixins/translation';
import LensBlockHeadline from '../LensBlockHeadline';
import LensTypeSelectionOption from './LensTypeSelectionOption';

import { mapState, mapActions } from 'vuex';

export default {
  name: 'TypeSelection',
  components: { LensBlockHeadline, LensTypeSelectionOption },
  mixins: [translation],
  data() {
    return {};
  },
  computed: {
    ...mapState('facets', ['lensType']),
  },
  methods: {
    ...mapActions('query', ['setCondition']),
    txt(indicator) {
      if (!this.getTranslations) {
        return '';
      }
      return this.getTranslations[indicator];
    },
  },
};
</script>

<style scoped>
.lens-type-selection {
  margin-top: 20px;
  margin-bottom: 20px;
}

.lens-type-selection-content {
  padding: 0 0 0 30px;
}
</style>
