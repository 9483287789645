<template>
  <div id="eo-option-shade" class="eo-option-shade">
    <div
      :class="{ 'eo-options-brand': isBrandOption }"
      class="eo-lens-option-shade-body"
      @click="toggleOptionActive(option)"
    >
      <div class="eo-lens-option-marker radio">
        <input
          v-if="facets[property].type === 'radio'"
          v-model="facets[property].selected"
          type="radio"
          :value="option.value"
        />
        <label />
      </div>
      <LensOptionContent :option="option" />
    </div>
    <div
      v-if="showOptions"
      class="eo-lens-option-shade-select"
      :class="{ 'eo-error-box': colorError || percentageError }"
    >
      <div v-if="showPolarized" class="eo-lens-shade-polar">
        <LensOptionNotAvailable
          v-if="isNotAvailable(polarizedOption)"
          property="refinement"
          :option="polarizedOption"
        />
        <LensOptionSelectable
          v-else
          :raw="'open-refinement'"
          :option="polarizedOption"
        />
      </div>
      <div>
        <div class="eo-lens-option-shade-select-title">
          <label class="eo-lens-option-label">
            {{ txt('ls_farbe') }}
          </label>
          <property-tooltipp
            :title="txt('ls_color_outside')"
            :text="txt('ls_i_color_outside')"
          />
        </div>
        <div class="eo-shade-option-select">
          <beat-loader
            v-if="!isIExplorer"
            :loading="bLoading"
            color="#e94e1b"
            size="8px"
            class="shade-option-spinner"
          />
          <multiselect
            v-model="currentColor"
            class="eo-shade-select"
            :options="colorOptions"
            group-values="options"
            group-label="type"
            :show-labels="false"
            open-direction="bottom"
            :placeholder="txt('ls_bitte_auswaehlen')"
            @select="selectColor"
          >
            <template slot="singleLabel" slot-scope="props">
              <div class="eo-shade-select-template">
                <div>
                  {{ txt(props.option.name) | capitalize }}
                  <span v-if="props.option.type === 'flash'">
                    {{ txt('ls_mirrored') }}
                  </span>
                </div>
                <div
                  class="eo-shade-select-template-cancel"
                  @mousedown.prevent="removeColor"
                >
                  &times;
                </div>
              </div>
            </template>
            <template slot="option" slot-scope="props">
              <div v-if="props.option.$isLabel">
                <b>
                  {{ props.option.$groupLabel }}
                </b>
              </div>
              <div v-else class="eo-shade-select-template">
                <div>
                  <span>
                    {{ txt(props.option.name) | capitalize }}
                    <span v-if="props.option.type === 'flash'">
                      {{ txt('ls_mirrored') }}
                    </span>
                  </span>
                </div>
                <div class="eo-shade-select-template-surcharge">
                  <span v-if="props.option.group === 'brand'">
                    <span v-if="props.option.brandCount > 1">
                      {{ txt('ls_from') }}
                    </span>
                    {{
                      (props.option.cheapestBrandLens.fPrice * 2) | formatPrice
                    }}
                  </span>
                  <span v-else>
                    <span v-if="props.option.count > 1">
                      {{ txt('ls_from') }}
                    </span>
                    {{ (props.option.cheapest * 2) | formatPrice }}
                  </span>
                </div>
              </div>
            </template>
          </multiselect>
        </div>
        <div class="eo-error-message">
          <span v-if="colorError">
            {{ error.message }}
          </span>
        </div>
      </div>
      <div v-if="showBaseColor" class="eo-lens-option-shade-option">
        <div class="eo-lens-option-shade-select-title">
          <label class="eo-lens-option-label">
            {{ txt('ls_choose_base_color') }}
          </label>
          <property-tooltipp
            :title="txt('ls_base_color')"
            :text="txt('ls_for_visible_color_carrier')"
          />
        </div>
        <div class="eo-shade-option-select">
          <beat-loader
            v-if="!isIExplorer"
            :loading="bLoading"
            color="#e94e1b"
            size="8px"
            class="shade-option-spinner"
          />
          <multiselect
            v-model="currentBaseColor"
            class="eo-shade-select"
            :options="baseColorOptions"
            group-values="options"
            group-label="type"
            :show-labels="false"
            open-direction="bottom"
            :placeholder="txt('ls_bitte_auswaehlen')"
            @select="selectBaseColor"
          >
            <template slot="singleLabel" slot-scope="props">
              <div v-if="props.option.$isLabel">
                <b>
                  {{ props.option.$groupLabel }}
                </b>
              </div>
              <div v-else class="eo-shade-select-template">
                <div>
                  {{ txt(props.option.name) | capitalize }}
                </div>
                <div
                  class="eo-shade-select-template-cancel"
                  @mousedown.prevent="removeBaseColor"
                >
                  &times;
                </div>
              </div>
            </template>
            <template slot="option" slot-scope="props">
              <div v-if="props.option.$isLabel">
                <b>
                  {{ props.option.$groupLabel }}
                </b>
              </div>
              <div
                v-else
                class="eo-shade-select-template"
                :class="{
                  'eo-select-brand-option': props.option.brandCount > 0,
                }"
              >
                <div>
                  <span>
                    {{ txt(props.option.name) | capitalize }}
                  </span>
                </div>
                <div class="eo-shade-select-template-surcharge">
                  <span v-if="props.option.count > 1">
                    {{ txt('ls_from') }}
                  </span>
                  <span>
                    {{ (props.option.cheapest * 2) | formatPrice }}
                  </span>
                </div>
              </div>
            </template>
          </multiselect>
        </div>
        <div class="eo-error-message">
          <span v-if="colorError">
            {{ error.message }}
          </span>
        </div>
      </div>
      <div v-if="showShadePercent" class="eo-lens-option-shade-option">
        <div class="eo-lens-option-shade-select-title">
          <label class="eo-lens-option-label">
            {{ txt('ls_toenungsgrad') }}
          </label>
          <property-tooltipp
            :title="txt('ls_shade_percent')"
            :text="txt('ls_i_toenungsgrad')"
          />
        </div>
        <div class="eo-shade-option-select">
          <beat-loader
            v-if="!isIExplorer"
            :loading="bLoading"
            color="#e94e1b"
            size="8px"
            class="shade-option-spinner"
          />
          <multiselect
            v-model="currentPercent"
            class="eo-shade-select"
            :options="percentageOptions"
            group-values="options"
            group-label="type"
            :show-labels="false"
            open-direction="bottom"
            :placeholder="txt('ls_choose_required')"
            @select="selectPercent"
          >
            <template slot="singleLabel" slot-scope="props">
              <div v-if="props.option.$isLabel">
                <b>
                  {{ props.option.$groupLabel }}
                </b>
              </div>
              <div v-else class="eo-shade-select-template">
                <div>
                  {{ formatCode(props.option.percent) }}
                </div>
                <span>&nbsp;&ndash;&nbsp;</span>
                <div>
                  {{ shadePercentLabel(props.option.percent.split('-')[0]) }}
                </div>
                <div
                  class="eo-shade-select-template-cancel"
                  @mousedown.prevent="removePercent"
                >
                  &times;
                </div>
              </div>
            </template>
            <template slot="option" slot-scope="props">
              <div v-if="props.option.$isLabel">
                <b>
                  {{ props.option.$groupLabel }}
                </b>
              </div>
              <div
                v-else
                class="eo-shade-select-template"
                :class="{
                  'eo-select-brand-option': props.option.brandCount > 0,
                }"
              >
                <div>
                  <span>
                    {{ formatCode(props.option.percent) }}
                  </span>
                  <span>&nbsp;&ndash;&nbsp;</span>
                  <div>
                    {{ shadePercentLabel(props.option.percent.split('-')[0]) }}
                  </div>
                </div>
                <div class="eo-shade-select-template-surcharge">
                  <span v-if="props.option.group === 'brand'">
                    <span v-if="props.option.brandCount > 1">
                      {{ txt('ls_from') }}
                    </span>
                    {{
                      (props.option.cheapestBrandLens.fPrice * 2) | formatPrice
                    }}
                  </span>
                  <span v-else>
                    <span v-if="props.option.count > 1">
                      {{ txt('ls_from') }}
                    </span>
                    {{ (props.option.cheapest * 2) | formatPrice }}
                  </span>
                </div>
              </div>
            </template>
          </multiselect>
        </div>
        <div class="eo-error-message">
          <span v-if="percentageError">
            {{ error.message }}
          </span>
        </div>
      </div>
    </div>
    <div v-else-if="showBlueControl" class="eo-lens-option-shade-select">
      <div>
        <LensOptionNotAvailable
          v-if="isNotAvailable(blueControlOption)"
          property="refinement"
          :option="blueControlOption"
        />
        <LensOptionSelectable
          v-else
          :bluecheck="blueTrue()"
          :raw="'open-refinement'"
          :option="blueControlOption"
        />
      </div>
      <div class="pt-2">
        <LensOptionNotAvailable
          v-if="isNotAvailable(mobileDeviceAdditiveOption)"
          property="refinement"
          :option="mobileDeviceAdditiveOption"
        />
        <LensOptionSelectable
          v-else
          :raw="'open-refinement'"
          :option="mobileDeviceAdditiveOption"
        />
      </div>
    </div>
  </div>
</template>

<script>
import Cookies from 'js-cookie';
import Multiselect from 'vue-multiselect';
import BeatLoader from 'vue-spinner/src/BeatLoader';
import { mapActions, mapGetters, mapState } from 'vuex';
import facets from '../../data/facets.json';

import translation from '../../mixins/translation';
import PropertyTooltipp from '../prescription-pass/PropertyTooltipp';
import LensOptionContent from './LensOptionContent';
import LensOptionNotAvailable from './LensOptionNotAvailable';
import LensOptionSelectable from './LensOptionSelectable';

export default {
  name: 'LensOptionShade',
  components: {
    PropertyTooltipp,
    LensOptionContent,
    LensOptionNotAvailable,
    LensOptionSelectable,
    Multiselect,
    BeatLoader,
  },
  filters: {
    capitalize(value) {
      if (!value) return '';
      value = value.toString();
      return value.charAt(0).toUpperCase() + value.slice(1);
    },
  },
  mixins: [translation],
  props: {
    property: {
      type: String,
      required: true,
    },
    option: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      facets,
      colorOptions: [],
      baseColorOptions: [],
      percentageOptions: [],
      relocated: false,
    };
  },
  computed: {
    ...mapState({
      oProduct: (state) => state.result.oProduct,
      aLenses: (state) => state.localResult.aLenses,
      shadeColors: (state) => state.localResult.shadeColors,
      flashColors: (state) => state.localResult.flashColors,
      shadePercents: (state) => state.localResult.shadePercents,
      bLoading: (state) => state.result.bLoading,
      sShade: (state) => state.query.oConditions.sShade,
      error: (state) => state.error,
    }),
    ...mapGetters({
      getSelectedLenses: 'localResult/getSelectedLenses',
      aLensesWithout: 'localResult/getLensesWithout',
      language: 'profile/language',
    }),
    allLensBrandsTitle() {
      switch (this.language) {
        case 'de':
          return 'Alle Glasmarken';
        case 'se':
          return 'Alla glasmärken';
        case 'da':
          return 'Alle glasmærker';
        case 'fr':
          return 'Toutes les marques';
        case 'pl':
          return 'Wszystkie marki soczewek';
        case 'hu':
          return 'Minden márka';
        case 'ro':
          return 'Toate mărcile';
      }
      return 'All lens brands';
    },
    currencySign() {
      return Cookies.get('sign');
    },
    blueControlOption() {
      return this.facets.refinement.options.blueControl;
    },
    mobileDeviceAdditiveOption() {
      const option = this.facets.refinement.options.mobileDeviceAdditive;

      switch (this.language) {
        case 'de':
          option.infotext =
            'Glas mit speziell geschliffenem Bereich im unteren Teil der Linse, das langes Lesen auf Mobilgeräten angenehmer macht.';
          break;
        case 'fr':
          option.infotext =
            'Les verres contiennent une zone renforcée qui augmente la puissance des verres et détend ainsi les yeux pour la lecture sur les appareils mobiles.';
          break;
        case 'sv':
          option.infotext =
            'Glasen innehåller en liten zon med ökad styrka i nedre delen av glaset som gör läsning på mobila enheter bekvämare.';
          break;
        case 'hu':
          option.infotext =
            'Egyedileg kialakított lencsék, a lencse alján egy boost zóna található, amely kényelmesebbé teszi az olvasást mobileszközökön. Ezek nem többfókuszú lencsék.';
          break;
        case 'ro':
          option.infotext =
            'Lentile confecționate individual, cu o zonă de boost în partea de jos a lentilei, care face cititul pe dispozitive mobile mai confortabil. Aceștia nu sunt lentile multifocale.';
          break;
        case 'nl':
          option.infotext =
            'De glazen bevatten een zone dat het lezen op mobiele apparaten comfortabeler maakt.';
          break;
        case 'da':
          option.infotext =
            'Individuelt fremstillede glas med en boost-zone nederst i glasset, som gør det mere behageligt at læse på mobile enheder.';
          break;
        case 'pl':
          option.infotext =
            'Na dole soczewki znajduję się dodatkowa strefa powiększająca, która zapewnia większy komfort podczas czytania na urządzeniach mobilnych';
          break;
      }

      return option;
    },
    polarizedOption() {
      return this.facets.refinement.options.polarized;
    },
    currentColor: {
      get() {
        if (
          this.$store.state.localResult.currentFlashColor &&
          this.sShade !== 'phototrop'
        ) {
          return this.$store.state.localResult.currentFlashColor;
        }
        return this.$store.state.localResult.currentColor;
      },
      set(color) {
        this.$store.dispatch('localResult/setCurrentFlashColor', '');
        this.$store.dispatch('localResult/setCurrentColor', '');

        if (color.type === 'flash') {
          this.$store.dispatch('localResult/setCurrentFlashColor', color);
        } else {
          this.$store.dispatch('localResult/setCurrentColor', color);
        }
        this.relocated = false;
      },
    },
    currentBaseColor: {
      get() {
        return this.$store.state.localResult.currentColor;
      },
      set(color) {
        this.$store.dispatch('localResult/setCurrentColor', color);
      },
    },
    currentPercent: {
      get() {
        return this.$store.state.localResult.currentPercent;
      },
      set(percent) {
        this.$store.dispatch('localResult/setCurrentPercent', percent);
      },
    },
    currentBrand() {
      if (this.getSelectedLenses && this.getSelectedLenses.length > 0) {
        return (this.getSelectedLenses[0].sBrand || '')
          .replace('ROX Sun', 'Ray-Ban')
          .replace('OTD Sun Standard', 'Oakley');
      }
      return '';
    },
    showOptions() {
      return this.option.active && this.option.value !== 'none';
    },
    showBlueControl() {
      return this.option.active && this.option.value === 'none';
    },
    showPolarized() {
      return this.option.active && this.option.value === 'complete';
    },
    showBaseColor() {
      return this.currentColor && this.currentColor.type === 'flash';
    },
    showShadePercent() {
      return (
        this.currentColor &&
        this.option.property !== 'aFlashColor' &&
        this.option.value !== 'phototrop'
      );
    },
    isBrandOption() {
      if (this.option.property === 'aFlashColor') {
        return this.getSelectedLenses.some((lens) => {
          return lens.aFlashColor.length > 0;
        });
      }
      return false;
    },
    colorError() {
      return this.error.code && !this.currentColor;
    },
    percentageError() {
      return this.error.code && !this.currentPercent;
    },
  },
  watch: {
    shadeColors() {
      this.fillColors();
      this.fillBaseColors();
    },
    shadePercents() {
      this.fillPercent();
    },
    sShade() {
      this.updateShade();
    },
    aLenses(lenses) {
      if (lenses.length === 0) {
        if (this.currentPercent) {
          this.softReset();
          this.tryToSelectPercent();
        } else {
          this.reset();
        }
      }
      this.$forceUpdate();
    },
  },
  mounted() {
    this.updateShade();
    this.fillColors();
    this.fillBaseColors();
    if (this.currentColor) {
      this.fillPercent();
    }
  },
  methods: {
    ...mapActions({
      setCondition: 'query/setCondition',
      setLocalCondition: 'localQuery/setCondition',
      tryToSelectPercent: 'localResult/tryToSelectPercent',
    }),
    preselection(option) {
      console.log('LensOptionShade: ' + option.value);
      return option.value;
    },
    isIExplorer() {
      return (
        navigator.userAgent.indexOf('MSIE ') > 0 ||
        // eslint-disable-next-line no-useless-escape
        !!navigator.userAgent.match(/Trident.*rv\:11\./)
      );
    },
    blueTrue() {
      return (
        (this.oProduct.prd_iBrand === 808 ||
          this.oProduct.prd_iBrand === 794) &&
        !this.oProduct.prd_eType === 'eoProductsGlassesSunglasses'
      );
    },
    selectOption(option) {
      const facet = this.facets[this.property];
      Object.values(facet.options).forEach((facetOption) => {
        facetOption.active = facetOption.value === option.value;
      });
      this.facets[this.property].selected = option.value;
    },
    toggleOptionActive(option) {
      if (this.option.property === 'aFlashColor') {
        const facet = this.facets[this.property];
        facet.options['flash'].active = !facet.options['flash'].active;

        this.setLocalCondition({
          property: 'aFlashColor',
          value: option.active ? true : undefined,
        });
      } else {
        if (!this.bLoading) {
          this.selectOption(option);

          this.setCondition({
            sKey: this.property,
            mValue: option.value,
          });
        }
      }
    },
    reset() {
      if (!this.relocated) {
        const value = undefined;

        this.$store.dispatch('localResult/setCurrentColor', '');
        this.$store.dispatch('localResult/setCurrentFlashColor', '');
        this.$store.dispatch('localResult/setCurrentPercent', '');

        this.currentColor = '';
        this.currentPercent = '';
        facets.refinement.options.blueControl.active = false;
        this.setLocalCondition({ property: 'bPolarisiert', value });
        facets.refinement.options.polarized.active = false;
        this.setLocalCondition({ property: 'bBlueControl', value });

        this.relocated = true;
      }
    },
    softReset() {
      const value = undefined;
      this.$store.dispatch('localResult/setCurrentPercent', '');
      this.currentPercent = '';

      facets.refinement.options.blueControl.active = false;
      this.setLocalCondition({ property: 'bPolarisiert', value });
      facets.refinement.options.polarized.active = false;
      this.setLocalCondition({ property: 'bBlueControl', value });
    },
    selectColor(value) {},
    selectBaseColor(value) {},
    selectPercent() {
      this.fillPercent();
    },
    removeColor() {
      this.$store.dispatch('localResult/setCurrentColor', '');
      if (this.currentColor.type === 'flash') {
        this.$store.dispatch('localResult/setCurrentFlashColor', '');
      }
    },
    removeBaseColor() {
      this.$store.dispatch('localResult/setCurrentColor', '');
    },
    removePercent() {
      this.$store.dispatch('localResult/setCurrentPercent', '');
    },
    updateShade() {
      if (this.$route.query.sShade && this.property !== 'refinement') {
        const facet = this.facets[this.property];
        Object.values(facet.options).forEach((facetOption) => {
          facetOption.active = facetOption.value === this.$route.query.sShade;
        });
        facet.selected = this.$route.query.sShade;
      }
    },
    formatCode(code) {
      const [max, min] = code.split('-');
      if (max === min) {
        return max + '%';
      }
      return max + '% bis ' + min + '%';
    },
    formatPrice(value) {
      return value.toFixed(2).replace('.', ',');
    },
    fillColors() {
      let mergedOptions = [];
      Object.keys(this.shadeColors)
        .filter((code) => {
          return (
            this.shadeColors[code].isBrandBaseColor &&
            parseInt(this.shadeColors[code].code.substr(1)) > 1
          );
        })
        .forEach((code) => {
          mergedOptions.push(this.shadeColors[code]);
        });

      if (this.sShade !== 'phototrop') {
        Object.keys(this.flashColors).forEach((code) => {
          mergedOptions.push(this.flashColors[code]);
        });
      }

      mergedOptions = mergedOptions.sort((k1, k2) => {
        return k1.name.localeCompare(k2.name);
      });

      this.colorOptions = [
        { type: this.currentBrand, options: [] },
        { type: this.allLensBrandsTitle, options: mergedOptions },
      ];

      mergedOptions.forEach((option) => {
        if (option.brandCount > 0) {
          const clonedOption = JSON.parse(JSON.stringify(option));
          clonedOption.group = 'brand';
          this.colorOptions[0].options.push(clonedOption);
        }
      });
    },
    fillBaseColors() {
      const mergedOptions = [];
      Object.keys(this.shadeColors).forEach((color) => {
        mergedOptions.push(this.shadeColors[color]);
      });

      this.baseColorOptions = [
        { type: this.currentBrand, options: [] },
        { type: this.allLensBrandsTitle, options: mergedOptions },
      ];

      mergedOptions.forEach((option) => {
        if (option.brandCount > 0) {
          const clonedOption = JSON.parse(JSON.stringify(option));
          clonedOption.group = 'brand';
          this.baseColorOptions[0].options.push(clonedOption);
        }
      });
    },

    fillPercent() {
      const mergedOptions = [];

      Object.keys(this.shadePercents).forEach((percent) => {
        mergedOptions.push(this.shadePercents[percent]);
      });

      this.percentageOptions = [
        { type: this.currentBrand, options: [] },
        { type: 'Alle Glasmarken', options: mergedOptions },
      ];

      mergedOptions.forEach((option) => {
        if (option.brandCount > 0) {
          const clonedOption = JSON.parse(JSON.stringify(option));
          clonedOption.group = 'brand';
          this.percentageOptions[0].options.push(clonedOption);
        }
      });
    },

    shadePercentLabel(percent) {
      if (this.language === 'de') {
        if (this.sShade === 'complete') {
          if (percent >= 0 && percent <= 25) {
            return 'Leichte Filtertönung';
          } else if (percent > 25 && percent < 75) {
            return 'Mittlere Tönung';
          } else {
            return 'Sonnenbrillentönung';
          }
        } else if (this.sShade === 'gradient') {
          if (percent <= 25) {
            return 'Helle Verlaufstönung';
          } else {
            return 'Sonnenbrillenverlauf';
          }
        }
      }
      return '';
    },

    isNotAvailable(option) {
      const property = option.property;
      const lenses = this.aLensesWithout(property);
      return (
        !option.active &&
        option.value &&
        lenses.every((lens) => lens[property] != option.value)
      );
    },
  },
};
</script>

<style scoped>
.eo-option-shade {
  cursor: pointer;
}

.eo-lens-option-shade-body {
  display: flex;
}

.eo-lens-option-shade-select {
  margin: 8px;
  padding: 8px;
  background-color: white;
}

.eo-lens-option-shade-select-title {
  font-size: 0.9em;
  font-weight: bold;
  margin: 4px 0;
}

.eo-lens-option-shade-select-title:not(:first-child) {
  margin: 8px 0 4px;
}

.eo-lens-option-label {
  float: left;
  margin: 3px 4px 0 0;
}

.eo-shade-option-select {
  display: flex;
  justify-content: center;
  align-items: center;
}

.eo-shade-select {
  width: 100%;
  line-height: 14px;
}

.shade-option-spinner {
  position: absolute;
  z-index: 10;
  margin: auto;
}

.eo-lens-option-shade-option {
  padding-top: 8px;
}

.eo-shade-select-template {
  display: flex;
}

.eo-shade-select-template-cancel {
  margin-left: auto;
  width: 20px;
  height: 20px;
  text-align: center;
  font-size: 20pt;
}

.eo-shade-select-template-cancel:hover {
  background-color: #eee;
  border-radius: 6px;
}

.eo-shade-select-template-surcharge {
  margin-left: auto;
  font-size: 11px;
}

.eo-select-brand-option {
  font-weight: bold;
}

.eo-lens-option-marker {
  width: auto;
  align-self: center;
  padding: 0 10px;
  cursor: pointer;
}

.eo-options-brand {
  font-weight: bold;
}

.eo-lens-shade-polar {
  margin-bottom: 10px;
}

.eo-error-message {
  margin-top: 5px;
  color: red;
}

.eo-error-box {
  border: 1px solid #e54d42;
}

.pt-2 {
  padding-top: 0.5rem;
}
</style>
