<template>
  <swiper-slide class="lens">
    <div class="grid-y m-llist-slide">
      <div class="cell eo-lens-list-row eo-row-material">
        <strong>
          <span v-if="product.prd_iBrand === 2 && isOakley"> Oakley </span>
          {{ name }}
          <br />
          {{ oLens.sMaterialCode }}
        </strong>
        <div v-if="oLens.smaterialname" class="m-litem-bindex">
          <span>
            <span>
              <img
                class="eo-list-indicator eo-list-arrow"
                src="../../assets/pfeil-re.png"
                alt=""
              />
            </span>
          </span>
          <!--          {{ txt('ls_kunststoff') }}-->
          {{ oLens.smaterialname }}
        </div>
        <div class="m-litem-bindex">
          <span>
            <img
              class="eo-list-indicator eo-list-arrow"
              src="../../assets/pfeil-re.png"
              alt=""
            />
          </span>
          <span> {{ txt('ls_brechungsindex') }}: </span>
          <b>{{ oLens.fBrechungsIndex }}</b>
          <PropertyTooltipp
            :title="txt('ls_brechungsindex')"
            :text="
              txt(
                'ls_i_dicke' + oLens.fBrechungsIndex.toString().replace('.', '')
              )
            "
          />
        </div>
      </div>
      <!-- eslint-disable -->
      <div
        v-if="showOverhang"
        class="cell small-auto show-for-small-only m-llist-mobittl"
        v-html="txt('ls_divider_glasdicke')"
      ></div>
      <!-- eslint-enable -->
      <div v-if="showOverhang" class="cell eo-lens-list-row eo-row-overhang">
        <span>
          <img
            class="eo-list-indicator eo-list-arrow"
            src="../../assets/pfeil-re.png"
            alt=""
          />
        </span>
        <span class="flex items-center">
          {{ mdecimalcomma(mround(oLens.fUeberstand, 1)) }} mm
          <img
            class="h-5 ml-1"
            :src="defineOverflowEmoticon(oLens.fUeberstand)"
            alt=""
          />
        </span>
      </div>
      <!-- eslint-disable -->
      <div
        v-if="showCenterThickness"
        class="cell small-auto show-for-small-only m-llist-mobittl"
        v-html="txt('ls_divider_mittendicke')"
      ></div>
      <!-- eslint-enable -->
      <div
        v-if="showCenterThickness"
        class="cell eo-lens-list-row eo-row-overhang"
      >
        <span>
          <img
            class="eo-list-indicator eo-list-arrow"
            src="../../assets/pfeil-re.png"
            alt=""
          />
        </span>
        <span class="flex items-center">
          {{ mdecimalcomma(mround(oLens.fCenterThickness, 1)) }} mm
          <img
            class="h-5 ml-1"
            :src="defineCenterEmoticon(oLens.fCenterThickness)"
            alt=""
          />
        </span>
      </div>
      <!-- eslint-disable -->
      <div
        class="cell small-auto show-for-small-only m-llist-mobittl"
        v-html="txt('ls_divider_glasdesign')"
      ></div>
      <!-- eslint-enable -->
      <div class="cell eo-lens-list-row eo-row-focus">
        <div
          v-if="parseInt(oLens.iAsphaerisch) === 2"
          class="eo-lens-list-spherical"
        >
          <span>
            <img
              class="eo-list-indicator eo-list-arrow"
              src="../../assets/pfeil-re.png"
              alt=""
            />
          </span>
          &nbsp;
          <!-- eslint-disable -->
          <span
            class="highlighted"
            v-html="replaceBr(txt('ls_unten_asphaerisch'))"
          />
          <!-- eslint-enable -->
          <PropertyTooltipp
            class="eo-lens-list-spherical-tooltipp"
            :title="txt('ls_asphaerisch')"
            :text="txt('ls_i_asphaerisch')"
          />
        </div>
        <div
          v-else-if="parseInt(oLens.iAsphaerisch) === 3"
          class="eo-lens-list-spherical"
        >
          <span>
            <img
              class="eo-list-indicator eo-list-arrow"
              src="../../assets/pfeil-re.png"
              alt=""
            />
          </span>
          &nbsp;
          <!-- eslint-disable -->
          <span
            class="highlighted"
            v-html="replaceBr(txt('ls_unten_doppeltasphaerisch'))"
          />
          <!-- eslint-enable -->
          <PropertyTooltipp
            class="eo-lens-list-spherical-tooltipp"
            :title="txt('ls_doppeltasphaerisch')"
            :text="txt('ls_i_doppeltasphaerisch')"
          />
        </div>
        <div v-else class="eo-lens-list-spherical">
          <span>
            <img
              class="eo-list-indicator eo-list-arrow"
              src="../../assets/pfeil-re.png"
              alt=""
            />
          </span>
          &nbsp;
          <!-- eslint-disable -->
          <span v-html="replaceBr(txt('ls_unten_nichtasphaerisch'))" />
          <!-- eslint-enable -->
          <PropertyTooltipp
            class="eo-lens-list-spherical-tooltipp"
            :title="txt('ls_nichtasphaerisch')"
            :text="txt('ls_i_nichtasphaerisch')"
          />
        </div>
      </div>
      <div class="cell show-for-small-only m-llist-mobittl">
        {{ txt('ls_divider_glasveredelungen') }}
      </div>
      <div class="cell eo-lens-list-row eo-row-refinement">
        <ul class="features">
          <li v-if="isRayBan" style="display: flex; align-content: center">
            <img
              class="eo-list-indicator eo-list-haken"
              src="../../assets/haken.png"
              alt=""
            />
            &nbsp;
            <span class="highlighted">
              {{ txt('ls_with') }}
            </span>
            &nbsp;&nbsp;
            <img
              class="eo-list-indicator eo-list-logo"
              src="../../assets/Ray-Ban.svg"
              alt=""
            />
            <span class="highlighted">
              {{ txt('ls_logo') }}
            </span>
            &nbsp;
            <span class="eo-property-tooltip">
              <PropertyTooltipp
                :title="txt('ls_with_logo')"
                :text="txt('ls_i_rb_logo')"
              />
            </span>
          </li>
          <li v-if="oLens.bCleancoat">
            <img
              class="eo-list-indicator eo-list-haken"
              src="../../assets/haken.png"
              alt=""
            />
            <span :class="{ highlighted: !inclusiveForBrand('bCleancoat') }">
              {{ txt('ls_cleancoat') }}
            </span>

            <span class="eo-property-tooltip">
              <PropertyTooltipp
                :title="txt('ls_cleancoat')"
                :text="txt('ls_i_cleancoat')"
              />
            </span>
          </li>
          <li v-if="oLens.bBreitbandEntspiegelt">
            <img
              class="eo-list-indicator eo-list-haken"
              src="../../assets/haken.png"
              alt=""
            />
            <span
              :class="{
                highlighted: !inclusiveForBrand('bBreitbandEntspiegelt'),
              }"
            >
              {{ txt('ls_breitbandentspiegelt') }}
            </span>

            <span class="eo-property-tooltip">
              <PropertyTooltipp
                :title="txt('ls_breitbandentspiegelt')"
                :text="txt('ls_i_breitbandentspiegelt')"
              />
            </span>
          </li>
          <li v-if="oLens.bLotusEffekt">
            <img
              class="eo-list-indicator eo-list-haken"
              src="../../assets/haken.png"
              alt=""
            />
            <span :class="{ highlighted: !inclusiveForBrand('bLotusEffekt') }">
              {{ txt('ls_veredelung_lotus') }}
            </span>

            <span class="eo-property-tooltip">
              <PropertyTooltipp
                :title="txt('ls_lotus')"
                :text="txt('ls_i_lotus')"
              />
            </span>
          </li>
          <li v-if="oLens.bAntistatisch">
            <img
              class="eo-list-indicator eo-list-haken"
              src="../../assets/haken.png"
              alt=""
            />
            <span :class="{ highlighted: !inclusiveForBrand('bAntistatisch') }">
              {{ txt('ls_antistatisch') }}
            </span>

            <span class="eo-property-tooltip">
              <PropertyTooltipp
                :title="txt('ls_antistatisch')"
                :text="txt('ls_i_antistatisch')"
              />
            </span>
          </li>
          <li v-if="oLens.bRueckentspiegelt">
            <img
              class="eo-list-indicator eo-list-haken"
              src="../../assets/haken.png"
              alt=""
            />
            <span
              :class="{
                highlighted: !inclusiveForBrand('bRueckentspiegelt'),
              }"
            >
              {{ txt('ls_rueckentspiegelt') }}
            </span>

            <span class="eo-property-tooltip">
              <PropertyTooltipp
                :title="txt('ls_rueckentspiegelt')"
                :text="txt('ls_i_rueckentspiegelt')"
              />
            </span>
          </li>
          <li v-if="oLens.iUvFilter">
            <img
              class="eo-list-indicator eo-list-haken"
              src="../../assets/haken.png"
              alt=""
            />
            <span :class="{ highlighted: !inclusiveForBrand('iUvFilter') }">
              {{ txt('ls_uvfilter').replace('100', oLens.iUvFilter) }}
            </span>

            <span class="eo-property-tooltip">
              <PropertyTooltipp
                :title="txt('ls_uvfilterunten')"
                :text="txt('ls_i_uvfilter').replace('100', oLens.iUvFilter)"
              />
            </span>
          </li>
          <li v-if="oLens.bHart">
            <img
              class="eo-list-indicator eo-list-haken"
              src="../../assets/haken.png"
              alt=""
            />
            <span :class="{ highlighted: !inclusiveForBrand('bExtrahart') }">
              {{ txt('ls_gehaertet') }}
            </span>

            <span class="eo-property-tooltip">
              <PropertyTooltipp
                :title="txt('ls_gehaertet')"
                :text="txt('ls_i_gehaertet')"
              />
            </span>
          </li>
          <li v-if="oLens.bExtrahart">
            <img
              class="eo-list-indicator eo-list-haken"
              src="../../assets/haken.png"
              alt=""
            />
            <span :class="{ highlighted: !inclusiveForBrand('bExtrahart') }">
              {{ txt('ls_extrahart') }}
            </span>

            <span class="eo-property-tooltip">
              <PropertyTooltipp
                :title="txt('ls_extrahart')"
                :text="txt('ls_i_extrahart')"
              />
            </span>
          </li>
          <li v-if="oLens.bAntibeschlag">
            <img
              class="eo-list-indicator eo-list-haken"
              src="../../assets/haken.png"
              alt=""
            />
            <span :class="{ highlighted: !inclusiveForBrand('bAntibeschlag') }">
              {{ txt('ls_antibeschlag') }}
            </span>

            <span class="eo-property-tooltip">
              <PropertyTooltipp
                :title="txt('ls_antibeschlag')"
                :text="txt('ls_i_antibeschlag')"
              />
            </span>
          </li>
          <li v-if="oLens.bMobileReadingAdditive">
            <img
              class="eo-list-indicator eo-list-haken"
              src="../../assets/haken.png"
              alt=""
            />
            <span
              :class="{
                highlighted: !inclusiveForBrand('bMobileReadingAdditive'),
              }"
            >
              Mobile-Reading
            </span>
            <span class="eo-property-tooltip">
              <PropertyTooltipp
                title="Mobile-Reading-Additive"
                :text="mobileReadingText"
              />
            </span>
          </li>
          <li v-if="oLens.bPolarisiert == true">
            <img
              class="eo-list-indicator eo-list-haken"
              src="../../assets/haken.png"
              alt=""
            />
            <span :class="{ highlighted: !inclusiveForBrand('bPolarisiert') }">
              {{ txt('ls_polarisiert') }}
            </span>

            <span class="eo-property-tooltip">
              <PropertyTooltipp
                :title="txt('ls_polarisiert')"
                :text="txt('ls_i_polarisiert')"
              />
            </span>
          </li>
          <li v-if="oLens.bBlueControl == true">
            <img
              class="eo-list-indicator eo-list-haken"
              src="../../assets/haken.png"
              alt=""
            />
            <span :class="{ highlighted: !inclusiveForBrand('bBlueControl') }">
              {{ txt('ls_blueControl') }}
            </span>

            <span class="eo-property-tooltip">
              <PropertyTooltipp
                :title="txt('ls_blueControl')"
                :text="txt('ls_i_blueControl')"
              />
            </span>
          </li>
        </ul>
      </div>

      <div v-if="isHoya" class="cell eo-lens-list-row eo-row-warranty">
        <ul class="features">
          <li>
            <img
              class="eo-list-indicator eo-list-haken"
              src="../../assets/haken.png"
              alt=""
            />
            <span>{{ txt('ls_3jahresgarantie') }}</span>
          </li>
        </ul>
      </div>

      <div v-if="sShade !== 'none'" class="cell eo-lens-list-row eo-row-shade">
        <ul class="features">
          <li v-if="sShade === 'complete'">
            <img
              class="eo-list-indicator eo-list-haken"
              src="../../assets/haken.png"
              alt=""
            />
            <span v-if="flashChoices.length > 0 && iColor">
              {{ txt('ls_base_color_inside') }}
            </span>
            <span v-else>
              {{ txt('ls_toenung_komplett') }}
            </span>

            <span class="eo-property-tooltip">
              <PropertyTooltipp
                :title="txt('ls_toenung_komplett')"
                :text="txt('ls_i_kompletttoenung')"
              />
            </span>
          </li>
          <li v-else-if="sShade === 'gradient'">
            <img
              class="eo-list-indicator eo-list-haken"
              src="../../assets/haken.png"
              alt=""
            />
            {{ txt('ls_toenung_verlauf') }}

            <span class="eo-property-tooltip">
              <PropertyTooltipp
                :title="txt('ls_toenung_verlauf')"
                :text="txt('ls_i_toenungverlauf')"
              />
            </span>
          </li>
          <li v-else-if="sShade === 'phototrop'">
            <img
              class="eo-list-indicator eo-list-haken"
              src="../../assets/haken.png"
              alt=""
            />
            {{ txt('ls_toenung_phototrop') }}

            <span class="eo-property-tooltip">
              <PropertyTooltipp
                :title="txt('ls_toenung_phototrop')"
                :text="txt('ls_i_toenungphototrop')"
              />
            </span>
          </li>
          <li v-if="iColor > 1" class="eo-list-item">
            <span
              v-if="!iFlashColor && isRayBan"
              class="eo-list-frame-icon rayban"
            >
              <img
                v-if="isGradient"
                class="eo-list-indicator eo-list-frame-rayban"
                :src="raybanImg('gradient-' + iColor)"
                :alt="iColor"
              />
              <img
                v-else
                class="eo-list-indicator eo-list-frame-rayban"
                :src="raybanImg('complete-' + iColor)"
                :alt="iColor"
              />
            </span>
            <span
              v-else-if="!iFlashColor && isOakley"
              class="eo-list-frame-icon"
            >
              <img
                v-if="isGradient"
                class="eo-list-indicator eo-list-frame-oakley"
                :src="oakleyImg('gradient-' + iColor)"
                :alt="iColor"
              />
              <img
                v-else
                class="eo-list-indicator eo-list-frame-oakley"
                :src="oakleyImg('complete-' + iColor)"
                :alt="iColor"
              />
            </span>
            <span v-else-if="!iFlashColor" class="eo-list-frame-icon">
              <img
                class="eo-list-indicator eo-list-frame eo-list-frame-color"
                :src="iconsImg(sShade + '/' + iColor)"
                alt=""
              />
            </span>
            <span v-else class="eo-list-frame-icon eo-flash" />
            <div class="eo-list-choices">
              {{ shadeChoices.join(', ') }}
            </div>
          </li>
          <li v-if="flashChoices.length > 0">
            <img
              class="eo-list-indicator eo-list-haken"
              src="../../assets/haken.png"
              alt=""
            />
            <span>
              {{ txt('ls_color_outside') }}
            </span>

            <span class="eo-property-tooltip">
              <PropertyTooltipp
                :title="txt('ls_vollverspiegelt')"
                :text="txt('ls_i_vollverspiegelt')"
              />
            </span>
          </li>

          <li
            v-if="flashChoices.length > 0 && iFlashColor > 0"
            class="eo-list-item"
          >
            <span v-if="isRayBan" class="eo-list-frame-icon rayban">
              <img
                class="eo-list-indicator eo-list-frame-rayban"
                :src="raybanImg('mirror-' + iFlashColor)"
                :alt="iColor"
              />
            </span>
            <span v-else-if="isOakley" class="eo-list-frame-icon">
              <img
                class="eo-list-indicator eo-list-frame-oakley"
                :src="oakleyImg('mirror-' + iFlashColor)"
                :alt="iColor"
              />
            </span>
            <span v-else class="eo-list-frame-icon">
              <img
                class="eo-list-indicator eo-list-frame"
                :src="iconsImg(sShade + '-flash/' + iFlashColor)"
                alt=""
              />
            </span>
            <div class="eo-list-choices">
              {{ flashChoices.join(', ') }} {{ txt('ls_mirrored') }}
            </div>
          </li>
        </ul>
      </div>
      <div
        class="cell iprice show-for-small-only eo-lens-list-row m-litem-mobile"
        :class="{ rayban: isRayBan }"
      >
        <div class="cell eo-item-price mobile">
          <div class="eo-item-price-title">Preis</div>
          <div class="eo-item-price-value mobile">
            {{ lensPrice | formatPrice }}
            <div
              v-if="glazingLens"
              class="m-litem-stoerer"
              :class="{ rayban: isRayBan }"
            >
              <div>{{ txt('ls_fitting') }}</div>
              <br />
              <div>{{ txt('ls_fitting_to') }}</div>
            </div>
            <div
              v-else-if="
                (glazingLens || !containsGlazing) &&
                recommended &&
                currentBrand !== 'optimeo'
              "
              class="m-litem-stoerer"
              :class="{
                rayban: isRayBan,
                de: language === 'de' || language === 'en',
              }"
            >
              <div>{{ txt('ls_our') }}</div>
              <br v-if="language === 'de' || language === 'en'" />
              <div v-if="language === 'de' || language === 'en'">
                {{ currentBrand }}
              </div>
              <br />
              <div>{{ txt('ls_recommendation') }}</div>
            </div>
            <div
              v-else-if="
                (glazingLens || !containsGlazing) &&
                recommended &&
                currentBrand === 'optimeo'
              "
              class="m-litem-stoerer"
              :class="{
                rayban: isRayBan,
              }"
            >
              <div class="primary">Best</div>
              <br />
              <div class="primary">Choice</div>
            </div>
            <div
              v-else-if="cheapestLens"
              class="m-litem-stoerer"
              :class="{ rayban: isRayBan }"
            >
              <div>{{ txt('ls_cheapest') }}</div>
              <br />
              <div>{{ txt('ls_price') }}</div>
            </div>
            <div
              v-else-if="bestValue"
              class="m-litem-stoerer"
              :class="{ rayban: isRayBan }"
            >
              <div>{{ txt('ls_top_price') }}</div>
              <br />
              <div>{{ txt('ls_performance') }}</div>
            </div>
            <div
              v-else-if="premiumLens"
              class="m-litem-stoerer"
              :class="{ rayban: isRayBan }"
            >
              <div>{{ txt('ls_premium') }}</div>
              <br />
              <div>{{ txt('ls_lenses') }}</div>
            </div>
          </div>
        </div>
        <div class="cell small-6">
          <!-- eslint-disable -->
          <span v-html="txt('ls_gesamtpreis')" />
          <!-- eslint-enable -->
        </div>
        <div class="cell small-6 m-text-right">
          {{ productPrice | formatPrice }}
        </div>
      </div>
      <div class="cell hide-for-small-only eo-lens-list-row red eo-row-price">
        <div class="eo-item-price">
          <div class="eo-item-price-value">
            <span v-if="slashedPrice" class="slashed-price">
              {{ slashedPrice | formatPrice }}
            </span>
            <span> {{ lensPrice | formatPrice }} </span>
          </div>
          <div class="">
            <div
              v-if="false"
              class="m-litem-stoerer"
              :class="{ other: language !== 'de' }"
            >
              <div class="eo-stoerer-text" :class="{ rayban: isRayBan }">
                {{ txt('ls_fitting') }}
              </div>
              <br />
              <div class="eo-stoerer-text" :class="{ rayban: isRayBan }">
                {{ txt('ls_fitting_to') }}
              </div>
            </div>
            <div
              v-else-if="
                (glazingLens || !containsGlazing) &&
                recommended &&
                currentBrand !== 'optimeo'
              "
              class="m-litem-stoerer"
              :class="{
                rayban: isRayBan,
                other: language !== 'de',
                de: language === 'de' || language === 'en',
              }"
            >
              <div class="eo-stoerer-text" :class="{ rayban: isRayBan }">
                {{ txt('ls_our') }}
              </div>
              <br v-if="language === 'de' || language === 'en'" />
              <div
                v-if="language === 'de' || language === 'en'"
                class="eo-stoerer-text"
                :class="{ rayban: isRayBan }"
              >
                {{ currentBrand }}
              </div>
              <br />
              <div class="eo-stoerer-text" :class="{ rayban: isRayBan }">
                {{ txt('ls_recommendation') }}
              </div>
            </div>
            <div
              v-else-if="
                (glazingLens || !containsGlazing) &&
                recommended &&
                currentBrand === 'optimeo'
              "
              class="m-litem-stoerer"
              :class="{
                rayban: isRayBan,
                other: language !== 'de',
              }"
            >
              <div
                class="eo-stoerer-text primary"
                :class="{ rayban: isRayBan }"
              >
                Best
              </div>
              <br />
              <div
                class="eo-stoerer-text primary"
                :class="{ rayban: isRayBan }"
              >
                Choice
              </div>
            </div>
            <div
              v-else-if="containsGlazing && isRayBan"
              class="m-litem-stoerer"
            >
              <div class="eo-stoerer-text" :class="{ rayban: isRayBan }">
                Original
              </div>
              <br />
              <div class="eo-stoerer-text" :class="{ rayban: isRayBan }">
                Ray-Ban
              </div>
            </div>
            <div
              v-else-if="cheapestLens"
              class="m-litem-stoerer"
              :class="{ other: language !== 'de' }"
            >
              <div class="eo-stoerer-text" :class="{ rayban: isRayBan }">
                {{ txt('ls_cheapest') }}
              </div>
              <br />
              <div class="eo-stoerer-text" :class="{ rayban: isRayBan }">
                {{ txt('ls_price') }}
              </div>
            </div>
            <div
              v-else-if="bestValue"
              class="m-litem-stoerer"
              :class="{ other: language !== 'de' }"
            >
              <div class="eo-stoerer-text" :class="{ rayban: isRayBan }">
                {{ txt('ls_top_price') }}
              </div>
              <br />
              <div class="eo-stoerer-text" :class="{ rayban: isRayBan }">
                {{ txt('ls_performance') }}
              </div>
            </div>
            <div
              v-else-if="premiumLens"
              class="m-litem-stoerer"
              :class="{ other: language !== 'de' }"
            >
              <div class="eo-stoerer-text" :class="{ rayban: isRayBan }">
                {{ txt('ls_premium') }}
              </div>
              <br />
              <div class="eo-stoerer-text" :class="{ rayban: isRayBan }">
                {{ txt('ls_lenses') }}
              </div>
            </div>
          </div>
          <p>
            <!-- eslint-disable -->
            <span v-html="txt('ls_gesamtpreis')" />
            <!-- eslint-enable -->
            {{ productPrice | formatPrice }}
          </p>
        </div>
      </div>
      <div class="cell m-cartwrap eo-row-cart">
        <div class="eo-add-to-cart-block">
          <add-to-cart-button
            :show="inputCompleted && additionCompleted && shadeFilled"
            :product="oLens"
          />
          <i
            v-if="errorCode === oLens.iLens"
            class="fas fa-exclamation-circle eo-cart-error"
          />
        </div>
        <!--span
          class="eo-cart-error-message"
          v-if="error.code === oLens.iLens"
        >
          {{ error.message }}
        </span-->
      </div>
    </div>
    <div
      v-if="(glazingLens || !containsGlazing) && recommended"
      class="eo-recommendation-box"
      :class="{ rayban: isRayBan }"
    />
  </swiper-slide>
</template>

<script>
import { swiperSlide } from 'vue-awesome-swiper';
import AddToCartButton from './AddToCartButton';
import PropertyTooltipp from '../prescription-pass/PropertyTooltipp';
import { mapGetters, mapState } from 'vuex';

import colors from '../../data/colors.json';
import flashColors from '../../data/flashColors.json';
import glazingColors from '../../data/glazing-colors.json';
import smile from '../../assets/smile.svg';
import meh from '../../assets/meh.svg';
import frown from '../../assets/frown.svg';

import translation from '../../mixins/translation';

export default {
  name: 'LensListItem',
  components: {
    AddToCartButton,
    swiperSlide,
    PropertyTooltipp,
  },
  mixins: [translation],
  props: {
    oLens: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      colors,
      flashColors,
    };
  },
  computed: {
    ...mapGetters({
      getSelectedLenses: 'localResult/getSelectedLenses',
      getAllSelectedLenses: 'localResult/getAllSelectedLenses',
      showCenterThickness: 'query/hasPositiveValues',
      showOverhang: 'query/isNotZero',
      inputCompleted: 'query/inputCompleted',
      additionCompleted: 'query/additionCompleted',
      shadeFilled: 'localQuery/shadeFilled',
      language: 'profile/language',
    }),
    ...mapState({
      product: (state) => state.result.oProduct,
      sShade: (state) => state.query.oConditions.sShade,
      currentColor: (state) => state.localResult.currentColor,
      currentPercent: (state) => state.localResult.currentPercent,
      currentFlashColor: (state) => state.localResult.currentFlashColor,
      error: (state) => state.error,
      errorCode: (state) => state.error.code,
      currencySign: (state) => state.result.sign,
    }),
    mobileReadingText() {
      switch (this.language) {
        case 'de':
          return 'Glas mit speziell geschliffenem Bereich im unteren Teil der Linse, das langes Lesen auf Mobilgeräten angenehmer macht.';
        case 'fr':
          return 'Les verres contiennent une zone renforcée qui augmente la puissance des verres et détend ainsi les yeux pour la lecture sur les appareils mobiles.';
        case 'sv':
          return 'Glasen innehåller en liten zon med ökad styrka i nedre delen av glaset som gör läsning på mobila enheter bekvämare.';
        case 'hu':
          return 'Egyedileg kialakított lencsék, a lencse alján egy boost zóna található, amely kényelmesebbé teszi az olvasást mobileszközökön. Ezek nem többfókuszú lencsék.';
        case 'ro':
          return 'Lentile confecționate individual, cu o zonă de boost în partea de jos a lentilei, care face cititul pe dispozitive mobile mai confortabil. Aceștia nu sunt lentile multifocale.';
        case 'nl':
          return 'De glazen bevatten een zone dat het lezen op mobiele apparaten comfortabeler maakt.';
        case 'da':
          return 'Individuelt fremstillede glas med en boost-zone nederst i glasset, som gør det mere behageligt at læse på mobile enheder.';
        case 'pl':
          return 'Na dole soczewki znajduję się dodatkowa strefa powiększająca, która zapewnia większy komfort podczas czytania na urządzeniach mobilnych';
      }
      return 'The lenses contain a small zone of increased strength at the bottom of the lens that makes reading on mobile devices more comfortable.';
    },
    currentBrand() {
      if (this.getSelectedLenses && this.getSelectedLenses.length > 0) {
        return (this.getSelectedLenses[0].sBrand || '')
          .replace('ROX Clear', 'Ray-Ban')
          .replace('ROX Sun', 'Ray-Ban')
          .replace('OTD Clear', 'Oakley')
          .replace('OTD Sun Standard', 'Oakley')
          .replace('OTD Complex High', 'Oakley')
          .replace('OTD Complex', 'Oakley')
          .replace('OTD Shield', 'Oakley');
      }
      return '';
    },
    name() {
      return this.oLens.sName.replace('ROX', 'Ray-Ban');
    },
    lensPrice() {
      return Math.max(0, parseFloat(this.oLens.fPrice) * 2);
    },
    slashedPrice() {
      return Math.max(0, parseFloat(this.oLens.fSlashedPrice) * 2);
    },
    productPrice() {
      const productPrice = this.product.price;
      return parseFloat(productPrice) + this.lensPrice;
    },
    glazingLens() {
      const glazings = glazingColors[this.product.prd_iBrand];
      if (
        this.product.psu_sLensColorName &&
        glazings &&
        glazings[this.product.psu_sLensColorName]
      ) {
        const colors = glazings[this.product.psu_sLensColorName];
        return colors.some((color) => {
          return this.iColor === color.mgc_iColor;
          // return color.mgc_iLens === parseInt(this.oLens.iLens);
        });
      }
      return false;
    },
    containsGlazing() {
      return (
        glazingColors &&
        glazingColors[this.product.prd_iBrand] &&
        glazingColors[this.product.prd_iBrand]._brands.includes(
          this.oLens.iBrand
        ) &&
        glazingColors[this.product.prd_iBrand] &&
        this.sShade !== 'none'
      );
    },
    shadeChoices() {
      let aShadePercent = this.oLens.aShadePercent;
      const aShadeColor = (this.oLens.aShadeColor || [])
        .filter((color) => {
          return !this.iColor || parseInt(color) === this.iColor;
        })
        .map((color) => {
          return this.capitalize(this.txt(this.colors[color]));
        });

      if (aShadeColor.length === 1 && aShadePercent) {
        if (this.currentPercent.percent) {
          aShadePercent = [this.currentPercent.percent];
        }
        return [
          aShadeColor[0] +
            ' (' +
            aShadePercent
              .map((percent) => {
                return this.formatCode(percent);
              })
              .join(', ') +
            ') ' +
            this.shadePercentLabel(parseInt(aShadePercent[0].split('-')[0])),
        ];
      }

      return aShadeColor;
    },
    flashChoices() {
      return (this.oLens.aFlashColor || [])
        .filter((color) => {
          return !this.iFlashColor || parseInt(color) === this.iFlashColor;
        })
        .map((color) => {
          return this.capitalize(this.txt(this.flashColors[color]));
        });
    },

    iColor() {
      if (this.currentColor) {
        return parseInt(this.currentColor.code.substr(1));
      }
      return 0;
    },
    iFlashColor() {
      if (this.currentFlashColor) {
        return parseInt(this.currentFlashColor.code);
      }
      return 0;
    },
    cheapestLens() {
      const values = this.getAllSelectedLenses.map((lens) => {
        return parseFloat(lens.fPrice);
      });
      const minPrice = Math.min(...values);
      return parseFloat(this.oLens.fPrice) <= minPrice;
    },
    bestValue() {
      const prices = this.getAllSelectedLenses
        .map((lens) => {
          return parseFloat(lens.fPrice);
        })
        .sort((a, b) => a - b);
      return this.oLens.fPrice === prices[1] && this.oLens.fPrice < 160 / 2;
    },
    premiumLens() {
      return false;
    },
    recommended() {
      const overhangs = this.getSelectedLenses.map((lens) => {
        return lens.fUeberstand;
      });
      const minOverhang = Math.min(...overhangs);
      const lenses = this.getSelectedLenses.filter((lens) => {
        return lens.fUeberstand === minOverhang;
      });
      return (
        this.oLens ===
        lenses.reduce((result, lens) => {
          if (result.fUeberstand === lens.fUeberstand) {
            if (
              (result.bBlueControl && lens.bBlueControl) ||
              (result.bPolarisiert && lens.bPolarisiert)
            ) {
              return result.fPrice < lens.fPrice ? result : lens;
            } else if (result.bBlueControl || result.bPolarisiert) {
              return result;
            } else if (lens.blueControl || lens.bPolarisiert) {
              return lens;
            }
          }
          if (result.fCenterThickness < lens.fCenterThickness) {
            return result;
          }
          return lens;
        })
      );
    },
    isHoya() {
      return [218].includes(this.oLens.iBrand);
    },
    isRayBan() {
      return [759, 760, 761].includes(this.oLens.iBrand);
    },
    isOakley() {
      return [763, 764, 765, 767, 773].includes(this.oLens.iBrand);
    },
    isGradient() {
      return this.sShade === 'gradient';
    },
    toLabel() {
      switch (this.language) {
        case 'de':
          return 'bis';
        case 'se':
          return 'till';
        case 'da':
          return 'til';
        case 'fr':
          return 'de';
        case 'pl':
          return 'do';
        case 'hu':
          return '-';
        case 'ro':
          return 'până';
      }
      return 'All lens brands';
    },
  },
  methods: {
    shadePercentLabel(percent) {
      if (this.language === 'de') {
        if (this.sShade === 'complete') {
          if (percent >= 0 && percent <= 25) {
            return 'Leichte Filtertönung';
          } else if (percent > 25 && percent < 75) {
            return 'Mittlere Tönung';
          } else {
            return 'Sonnenbrillentönung';
          }
        } else if (this.sShade === 'gradient') {
          if (percent <= 25) {
            return 'Helle Verlaufstönung';
          } else {
            return 'Sonnenbrillenverlauf';
          }
        }
      }
      return '';
    },
    capitalize(value) {
      if (!value) return '';
      value = value.toString();
      return value.charAt(0).toUpperCase() + value.slice(1);
    },
    mround: function (val, dec) {
      if (!val) {
        val = 0;
      }
      if (!dec) {
        dec = 0;
      }
      return Math.round(val * Math.pow(10, dec)) / Math.pow(10, dec);
    },
    mdecimalcomma: function (val) {
      return val.toString().replace('.', ',');
    },
    defineOverflowEmoticon: function (val) {
      if (this.product.pgl_eFrameType === 'rimless') {
        if (val >= 4.7) {
          return frown;
        } else if (val >= 3.7) {
          return meh;
        }
      } else if (this.product.pgl_eFrameMaterial === 'metal') {
        if (val >= 2.7) {
          return frown;
        } else if (val >= 1.7) {
          return meh;
        }
      } else {
        if (val >= 2) {
          return frown;
        } else if (val >= 1) {
          return meh;
        }
      }
      return smile;
    },
    defineCenterEmoticon(val) {
      if (val <= 2.4) {
        return smile;
      } else if (val < 3.5) {
        return meh;
      }
      return frown;
    },
    replaceBr(value) {
      return value.replace('\r', '<br>');
    },
    txt(indicator) {
      if (!this.getTranslations) {
        return '';
      }
      return this.getTranslations[indicator];
    },
    formatCode(code) {
      const [max, min] = code.split('-');
      if (max === min) {
        return max + '%';
      }
      return `${max}% ${this.toLabel} ${min}%`;
    },
    inclusiveForBrand(property) {
      const first = this.getSelectedLenses[0];
      return this.getSelectedLenses.every((lens) => {
        return lens[property] === first[property];
      });
    },
    iconsImg(type) {
      try {
        return require('../../assets/shade-icons/' + type + '.svg');
      } catch (e) {
        return '';
      }
    },
    raybanImg(type) {
      try {
        return require('../../assets/lux-icons/rayban/' + type + '.png');
      } catch (e) {
        return '';
      }
    },
    oakleyImg(type) {
      try {
        return require('../../assets/lux-icons/oakley/' + type + '.png');
      } catch (e) {
        return '';
      }
    },
  },
};
</script>

<style scoped>
.lens {
  border-radius: 10px;
}

.eo-property-tooltip {
  display: inline-block;
  width: 16px;
  height: 16px;
  vertical-align: sub;
  margin-left: 2px;
}

.eo-property-tooltip svg {
  width: 100%;
  height: 100%;
}

ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

.fa-check {
  color: forestgreen;
}

ul.features li {
  @apply flex;
  line-height: 21px;
  white-space: nowrap;
}

.eo-row-refinement ul.features li {
  height: 22px !important;
}

ul.features li i {
  margin-right: 8px;
}

h2 {
  font-size: 14px;
  margin-bottom: 4px;
  margin-top: 4px;
  color: #444;
  letter-spacing: 0.5px;
}

p {
  margin-bottom: 8px;
}

.eo-list-item {
  display: flex;
  align-content: center;
}

.eo-list-indicator {
  user-select: none;
  margin-right: 4px;
}

.eo-list-haken {
  width: 17px;
  height: 13px;
  margin-top: 3px;
}

.eo-list-logo {
  width: 40px;
  margin-left: -2px;
  margin-right: 5px;
}

.eo-list-arrow {
}

.eo-list-text {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.eo-item-price {
  display: flex;
  flex-direction: column;
  position: relative;
  height: 100%;
  padding-left: 5px;
}

.eo-item-price p {
  font-size: 14px;
}

.eo-item-price.mobile {
  display: flex;
  flex-direction: row;
  position: relative;
}

.eo-item-price-title {
  padding-top: 1.25em;
  font-size: 1.1em;
  text-transform: uppercase;
  font-weight: bold;
}

.eo-item-price-value {
  flex-grow: 1;
  position: relative;
  font-size: 1.5em;
  color: #e94e1b;
  padding-bottom: 0;
  white-space: nowrap;
  font-weight: bold;
}

.eo-stoerer-text.rayban {
  background-color: #ff0d00;
  border-color: #ff0d00;
}

.eo-item-price-value.mobile {
  text-align: right;
  padding-top: 1em;
}

.eo-item-price-value .m-litem-stoerer {
  padding-left: 10px;
  font-size: 0.8em;
}

.m-litem-stoerer {
  user-select: none;
}

.m-litem-stoerer.de {
  top: -5.5em !important;
}

.m-litem-stoerer .primary {
  @apply bg-primary;
}

@media (min-width: 880px) {
  .m-litem-stoerer.de {
    top: -3.5em !important;
  }
}

.m-llist-mobittl {
  padding-top: 1em;
  padding-bottom: 0;
  font-size: 1.1em;
  font-weight: bold;
  text-transform: uppercase;
}

.m-litem-mobile .m-llist-mobittl,
.m-litem-mobile {
  padding-top: 1em;
  padding-bottom: 0;
}

.m-litem-mobile .m-litem-stoerer {
  position: absolute;
  top: -3em;
  right: 0.5em;
  display: block;
  transform: rotate(-7deg);
}

.eo-list-choice {
  width: 38px;
  height: 38px;
  margin-left: -5px;
}

.eo-list-choice-span {
  width: 43px;
}

.eo-list-frame-icon {
  position: relative;
  width: 55px;
  height: 35px;
  flex-shrink: 0;
}

.eo-list-frame-icon.rayban {
  height: 50px;
}

.eo-list-frame {
  position: absolute;
  width: 45px;
  height: 35px;
  z-index: 10;
}

.eo-list-frame-rayban {
  position: absolute;
  width: 50px;
  height: 50px;
  z-index: 10;
}

.eo-list-frame-oakley {
  position: absolute;
  width: 50px;
  height: 33px;
  z-index: 10;
}

.eo-list-frame-color {
  position: absolute;
  left: 0;
  top: 0;
  z-index: 0;
}

.eo-list-choices {
  display: flex;
  flex-direction: column;
  justify-content: center;
  white-space: normal;
}

.m-litem-stoerer {
  position: absolute;
  top: -1.5em;
  right: 0;
  display: block;
  transform: rotate(-7deg);
}

.m-litem-stoerer.other {
  right: -1em;
  transform: scale(0.85) rotate(-7deg);
}

.highlighted {
  /*font-weight: 500;*/
}

.eo-add-to-cart-block {
  white-space: nowrap;
}

.eo-cart-error {
  margin-left: 10px;
  font-size: 2em;
}

.eo-cart-error-message {
  position: relative;
  display: block;
  margin-top: 10px;
  color: red;
}

.eo-recommendation-box {
  border: 2px solid #7a7a7a;
  border-radius: 10px;
  position: absolute;
  right: 0;
  left: 0;
  top: 0;
  bottom: 15px;
  pointer-events: none;
}

.eo-recommendation-box.rayban {
  border-color: #7a7a7a;
}

.eo-list-frame-icon.eo-flash {
  width: 21px;
}

.iprice {
  display: flex;
  flex-flow: row wrap;
}

.eo-stoerer-text.red.rayban {
  border-color: #e94e1b;
  background-color: #e94e1b;
}

.eo-cart-button.rayban {
  background-color: #e94e1b;
}

.m-litem-mobile.rayban {
  border-color: #e94e1b;
}

.small-6 {
  width: 50%;
}

.m-litem-bindex {
  display: flex;
}

.m-litem-bindex > * {
  margin-right: 4px;
}

@media print, screen and (max-width: 880px) {
  .m-cartwrap {
    text-align: center;
  }

  .eo-recommendation-box {
    top: 2px;
  }

  .eo-row-material {
    min-height: 86px;
  }

  .eo-row-overhang {
    min-height: 36px;
  }

  .eo-row-refinement {
    min-height: 205px;
  }

  .eo-row-shade {
    min-height: 155px;
  }

  .m-litem-bindex {
    margin-top: 0.4em;
  }

  .eo-recommendation-box {
    right: 1px;
    left: 0;
  }
}

.eo-lens-list-spherical {
  display: inline-flex;
}

.eo-lens-list-spherical > * {
  margin-right: 5px;
}

.eo-lens-list-spherical-tooltipp {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  margin-top: 16px;
}

.slashed-price {
  display: block;
  white-space: nowrap;
  background: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZlcnNpb249IjEuMSI+PGxpbmUgeDE9IjAiIHkxPSIxMiIgeDI9IjUwIiB5Mj0iMyIgc3R5bGU9InN0cm9rZTpyZ2JhKDIzMyw3OCwyNywwLjYpO3N0cm9rZS13aWR0aDoyIi8+PC9zdmc+')
    no-repeat scroll 0 2px transparent;
  @apply text-gray-500 font-normal text-sm;
}
</style>
