<template>
  <div>
    <div class="eo-brands-template">
      <div v-for="n in 7" :key="n" class="eo-brands-template-brand">
        <vue-content-loading :width="65" :height="58">
          <rect x="7" y="1" rx="4" ry="4" width="52" height="28" />
          <rect x="1" y="38" rx="4" ry="4" width="64" height="16" />
        </vue-content-loading>
      </div>
      <div class="eo-brands-template-brand-empty" />
    </div>
    <div class="eo-lens-list-content-template">
      <vue-content-loading
        wdith="auto"
        :height="421"
        class="eo-lens-list-content-template-image"
      >
        <rect x="52.5%" y="7" rx="4" ry="4" width="80" height="10" />
        <rect x="0" y="30" rx="4" ry="4" width="80" height="20" />
        <rect x="0" y="95" rx="4" ry="4" width="140" height="22" />
        <rect x="0" y="128" rx="4" ry="4" width="130" height="22" />
        <rect x="0" y="160" rx="4" ry="4" width="120" height="18" />
        <rect x="0" y="310" rx="4" ry="4" width="60" height="18" />
      </vue-content-loading>
    </div>
  </div>
</template>

<script>
import VueContentLoading from 'vue-content-loading';

import translation from '../../mixins/translation';

export default {
  name: 'LensListTemplate',
  components: {
    VueContentLoading,
  },
  mixins: [translation],
};
</script>

<style scoped>
.eo-lens-list-content-template {
  margin-left: 30px;
  height: 640px;
  padding: 0 0.5rem;
  border-left: 1px solid black;
  border-bottom: 1px solid black;
  border-right: 1px solid black;
  margin-bottom: 40px;
}

.eo-lens-list-content-template-image {
  height: 100%;
}

.eo-brands-template {
  margin-left: 30px;
  display: flex;
  flex-flow: row wrap;
}

.eo-brands-template-brand {
  width: 100%;
  border-bottom: 1px solid #000;
}

.eo-brands-template-brand:first-child {
  border-left: 1px solid #ccc;
}

.eo-brands-template-brand {
  height: 74px;
  width: 86px;
  padding: 8px 8px 6px;
  border-top: 1px solid #ccc;
  border-right: 1px solid #ccc;
}

.eo-brands-template-brand-empty {
  height: 74px;
  flex-grow: 1;
  border-bottom: 1px solid #000;
}

@media screen and (max-width: 880px) {
  .eo-lens-list-content-template {
    border: none;
  }

  .eo-brands-template-brand {
    display: none;
  }
}
</style>
