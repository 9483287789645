<template>
  <div class="eo-lens-option-marker radio">
    <input :name="property" type="radio" :checked="active" />
    <label />
  </div>
</template>

<script>
export default {
  name: 'EoRadio',
  props: {
    property: {
      type: String,
      required: true,
    },
    active: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style scoped>
.eo-lens-option-marker {
  width: auto;
  align-self: center;
  padding: 0 10px;
  cursor: pointer;
}
</style>
