<template>
  <div>
    <div class="eo-lens-option-not-available">
      <div class="eo-lens-option-marker radio">
        <input v-if="facets[property].type === 'radio'" type="radio" disabled />
        <input
          v-else-if="facets[property].type === 'checkbox'"
          type="checkbox"
          disabled
        />
        <label />
      </div>
      <LensOptionContent :option="option" />
    </div>
  </div>
</template>

<script>
import facets from '../../data/facets.json';
import LensOptionContent from './LensOptionContent';

import translation from '../../mixins/translation';

export default {
  name: 'LensOptionNotAvailable',
  components: { LensOptionContent },
  mixins: [translation],
  props: {
    property: {
      type: String,
      required: true,
    },
    option: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      facets,
    };
  },
};
</script>

<style>
.eo-lens-option-marker input {
  cursor: pointer;
}
</style>

<style scoped>
.eo-lens-option-marker {
  width: auto;
  align-self: center;
  padding: 0 10px;
  cursor: pointer;
}

.eo-lens-option-not-available {
  display: flex;
  flex-direction: row;
  opacity: 0.4;
  cursor: default;
}
</style>
