<template>
  <div class="eo-lens-finder-app">
    <loading
      class="eo-cart-content-loading"
      :active.sync="isLoading"
      :can-cancel="true"
      :is-full-page="true"
    />
    <router-view />
    <notifications
      group="finder"
      position="bottom left"
      classes="eo-notification error"
    />
  </div>
</template>

<script>
/* eslint-disable */
import { mapState, mapActions, mapMutations } from 'vuex';
import Loading from 'vue-loading-overlay';
import Cookies from 'js-cookie';

import translation from './mixins/translation';
/* test push */
export default {
  components: { Loading },
  mixins: [translation],
  props: [],
  computed: {
    ...mapState({
      isLoading: (state) => state.profile.bLoading,
    }),
  },
  created() {
    console.log('created');
    const locale = Cookies.get('locale') || 'de_DE';
    this.$store.dispatch(
      'query/initialize',
      this.$router.history.current.query
    );
    let baseUrl = process.env.VUE_APP_LENS_FINDER_API || '';
    console.log('process.env.VUE_APP_LENS_FINDER_API ',baseUrl);

    let language = locale.split('_')[1].toLowerCase();
    console.log('locale', locale);
   // https://www.edel-optics.gb/lens-finder/api/language.php?sLocale=en_GB

    if(locale == 'en_GB' || locale == 'en_US') {
      baseUrl = 'https://www.edel-optics.co.uk/';
    }else {
      /* für dev */
      baseUrl = baseUrl.replace('de.edel-test', language + '.edel-test');
      /* für live */
      baseUrl = baseUrl.replace('www.edel-optics.de', 'www.edel-optics.' + language);
    }
    console.log('baseUrl', baseUrl);

    this.setBaseUrl(baseUrl);
    fetch(baseUrl + '/lens-finder/api/language.php?sLocale=' + locale)
      .then((response) => response.json())
      .then((oTranslation) => {
        this.$store.dispatch('translation/set', oTranslation);
        this.$store.dispatch(
          'query/initialize',
          this.$router.history.current.query
        );
        this.$store.dispatch('db/initialize');
        this.$store.dispatch('profile/initialize');

        document.title = this.txt('ls_optikermeistermn_hd');
      });
  },
  mounted() {
    window.startLoading = this.startLoading;
    window.stopLoading = this.stopLoading;

    window.stopLoading();

    window.onresize = this.updateInnerWidth;
  },
  destroyed() {
    this.$store.dispatch('db/destroy');
  },
  methods: {
    ...mapActions({
      startLoading: 'profile/startLoading',
      stopLoading: 'profile/stopLoading',
      updateInnerWidth: 'window/updateInnerWidth',
    }),
    ...mapMutations({
      setBaseUrl: 'db/setBaseUrl',
    }),
  },
};
</script>

<style scoped>
.eo-lens-finder-app {
  max-width: 1905px;
  margin: auto;
}
</style>

<style>
@import url('https://fonts.googleapis.com/css?family=Montserrat:400,500,700');
@import '../src/assets/css/foundation.css';
@import '../src/assets/css/app.css';
@import '~vue-multiselect/dist/vue-multiselect.min.css';

html lens-system {
  margin: 0;
  padding: 0;
}

body lens-system {
  margin: 0;
  padding: 0;
  font-family: Montserrat, Helvetica, Arial, sans-serif;
  color: #222;
  font-size: 13px;
  overflow-x: hidden;
}

lens-system h1,
lens-system h2,
lens-system h3,
lens-system h4,
lens-system h5,
lens-system h6,
lens-system p,
lens-system ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

html lens-system {
  box-sizing: border-box;
}

lens-system *,
lens-system *:before,
lens-system *:after {
  box-sizing: inherit;
}

#hzImg {
  display: none !important;
}
</style>

<style>
.vld-overlay.is-full-page {
  z-index: 9999 !important;
}

.multiselect {
  min-height: 32px !important;
}

.multiselect__tags {
  border: 1px solid #000 !important;
  border-radius: 0 !important;
  font-size: 11px !important;
  min-height: 32px !important;
  padding: 4px 34px 0 8px !important;
}

.multiselect__input {
  padding: 1px 0 0 5px !important;
  margin-bottom: 1px !important;
  font-size: 14px !important;
  line-height: 20px !important;
  min-height: 16px !important;
}

.multiselect__select {
  width: 34px !important;
  height: 32px !important;
}

.multiselect__single {
  user-select: none;
  margin-bottom: 1px !important;
  padding-top: 1px !important;
  font-size: 14px !important;
  white-space: nowrap;
}

.multiselect__placeholder {
  user-select: none;
  color: #303030 !important;
  padding: 1px 0 0 5px !important;
  margin-bottom: 1px !important;
  font-size: 14px !important;
  line-height: 20px !important;
}

.multiselect--active .multiselect__content-wrapper {
  border-top: none !important;
}

.multiselect--above .multiselect__content-wrapper {
  border-bottom: none !important;
  border-top: 1px solid black !important;
}

.multiselect__content-wrapper {
  z-index: 999 !important;
  border: 1px solid #000 !important;
  border-radius: 0 !important;
}

.multiselect__option--highlight {
  background-color: #e94e1b !important;
}

.multiselect__content {
  z-index: 999 !important;
}

.multiselect__option {
  padding: 8px !important;
  min-height: 28px !important;
  line-height: 12px !important;
  font-size: 13px !important;
}

@media print, screen and (max-width: 380px) {
  .eo-pass-entry .multiselect__tags {
    padding: 4px 20px 0 8px !important;
    width: 97px;
  }
  .eo-pass-entry .multiselect__select {
    display: none;
  }

  .eo-pass-entry .multiselect__content-wrapper {
    width: 100px;
  }
}
@media print, screen and (max-width: 300px) {
  .eo-selection-title {
    padding: 6px 5px !important;
  }
}
</style>

<style>
.eo-notification {
  margin: 0 5px 15px 30px;
  user-select: none;
  font-size: 14px;
  padding: 10px;
  color: #fff;
  background: #44a4fc !important;
  border-left: 5px solid #187fe7;
  display: block;
  box-sizing: border-box;
  text-align: left;
}
.eo-notification.error {
  background: #e54d42 !important;
  border-left-color: #b82e24;
}
</style>
