<template>
  <div
    v-if="showBlock"
    :id="'m-optwrap-' + property"
    class="cell auto m-lop-opgrid"
  >
    <div class="grid-x">
      <div class="cell hide-for-large small-half" />
      <div class="cell auto">
        <!-- HEADER -->
        <div
          :id="'m-optgrp-' + property"
          class="eo-lens-options-block-header"
          :class="{ active: expanded }"
          @click="toggleExpansion(property)"
        >
          <div class="eo-lens-options-block-header-icon">
            <img
              class="eo-lens-options-block-header-icon-img"
              alt=""
              :src="require('../../assets/' + facets[property].icon)"
            />
          </div>

          <div class="eo-lens-options-block-header-bar">
            <div
              v-if="windowWidth < 330 && property === 'refinement'"
              class="eo-lens-options-block-header-title"
            >
              {{ txt('ls_lens_refinement') }}
            </div>
            <div v-else class="eo-lens-options-block-header-title">
              {{ parseJsonTemplate(facets[property].name) }}
            </div>

            <property-tooltipp
              v-if="infotext"
              :title="infotitle"
              :text="infotext"
            />

            <div class="eo-lens-options-block-header-up-down">
              <img
                class="eo-lens-options-block-header-up-down-img"
                alt="v"
                :class="{ active: expanded }"
                src="../../assets/updown.svg"
              />
            </div>
          </div>
        </div>

        <!-- OPTIONEN -->
        <VueSlideToggle
          :class="{ 'eo-expanded-options': property === 'sShade' }"
          :open="expanded"
          tag="div"
          :duration="animationDuration"
        >
          <div
            v-for="(option, key) in expandedOptions"
            :id="'m-opt-' + key"
            :key="'m-opt-' + key"
            class="grid-x grid-padding-x m-optiongrid eo-lens-options-option"
            :class="{ 'm-active': option.active }"
          >
            <div class="cell auto m-option">
              <LensOptionNotAvailable
                v-if="isNotAvailable(option)"
                :property="property"
                :option="option"
              />
              <LensOptionShade
                v-else-if="isShade(option)"
                :property="property"
                :option="option"
              />
              <LensOptionInclusive
                v-else-if="isInclusive(option)"
                :property="property"
                :option="option"
              />
              <LensOptionSelectable
                v-else-if="isSelectable(option)"
                :raw="property"
                :option="option"
              />
            </div>
          </div>
        </VueSlideToggle>

        <VueSlideToggle
          :class="{ 'eo-expanded-options': property === 'sShade' }"
          :open="expandFinished"
          tag="div"
          :duration="animationDuration"
        >
          <div
            v-for="(option, key) in visibleOptions"
            :key="'m-opt-open-' + key"
            class="grid-x grid-padding-x m-optiongrid eo-lens-options-option"
            :class="{ 'm-active': option.active }"
          >
            <div class="cell auto m-option">
              <LensOptionNotAvailable
                v-if="isNotAvailable(option)"
                :property="property"
                :option="option"
              />
              <LensOptionShade
                v-else-if="isShade(option)"
                :property="property"
                :option="option"
              />
              <LensOptionInclusive
                v-else-if="isInclusive(option)"
                :property="property"
                :option="option"
              />
              <LensOptionSelectable
                v-else-if="isSelectable(option)"
                :raw="'open-' + property"
                :option="option"
              />
            </div>
          </div>
        </VueSlideToggle>
      </div>
    </div>
  </div>
</template>

<script>
import { VueSlideToggle } from 'vue-slide-toggle';
import { mapGetters, mapState } from 'vuex';
import facets from '../../data/facets.json';

import translation from '../../mixins/translation';
import PropertyTooltipp from '../prescription-pass/PropertyTooltipp';
import LensOptionInclusive from './LensOptionInclusive';
import LensOptionNotAvailable from './LensOptionNotAvailable';
import LensOptionSelectable from './LensOptionSelectable';
import LensOptionShade from './LensOptionShade';

export default {
  name: 'LensOptionsBlock',
  components: {
    PropertyTooltipp,
    VueSlideToggle,
    LensOptionInclusive,
    LensOptionNotAvailable,
    LensOptionSelectable,
    LensOptionShade,
  },
  mixins: [translation],
  props: {
    property: {
      type: String,
      default: '',
    },
    value: {
      type: String,
      default: '',
    },
    infotitle: {
      type: String,
      default: '',
    },
    infotext: {
      type: String,
      default: '',
    },
    closed: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      facets,
      expanded: this.closed,
      expandFinished: true,
      animationDuration: 500,
      progressive: facets.sLenstype,
    };
  },
  computed: {
    ...mapState({
      oProduct: (state) => state.result.oProduct,
      aLenses: (state) => state.localResult.aLenses,
      flashColors: (state) => state.localResult.flashColors,
      windowWidth: (state) => state.window.innerWidth,
    }),
    ...mapGetters({
      aLensesWithout: 'localResult/getLensesWithout',
      isRxOnly: 'result/isRxOnly',
    }),
    facet() {
      return this.facets[this.property];
    },
    options() {
      return this.facets[this.property].options;
    },
    visibleOptions() {
      if (this.property !== 'sShade') {
        return [];
      }
      return Object.values(this.options).filter((option) => {
        return this.showOptionClosed(option) && !this.isHidden(option);
      });
    },
    expandedOptions() {
      return Object.values(this.options).filter((option) => {
        return !this.isHidden(option);
      });
    },
    showBlock() {
      const types = ['progressive', 'workingplace'];
      return (
        !types.includes(this.progressive.selected) ||
        this.property !== 'iAsphaerisch'
      );
    },
  },
  watch: {
    aLenses() {
      this.$forceUpdate();
    },
  },
  mounted() {
    if (this.property === 'sShade' && !this.closed) {
      setTimeout(this.toggleExpansion, 0);
    } else {
      this.toggleExpansion();
    }
  },
  methods: {
    toggleExpansion: function () {
      if (this.canToggle()) {
        this.expanded = !this.expanded;

        if (!this.expanded) {
          this.expandFinished = false;
          setTimeout(() => {
            this.expandFinished = true;
          }, this.animationDuration / 2);
        }
      }
    },
    canToggle() {
      return true;
    },
    isHidden(option) {
      return (
        option.property === 'bBlueControl' ||
        option.property === 'bPolarisiert' ||
        option.property === 'aFlashColor'
      );
    },
    isInclusive(option) {
      return (
        !option.active &&
        this.property !== 'sShade' &&
        option.value &&
        this.facets[this.property].type !== 'radio' &&
        this.aLenses &&
        this.aLenses.length > 0 &&
        this.aLenses.every(
          (lens) => lens[option.property || this.property] == option.value
        )
      );
    },
    isNotAvailable(option) {
      const property = option.property || this.property;
      const lenses = this.aLensesWithout(property);
      return (
        (this.isRxOnly &&
          property === 'sShade' &&
          (option.value === 'none' ||
            option.value === 'phototrop' ||
            (option.value === 'gradient' && this.oProduct.prd_iBrand === 2))) ||
        (property === 'aFlashColor' && this.isEmptyObject(this.flashColors)) ||
        (!option.active &&
          property !== 'sShade' &&
          property !== 'aFlashColor' &&
          option.value &&
          lenses.every((lens) => lens[property] != option.value))
      );
    },
    isSelectable() {
      return this.property !== 'sShade';
    },
    isShade(option) {
      return this.property === 'sShade' || option.property === 'aFlashColor';
    },
    parseJsonTemplate(value) {
      const regexp = RegExp(/\{\{\s*(\w+)\s*\}\}/, 'g');
      let matches;
      while ((matches = regexp.exec(value)) !== null) {
        value = value.replace(matches[0], this.txt(matches[1]));
      }
      return value;
    },

    isEmptyObject(object) {
      return (
        Object.entries(object).length === 0 && object.constructor === Object
      );
    },
    showOptionClosed(option) {
      return (
        (!this.expanded &&
          this.expandFinished &&
          option.value &&
          (option.active || this.isInclusive(option))) ||
        (!this.expanded && option.property === 'aFlashColor')
      );
    },
  },
};
</script>

<style scoped>
.eo-lens-options-block-header {
  height: 40px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 5px;
  cursor: pointer;
  position: relative;
}

.eo-lens-options-block-header-icon {
  position: absolute;
  width: 33px;
  height: 33px;
  border: 1px solid #000;
  border-radius: 1000px;
  background-color: #fff;
  z-index: 10;
  padding: 6px;
}

.eo-lens-options-block-header-bar {
  background-color: #000;
  height: 24px;
  margin-left: 17px;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.eo-lens-options-block-header-title {
  text-transform: uppercase;
  color: #fff;
  font-weight: bold;
  margin-left: 22px;
  font-size: 1.1em;
  overflow: hidden;
  margin-right: 5px;
}

.eo-lens-options-block-header-icon-img {
  width: 100%;
  height: 100%;
  user-select: none;
}

.eo-lens-options-block-header-up-down {
  margin-left: auto;
  margin-right: 7px;
  display: flex;
  align-items: center;
}

.eo-lens-options-block-header-up-down-img {
  width: 12px;
  height: 12px;

  transform: rotate(0deg);
  transition: all 400ms ease;
  user-select: none;
}

.eo-lens-options-block-header-up-down-img.active {
  transform: rotate(180deg);
}

.eo-lens-options-block-header-bar svg {
  width: 16px;
  height: 16px;
  margin: 0 3px 1px 6px;
  align-self: center;
}

.eo-lens-options-option {
  margin-left: 14px;
}

@media print, screen and (max-width: 880px) {
  .eo-lens-options-option {
    margin-left: -12px;
  }

  .eo-expanded-options {
    margin-top: 15px;
    margin-bottom: 15px;
  }
}

.m-optiongrid {
  margin-bottom: 10px;
}

.m-option {
  margin-right: 0.4075rem;
}

.m-optiongrid.m-active .m-option,
.m-option:hover {
  background: rgba(233, 80, 29, 0.1);
}

.m-option:hover {
  cursor: pointer;
}

.m-desctxt {
  font-size: 1.1em;
}

.m-optgrp {
  cursor: pointer;
}

.m-optgrp .m-lop-updown img {
  width: 12px;
  height: 12px;
  margin-right: 7px;
  transform: rotate(0deg);
  transition: all 400ms ease;
}

@media print, screen and (min-width: 1160px) {
  .m-optgrp .m-lop-updown img {
    width: 9px;
    height: 9px;
  }
}

@media print, screen and (min-width: 1600px) {
  .m-optgrp .m-lop-updown img {
    width: 12px;
    height: 12px;
  }
}

.m-optgrp.m-active .m-lop-updown img {
  transform: rotate(180deg);
}

.m-lf-hddiv.m-lopt-block {
  width: 33px;
  height: 33px;
  padding: 6px;
}

.m-lf-hdimg-blockicon {
  user-select: none;
}

.m-lop-opgrid {
  margin-bottom: 10px;
}
</style>
