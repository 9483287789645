<template>
  <div class="lens-finder">
    <lens-title />
    <product v-if="isMobile" />
    <div class="grid-x grid-padding-x">
      <div class="cell large-8 xxlarge-9">
        <LensTypeSelection v-if="isGleitsicht" />
        <PrescriptionPass v-if="selected('lensType').value !== 'none'" />
        <LensExtraValues v-if="showExtraValues" />
        <AdditionalValues />
        <LensDesign v-if="hasLensDesign" />
        <LensOptionsBlock
          v-if="isSunglasses && isMobile"
          property="sShade"
          :infotitle="txt('ls_divider_toenung')"
          :infotext="txt('ls_i_ttltoenung')"
          :closed="false"
        />
        <FacetBrands />
        <LensList v-if="loaded" />
        <LensListTemplate v-else-if="!loaded" />
      </div>
      <div class="cell large-4 xxlarge-3">
        <Product v-if="!isMobile" />
        <LensOptions />
      </div>
    </div>
  </div>
</template>

<script>
import AdditionalValues from '@/components/progressive-power/AdditionalValues';
import LensExtraValues from '@/components/progressive-power/LensExtraValues';
import { mapActions, mapGetters, mapState } from 'vuex';

import facets from '../data/facets.json';

import translation from '../mixins/translation';
import FacetBrands from './lens-list/FacetBrands';
import LensList from './lens-list/LensList';

import LensListTemplate from './lens-list/LensListTemplate';
import LensOptions from './lens-options/LensOptions';
import LensOptionsBlock from './lens-options/LensOptionsBlock';
import LensTitle from './LensTitle';
import PrescriptionPass from './prescription-pass/PrescriptionPass';
import Product from './product/Product';
import LensDesign from './progressive-power/LensDesign';
import LensTypeSelection from './progressive-power/LensTypeSelection';

export default {
  name: 'Finder',
  components: {
    AdditionalValues,
    LensExtraValues,
    LensDesign,
    LensTypeSelection,
    LensTitle,
    PrescriptionPass,
    LensOptions,
    FacetBrands,
    LensList,
    LensListTemplate,
    LensOptionsBlock,
    Product,
  },
  mixins: [translation],
  data() {
    return {
      loaded: false,
      progressive: facets.sLenstype,
    };
  },
  computed: {
    ...mapState({
      oProduct: (state) => state.result.oProduct,
      aLenses: (state) => state.localResult.aLenses,
      windowWidth: (state) => state.window.innerWidth,
    }),
    ...mapGetters({
      selected: 'facets/selected',
    }),
    isGleitsicht() {
      return (
        process.env.VUE_APP_NODE_SETTINGS === 'gleitsicht' ||
        process.env.VUE_APP_NODE_SETTINGS === 'cashbox'
      );
    },
    isSunglasses() {
      return (
        this.oProduct &&
        this.oProduct.prd_eType === 'eoProductsGlassesSunglasses'
      );
    },
    isMobile() {
      return this.windowWidth < 1600;
    },
    hasLensDesign() {
      const types = ['progressive', 'workingplace'];
      return types.includes(this.selected('lensType').value);
    },
    showExtraValues() {
      return (
        this.hasLensDesign && process.env.VUE_APP_NODE_SETTINGS === 'cashbox'
      );
    },
  },
  watch: {
    aLenses() {
      this.loaded = true;
    },
  },
  mounted() {
    if (this.$route.query.sLenstype) {
      this.switch({
        facet: 'lensType',
        value: this.$route.query.sLenstype,
      });
    }
    if (this.$route.query.iProgressiveType) {
      this.switch({
        facet: 'progressiveType',
        value: parseInt(this.$route.query.iProgressiveType),
      });
    }
  },
  beforeRouteUpdate(to, from, next) {
    this.$store.dispatch('query/setConditions', to.query);
    next();
  },
  methods: {
    ...mapActions('facets', ['switch']),
    updateTemplate() {
      setTimeout(() => this.updateTemplate(), 2000);
      this.loaded = !this.loaded;
    },
  },
};
</script>

<style scoped></style>
