<template>
  <div
    class="eo-cart-button"
    :class="{ 'eo-gray-out': !showTheGray() }"
    @click="addToCart"
  >
    <div class="eo-cart-button-text">
      <!-- eslint-disable -->
      <span
        class="eo-cart-button-text-span"
        v-html="txt('ls_indenwarenkorb')"
      />
      <!-- eslint-enable -->
    </div>
  </div>
</template>

<script>
import VueScrollTo from 'vue-scrollto';
import { mapActions, mapGetters, mapState } from 'vuex';
import translation from '../../mixins/translation';

export default {
  name: 'AddToCartButton',
  mixins: [translation],
  props: {
    product: {
      type: Object,
      required: true,
    },
    show: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {};
  },
  computed: {
    ...mapState({
      sShade: (state) => state.query.oConditions.sShade,
      sLenstype: (state) => state.query.oConditions.sLenstype,
      facets: (state) => state.localQuery.oFacets,
      currentColor: (state) => state.localResult.currentColor,
      currentFlash: (state) => state.localResult.currentFlashColor,
      currentPercent: (state) => state.localResult.currentPercent,
      birthdate: (state) => state.localResult.birthdate,
    }),
    ...mapGetters({
      inputCompleted: 'query/inputCompleted',
      additionCompleted: 'query/additionCompleted',
      shadeFilled: 'localQuery/shadeFilled',
    }),
  },
  methods: {
    ...mapActions({
      setError: 'error/setError',
    }),
    addToCart() {
      let code = 0;
      let title = '';
      let message = '';
      let scrollTo = '';

      if (!this.currentColor) {
        code = this.product.iLens;
        title = this.txt('ls_e_no_color_selected');
        message = this.txt('ls_e_choose_color');

        scrollTo = '#eo-option-shade';
      } else if (!this.currentPercent) {
        code = this.product.iLens;
        title = this.txt('ls_e_no_shade_selected');
        message = this.txt('ls_e_choose_shade_percent');

        scrollTo = '#eo-option-shade';
      }

      if (!this.inputCompleted && this.product.iLens !== 1353) {
        code = this.product.iLens;
        title = this.txt('ls_e_missing_values');
        message = this.txt('ls_e_choose_all_values');

        scrollTo = '#eo-prescription-pass-heading';
      }

      if (this.sLenstype !== 'singlevision') {
        if (!this.additionCompleted) {
          code = this.product.iLens;
          title = this.txt('ls_e_missing_values');
          message = this.txt('ls_e_choose_all_values');

          scrollTo = '#eo-prescription-pass-heading';
        }
      }

      this.product.dBirthdate = this.birthdate;

      if (false && this.sLenstype === 'progressive') {
        if (
          this.facets.iHSA &&
          this.facets.iVorn &&
          this.facets.iFSW &&
          this.facets.fVIR &&
          this.facets.fVIL &&
          this.facets.iON &&
          this.facets.iESH &&
          this.facets.iProgression
        ) {
          this.product.iHSA = this.facets.iHSA;
          this.product.iVorn = this.facets.iVorn;
          this.product.iFSW = this.facets.iFSW;
          this.product.fVIR = this.facets.fVIR;
          this.product.fVIL = this.facets.fVIL;
          this.product.iON = this.facets.iON;
          this.product.iESH = this.facets.iESH;
          this.product.iProgression = this.facets.iProgression;
        } else {
          code = this.product.iLens;
          title = 'Gleitsichtwerte fehlen';
          message = 'Bitte geben sie die nötigen Gleitsichtwerte ein';
        }
      }

      if (code) {
        this.$notify({
          group: 'finder',
          title: title,
          text: message,
        });
        VueScrollTo.scrollTo(scrollTo);
      }

      this.setError({ code, message });

      if (
        (this.inputCompleted && this.additionCompleted && this.shadeFilled) ||
        this.product.iLens === 1353
      ) {
        switch (this.sShade) {
          case 'complete':
            if (this.facets.aFlashColor) {
              this.product.vollverspiegeltfarbe = this.facets.aFlashColor;
              this.product.toenungkomplettfarbe = this.facets.aShadeColor;
            } else {
              this.product.toenungkomplettfarbe = this.facets.aShadeColor;
            }
            this.product.toenungkomplettprozent = parseInt(
              this.facets.aShadePercent.split('-')[0]
            );
            this.product.toenungkontrastprozent = parseInt(
              this.facets.aShadePercent.split('-')[0]
            );
            break;
          case 'gradient':
            if (this.facets.aFlashColor) {
              this.product.vollverspiegeltfarbe = this.facets.aFlashColor;
              this.product.toenungkomplettfarbe = this.facets.aShadeColor;
            } else {
              this.product.toenungkomplettfarbe = this.facets.aShadeColor;
            }
            this.product.toenungverlaufprozent = this.facets.aShadePercent;
            this.product.toenungkontrastprozent = this.facets.aShadePercent;
            break;
          case 'phototrop':
            this.product.toenungphototropfarbe = this.facets.aShadeColor;
            break;
        }

        this.product.toenung = this.mapShade();

        if (this.$route.query.sGlazingReference) {
          this.product.sGlazingReference = this.$route.query.sGlazingReference;
        }

        window.addLensToCart(this.product);
      }
    },
    showTheGray() {
      if (this.product.iLens === 1353) {
        return !this.show;
      }
      return this.show;
    },
    mapShade() {
      switch (this.sShade) {
        case 'none':
          return 'toenungohne';
        case 'complete':
          return 'toenungkomplett';
        case 'gradient':
          return 'toenungverlauf';
        case 'phototrop':
          return 'toenungphototrop';
      }
      return '';
    },
  },
};
</script>

<style scoped>
.eo-cart-button.eo-gray-out {
  background-color: #787878;
  opacity: 0.4;
}
.eo-cart-button.eo-gray-out:hover {
  background-color: #787878;
  opacity: 0.4;
}

.eo-cart-button {
  display: inline-block;
  position: absolute;
  bottom: 0;
  left: 8px;
  background-color: #e9501d;
  color: #fff;
  font-weight: bold;
  font-size: 13px;
  background-image: url(https://img.edel-optics.de/frontend/icons/cart.svg);
  background-repeat: no-repeat;
  background-position: 12px center;
  padding: 8px;
  cursor: pointer;
  min-width: 160px;
  background-size: 32px;
}

.eo-cart-button {
  position: relative;
  bottom: initial;
  top: 0.4rem;
  left: 0;
  cursor: pointer;
  user-select: none;
  min-height: 51px;
}

.eo-cart-button-text {
  display: inline-block;
  margin-left: 56px;
}

.eo-cart-button-text-span {
  padding-right: 10px;
  height: 60px;
  vertical-align: middle;
  line-height: normal;
  white-space: nowrap;
}

.eo-cart-button:hover {
  background-color: #e93a00;
}
</style>
