import glazingColors from '../../data/glazing-colors.json';
import facets from '../../data/facets.json';
import colors from '../../data/colors.json';
import flashs from '../../data/flashColors.json';

export default {
  namespaced: true,

  state: {
    oFacets: {},
    changed: false,
    sShade: '',
    bUnknownPupilDistance: false,
  },
  getters: {
    shadeFilled: (state) => {
      state.changed;
      return (
        state.sShade === 'none' ||
        (state.sShade === 'phototrop' && !isNaN(state.oFacets.aShadeColor)) ||
        (!isNaN(state.oFacets.aShadeColor) &&
          state.oFacets.aShadePercent !== undefined)
      );
    },
  },
  mutations: {
    setShade(state, payload) {
      state.sShade = payload.mValue;
    },
    setCondition(state, payload) {
      let property = payload.property || payload.sKey;
      if (payload.option && payload.option.property) {
        property = payload.option.property;
      }
      state.oFacets[property] = payload.value;
      if (payload.value === undefined) {
        delete state.oFacets[property];
      }
      state.changed = !state.changed;
    },
    setPupilDistance(state, payload) {
      state.bUnknownPupilDistance = payload;
    },
  },
  actions: {
    setShade({ commit }, payload) {
      commit('setShade', payload);
    },
    setCondition({ commit }, payload) {
      commit('setCondition', payload);
      this.dispatch('localResult/update');
    },
    setPupilDistance({ commit }, payload) {
      commit('setPupilDistance', payload);
    },
    initializeGlazing({ dispatch }) {
      const oProduct = this.state.result.oProduct;
      const iBrand = oProduct.prd_iBrand;
      const sLensColorName = oProduct.psu_sLensColorName;

      if (glazingColors[iBrand]) {
        const glazings = glazingColors[iBrand][sLensColorName];

        if (glazings) {
          if (
            glazings.every((glazingColor) => {
              return glazingColor.mgc_bPolarized;
            })
          ) {
            facets.refinement.options.polarized.active = true;
            dispatch('setCondition', {
              property: 'bPolarisiert',
              value: true,
            });
          }

          const color = glazings[0].mgc_iColor;
          if (
            glazings.every((glazingColor) => {
              return color && glazingColor.mgc_iColor === color;
            })
          ) {
            dispatch('setCondition', {
              property: 'aShadeColor',
              value: color,
            });
            this.dispatch('localResult/setCurrentColor', {
              code: 'c' + color,
              type: 'shade',
              name: colors[color],
            });
          }

          const flash = glazings[0].mgc_iFlashColor;
          if (
            glazings.every((glazingColor) => {
              return flash && glazingColor.mgc_iFlashColor === flash;
            })
          ) {
            facets.refinement.options.flash.active = true;
            dispatch('setCondition', {
              property: 'aFlashColor',
              value: flash,
            });
            this.dispatch('localResult/setCurrentFlashColor', {
              code: flash,
              color: flash,
              type: 'flash',
              name: flashs[flash],
            });
          }
        }
      } else {
        if (oProduct.pco_aLensColor.length > 0) {
          const colorMap = {
            brown: 3,
            gray: 4,
            green: 5,
            blue: 6,
            yellow: 7,
            pink: 8,
          };
          const flashMap = {
            gold: 1,
            silver: 2,
            blue: 3,
            red: 4,
            green: 5,
            orange: 8,
            violet: 10,
            black: 17,
          };

          if (oProduct.psu_bFlashMirror) {
            const flash = flashMap[oProduct.pco_aLensColor[0]];
            if (flash) {
              facets.refinement.options.flash.active = true;
              dispatch('setCondition', {
                property: 'aFlashColor',
                value: flash,
              });
              this.dispatch('localResult/setCurrentFlashColor', {
                code: flash,
                color: flash,
                type: 'flash',
                name: flashs[flash],
              });
            }
          } else {
            const color = colorMap[oProduct.pco_aLensColor[0]];
            if (color) {
              dispatch('setCondition', {
                property: 'aShadeColor',
                value: color,
              });
              this.dispatch('localResult/setCurrentColor', {
                code: 'c' + color,
                type: 'shade',
                name: colors[color],
              });
            }
          }
        }
      }
    },
    preselectSingleShade() {
      //
    },

    resetLocalQuery({ state, dispatch }) {
      Object.keys(state.oFacets).forEach((key) => {
        dispatch('setCondition', {
          property: key,
          value: undefined,
        });
      });
      Object.values(facets.refinement.options).forEach((option) => {
        option.active = false;
      });
      this.dispatch('localResult/setCurrentFlashColor', '');
      this.dispatch('localResult/setCurrentColor', '');
      this.dispatch('localResult/setCurrentPercent', '');
    },
  },
};
