<template>
  <div class="mb-6">
    <LensBlockHeadline
      blockicon="plusminus"
      :property="txt('ls_additional_title')"
    />

    <div class="ml-10 sm:ml-20 space-x-3">
      <span v-if="hasLensDesign" class="font-bold block md:inline mb-3">
        {{ txt('ls_additional_birthdate') }}
      </span>
      <span v-else class="font-bold block md:inline mb-3">
        {{ txt('ls_birthday_request_label') }}
      </span>
      <input v-model="birthdate" type="date" />
    </div>
  </div>
</template>

<script>
import LensBlockHeadline from '@/components/LensBlockHeadline';
import translation from '@/mixins/translation';

import { mapGetters } from 'vuex';

export default {
  name: 'AdditionalValues',
  components: { LensBlockHeadline },
  mixins: [translation],
  computed: {
    ...mapGetters({
      selected: 'facets/selected',
      language: 'profile/language',
    }),
    hasLensDesign() {
      const types = ['progressive', 'workingplace'];
      return types.includes(this.selected('lensType').value);
    },
    birthdate: {
      get() {
        return this.$store.state.localResult.birthdate;
      },
      set(value) {
        this.$store.dispatch('localResult/updateBirthdate', value);
      },
    },
  },
  methods: {
    txt(indicator) {
      if (!this.getTranslations) {
        return '';
      }
      return this.getTranslations[indicator];
    },
  },
};
</script>

<style scoped></style>
