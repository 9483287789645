<template>
  <section class="eo-product">
    <lens-block-headline
      v-if="windowWidth < 330"
      property="Auswahl"
      blockicon="glasses"
    />
    <lens-block-headline
      v-else
      :property="txt('ls_selected_glasses')"
      blockicon="glasses"
    />
    <div v-if="oProduct" class="eo-product-content">
      <div class="eo-product-img">
        <img :src="imgSrc" alt="" />
      </div>
      <div class="eo-product-body">
        <div class="eo-product-title">
          {{ oProduct.prd_sModel }} ({{ oProduct.prd_sStyle }})
          <br />
          {{ oProduct.pgl_iLensSize }} mm / {{ oProduct.pgl_iBridgeSize }} mm
        </div>
        <a class="eo-product-back" :href="productUrl">
          {{ txt('ls_back_to_product') }}
        </a>
      </div>
    </div>
  </section>
</template>

<script>
import LensBlockHeadline from '../LensBlockHeadline';
import { mapState } from 'vuex';

import translation from '../../mixins/translation';

export default {
  name: 'ProductVue',
  components: { LensBlockHeadline },
  mixins: [translation],
  data() {
    return {
      imgSize: 340,
    };
  },
  computed: {
    ...mapState({
      oProduct: (state) => state.result.oProduct,
      productUrl: (state) => state.result.sProductUrl,
      windowWidth: (state) => state.window.innerWidth,
    }),
    imgSrc() {
      if (this.oProduct !== null) {
        const imgUrl = 'https://img.edel-optics.de/';
        return (
          imgUrl +
          this.oProduct.prd_eType +
          '/' +
          this.imgSize +
          '/nobg/' +
          this.oProduct.prd_aImages[0]
        );
      }
      return '';
    },
  },
  methods: {},
};
</script>

<style scoped>
.eo-product {
  margin-bottom: 17px;
  min-height: 331px;
}

@media print, screen and (max-width: 420px) {
  .eo-product {
    min-height: auto;
  }
}

.eo-product-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.eo-product-img {
  user-select: none;
  max-width: 340px;
  margin: auto;
}
.eo-product-img img {
  width: 100%;
}
.eo-product-title {
  font-size: 14pt;
  margin-top: -12px;
}
.eo-product-back {
  text-decoration: none;
  padding: 6px 10px;
  border: 1px solid #e94e1b;
  color: #e94e1b;
  cursor: pointer;
  font-weight: bold;
  text-transform: uppercase;
  display: inline-block;
  margin-top: 10px;
}
.eo-product-back:hover {
  color: #fff;
  background-color: #e94e1b;
}
.eo-product-back:active {
  color: #fff;
  background-color: #e94e1b;
}
.eo-product-body {
  max-width: 300px;
  margin: auto;
}
</style>
