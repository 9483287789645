<template>
  <section class="fbrands">
    <div class="show-for-small-only">
      <lens-block-headline
        :property="txt('ls_ttlmarke')"
        blockicon="glasmarke"
      />
    </div>

    <div class="show-for-medium">
      <lens-block-headline
        :property="txt('ls_ttlauswahlunten_glaeser')"
        blockicon="glaeser"
      />
    </div>
  </section>
</template>

<script>
import { mapGetters, mapState } from 'vuex';

import translation from '../../mixins/translation';
import LensBlockHeadline from '../LensBlockHeadline';

export default {
  name: 'FacetBrands',
  components: {
    LensBlockHeadline,
  },
  mixins: [translation],
  props: {},
  computed: {
    ...mapState({}),
    ...mapGetters({}),
  },
  watch: {},
  mounted() {},
  methods: {},
};
</script>

<style scoped>
section.fbrands {
  margin-bottom: 0;
  margin-top: 8px;
}
</style>
