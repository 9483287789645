<template>
  <div class="lens-title">
    <h1>
      <span v-if="oProduct">
        {{ txt('ls_h1title') }}
        {{ oProduct.prd_sModel }}
        <span v-if="oProduct.prd_sStyle"> ({{ oProduct.prd_sStyle }}) </span>
      </span>
    </h1>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex';

import translation from '../mixins/translation';

export default {
  name: 'LensTitle',
  components: {},
  mixins: [translation],
  props: {},
  computed: {
    ...mapState({
      oProduct: (state) => state.result.oProduct,
    }),
    ...mapGetters({}),
  },
  methods: {},
};
</script>

<style scoped>
.lens-title {
  padding-left: 12px;
  display: flex;
  min-height: 27px;
  margin-top: 20px;
}

.lens-title h1 {
  font-size: 22px;
  font-weight: bold;
}
</style>
