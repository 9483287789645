import Vue from 'vue';
import Vuex from 'vuex';

import oDb from './modules/db.js';
import oError from './modules/error';
import oFacets from './modules/facets.js';
import oLocalQuery from './modules/local-query';
import oLocalResult from './modules/local-result';
import oProfile from './modules/profile.js';
import oQuery from './modules/query.js';
import oResult from './modules/result.js';
import oTranslation from './modules/translation.js';
import oWindow from './modules/window';

Vue.use(Vuex);

const bDebug = process.env.NODE_ENV !== 'production';

export default new Vuex.Store({
  modules: {
    db: oDb,
    query: oQuery,
    result: oResult,
    profile: oProfile,
    facets: oFacets,
    translation: oTranslation,
    localQuery: oLocalQuery,
    localResult: oLocalResult,
    error: oError,
    window: oWindow,
  },
  strict: bDebug,
});
