<template>
  <div
    class="lens-type-selection-option"
    :class="{ active: option.active }"
    @click="switchFacet"
  >
    <eo-radio property="lensType" :active="option.active" />
    <div class="lens-type-selection-option-content">
      <div class="lens-type-selection-option-title">
        {{ parseJsonTemplate(option.name) }}
      </div>
      <div class="lens-type-selection-option-subtitle">
        {{ parseJsonTemplate(option.subtitle) }}
      </div>
    </div>
    <div class="lens-type-selection-option-tooltipp">
      <PropertyTooltipp
        :title="parseJsonTemplate(option.name)"
        :text="parseJsonTemplate(option.description)"
      />
    </div>
  </div>
</template>

<script>
import translation from '@/mixins/translation';
import { mapActions } from 'vuex';
import PropertyTooltipp from '../prescription-pass/PropertyTooltipp';
import EoRadio from '../util/EoRadio';

export default {
  name: 'LensTypeSelectionOption',
  components: { EoRadio, PropertyTooltipp },
  mixins: [translation],
  props: {
    option: {
      type: Object,
      required: true,
    },
  },
  computed: {},
  methods: {
    ...mapActions('facets', ['switch']),
    switchFacet() {
      const iProgressiveType = this.option.value === 'workingplace' ? 2 : 1;
      this.switch({ facet: 'lensType', option: this.option });
      this.switch({ facet: 'progressiveType', value: iProgressiveType });
      try {
        this.$router.replace({
          query: {
            ...this.$route.query,
            sLenstype: this.option.value,
            iProgressiveType,
          },
        });
      } catch (e) {}
    },
    parseJsonTemplate(value) {
      const regexp = RegExp(/\{\{\s*(\w+)\s*\}\}/, 'g');
      let matches;
      while ((matches = regexp.exec(value)) !== null) {
        value = value.replace(matches[0], this.txt(matches[1]));
      }
      return value;
    },
    txt(indicator) {
      if (!this.getTranslations) {
        return '';
      }
      return this.getTranslations[indicator];
    },
  },
};
</script>

<style scoped>
.lens-type-selection-option {
  display: flex;
  padding: 14px 10px;
  cursor: pointer;
}

.lens-type-selection-option.active {
  background: rgba(233, 80, 29, 0.1);
}

.lens-type-selection-option-content {
  margin-left: 5px;
  flex-grow: 1;
}

.lens-type-selection-option-title {
  font-size: 1.2em;
  font-weight: bold;
  margin-bottom: 2px;
}

.lens-type-selection-option-subtitle {
}

.lens-type-selection-option-tooltipp {
  margin-right: 5px;
  display: flex;
  align-items: center;
}
</style>
