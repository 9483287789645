import Cookies from 'js-cookie';

export default {
  namespaced: true,
  state: {
    baseUrl: process.env.VUE_APP_LENS_FINDER_API,
  },
  getters: {},
  mutations: {
    setBaseUrl(state, payload) {
      state.baseUrl = payload;
    },
  },
  actions: {
    initialize({ dispatch }) {
      dispatch('executeQuery').then(() => {
        this.dispatch('query/initializeShade');
        this.dispatch('localQuery/initializeGlazing');
        this.dispatch('localQuery/preselectSingleShade');
      });
    },
    loadData({ dispatch }) {
      dispatch('executeQuery');
    },
    executeQuery() {
      const oQuery = this.getters['query/get'];
      const iStartTime = new Date().getTime();

      const aWhere = [];
      for (const sKey in oQuery.oConditions) {
        if (oQuery.oConditions.hasOwnProperty(sKey)) {
          if (sKey === 'iProgressiveType') {
            if (
              oQuery.oConditions.sLenstype !== 'progressive' &&
              oQuery.oConditions.sLenstype !== 'workingplace'
            ) {
              continue;
            }
          }

          let mValue = oQuery.oConditions[sKey];
          if (mValue != null && mValue !== 'null') {
            if (Array.isArray(mValue)) {
              mValue = mValue[0];
            }
            aWhere.push(sKey + '=' + encodeURIComponent(mValue));
          }
        }
      }
      aWhere.push('iRandom=' + new Date().getTime());

      if (Cookies.get('channel')) {
        aWhere.push('sChannel=' + Cookies.get('channel'));
      }

      let apiUrl = process.env.VUE_APP_LENS_FINDER_API || '';

      apiUrl = this.state.db.baseUrl;
      const params = aWhere.join('&');
      const sRequest = apiUrl + '/lens-finder/api/index.php?' + params;

      return new Promise((resolve) => {
        fetch(sRequest)
          .then((response) => response.json())
          .then((oResult) => {
            const executionTime = new Date().getTime() - iStartTime;
            console.log('query executed in', executionTime + 'ms');
            this.dispatch('result/setResult', oResult);
          })
          .then(resolve);
      });
    },
  },
};
