import { mapGetters } from 'vuex';

export default {
  computed: {
    ...mapGetters({
      getTranslations: 'translation/getTranslation',
    }),
  },
  methods: {
    txt(indicator) {
      if (this.getTranslations) {
        return this.getTranslations[indicator];
      }
      return '';
    },
  },
};
