import Cookies from 'js-cookie';

export default {
  namespaced: true,
  state: {
    bChanged: false,
    sLocale: '',
    oCustomProperties: {},
    bLoading: false,
  },
  getters: {
    language(store) {
      return store.sLocale.split('_')[0];
    },
  },
  mutations: {
    reset(state) {
      state.bChanged = true;
    },

    initializeState(state, oStoredState) {
      for (const sKey in oStoredState) {
        if (oStoredState.hasOwnProperty(sKey)) {
          state[sKey] = oStoredState[sKey];
        }
      }
      state.bChanged = false;
    },

    startLoading(state) {
      state.bLoading = true;
    },
    stopLoading(state) {
      state.bLoading = false;
    },

    setLocale(state, locale) {
      state.sLocale = locale;
    },
  },
  actions: {
    initialize({ commit }) {
      const locale = Cookies.get('locale') || 'de_DE';
      commit('setLocale', locale);

      try {
        if (localStorage['lens-finder-profile']) {
          commit(
            'initializeState',
            JSON.parse(localStorage['lens-finder-profile'])
          );
        }
        const oStore = this;
        window.setInterval(function () {
          oStore.dispatch('profile/storeProfile');
        }, 5000);
      } catch (e) {
        console.log(e);
      }
    },

    setChanged({ commit }, bChanged) {
      commit('setChanged', bChanged);
    },

    setLocale({ commit }, sLocale) {
      commit('setLocale', sLocale);
    },

    setCustomProperty({ commit }, oProperty) {
      commit('setCustomProperty', oProperty);
    },

    storeProfile({ commit }) {
      try {
        if (this.state.profile.bChanged) {
          const sJson = JSON.stringify(this.state.profile);
          localStorage['lens-finder-profile'] = sJson;
          const kilobytes = Math.round(sJson.length / 1024);
          console.log('changed profile stored -', kilobytes, 'kb');
          commit('setChanged', false);
        }
      } catch (e) {
        console.log(e);
      }
    },

    resetProfile({ commit }) {
      commit('reset');
    },

    deleteProfile() {
      try {
        delete localStorage['lens-finder-profile'];
        document.location.href = '/';
      } catch (e) {
        console.log(e);
      }
    },

    startLoading({ commit }) {
      commit('startLoading');
    },
    stopLoading({ commit }) {
      commit('stopLoading');
    },
  },
};
