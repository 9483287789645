<template>
  <div class="eo-option-section">
    <section class="lens-options">
      <div class="grid-x m-lf-hdpadding" @click="toggleSmallExpansion()">
        <div class="cell auto m-lf-hdtxt-wrap">
          <div class="m-lf-hdtxt m-opt-header">
            {{ txt('ls_weitereoptionen') }}
          </div>
        </div>
        <div
          id="m-lf-opthdr"
          :class="{ 'm-active': expanded }"
          class="cell auto shrink m-lf-hdtxt m-small hide-for-large"
        >
          <div class="m-lop-updown">
            <img src="../../assets/updown.svg" alt="v" />
          </div>
        </div>
      </div>

      <VueSlideToggle :open="expanded" tag="div" :duration="expansionSpeed">
        <div class="grid-x m-lopt-grid-x-margin">
          <lens-options-block
            v-if="!isSunglasses || !isMobile"
            property="sShade"
            :infotext="txt('ls_i_ttltoenung')"
            :infotitle="txt('ls_divider_toenung')"
          />
        </div>
        <div class="grid-x m-lopt-grid-x-margin">
          <lens-options-block
            property="fBrechungsIndex"
            :infotitle="txt('ls_glasdicke')"
            :infotext="txt('ls_i_ttldicke')"
            :closed="true"
          />
        </div>
        <div class="grid-x m-lopt-grid-x-margin">
          <lens-options-block
            property="refinement"
            :infotitle="txt('ls_divider_glasveredelungen')"
            :infotext="txt('ls_i_glasveredelungen')"
            :closed="true"
          />
        </div>
        <div class="grid-x m-lopt-grid-x-margin">
          <lens-options-block
            property="iAsphaerisch"
            :infotitle="txt('ls_glasdesign')"
            :infotext="txt('ls_i_ttlglasart')"
            :closed="true"
          />
        </div>
        <div class="grid-x m-lopt-grid-x-margin">
          <lens-options-block
            property="iMaterial"
            :infotitle="txt('ls_ttlmaterial')"
            :infotext="txt('ls_i_ttlmaterial')"
            :closed="true"
          />
        </div>
      </VueSlideToggle>
    </section>
  </div>
</template>

<script>
import { VueSlideToggle } from 'vue-slide-toggle';
import { mapActions, mapGetters, mapState } from 'vuex';

import translation from '../../mixins/translation';
import LensOptionsBlock from './LensOptionsBlock';

export default {
  name: 'LensOptions',
  components: {
    LensOptionsBlock,
    VueSlideToggle,
  },
  mixins: [translation],
  props: {},
  data() {
    return {
      expanded: true,
      closed: false,
      expansionSpeed: 500,
    };
  },
  computed: {
    ...mapState({
      oProduct: (state) => state.result.oProduct,
      windowWidth: (state) => state.window.innerWidth,
      errorOccurred: (state) => state.error.count,
      errorMessage: (state) => state.error.message,
    }),
    ...mapGetters({}),
    isSunglasses() {
      return (
        this.oProduct &&
        this.oProduct.prd_eType === 'eoProductsGlassesSunglasses'
      );
    },
    isMobile() {
      return this.windowWidth < 1600;
    },
  },
  watch: {
    windowWidth(value, old) {
      if (old >= 1600 && value < 1600) {
        this.expanded = false;
      }
      if (this.windowWidth > 1600) {
        this.expanded = true;
      }
    },
    errorOccurred() {
      if (
        this.errorMessage === 'Bitte wähle eine Farbe aus.' ||
        this.errorMessage === 'Bitte wähle einen Tönungsgrad aus.'
      ) {
        this.expanded = true;
      }
    },
  },
  mounted() {
    if (this.isMobile) {
      this.expanded = false;
    }
  },
  methods: {
    ...mapActions({
      resetValues: 'query/resetValues',
    }),
    toggleSmallExpansion() {
      if (this.windowWidth <= 1600) {
        this.expanded = !this.expanded;
      }
    },
  },
};
</script>

<style scoped>
#m-lf-opthdr {
  display: grid;
}

.m-opt-header {
  padding-left: 1rem;
}

@media print, screen and (min-width: 1600px) {
  .lens-options {
    margin: 16px 1rem 0 1rem;
    border: #000 1px solid;
    min-height: 400px;
  }

  .m-lopt-grid-x-margin {
    margin-left: 1rem;
    margin-right: 1rem;
  }
}

@media print, screen and (max-width: 1600px) {
  .m-lf-hdtxt-wrap {
    cursor: pointer;
  }

  .m-lop-updown {
    background: rgb(0, 0, 0);
    font-size: 1.090909em;
    cursor: pointer;
    display: flex;
    justify-content: center;
    flex-direction: column;
  }

  .m-lop-updown img {
    width: 12px;
    height: 12px;
    margin-right: 7px;
    transform: rotate(0deg);
    transition: all 400ms ease;
  }

  .m-active .m-lop-updown img {
    transform: rotate(180deg);
  }
}
</style>
<style>
.eo-option-section {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.radio input {
  position: absolute;
  opacity: 0;
}

.radio input + label:before {
  content: '';
  background: #f4f4f4;
  border-radius: 100%;
  border: 1px solid #b4b4b4;
  display: inline-block;
  width: 18px;
  height: 18px;
  cursor: pointer;
  text-align: center;
  transition: all 250ms ease;
}

.radio input[type='checkbox'] + label:before {
  border-radius: 2px;
}

.radio input:checked + label:before {
  background-color: #e94e1b;
  box-shadow: inset 0 0 0 3px #f4f4f4;
}

.radio input:focus + label:before {
  outline: none;
  border-color: #e94e1b;
}

.radio input:disabled + label:before {
  box-shadow: inset 0 0 0 3px #f4f4f4;
  /*border-color: #e94e1b;*/
  border-color: #b4b4b4;
  background: none;
}

.eo-lens-option-inclusive .radio input + label:before {
  border-color: #b4b4b4;
  background: #e94e1b;
}
</style>
