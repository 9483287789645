var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"grid-x eo-lens-list-brands"},[(_vm.currentBrand)?_c('div',{staticClass:"mb-3 mx-5 md:ml-2"},[_c('h4',{staticClass:"text-lg font-bold"},[_vm._v(" "+_vm._s(_vm.getBrandName(_vm.currentBrand))+" ")]),_c('span',{domProps:{"innerHTML":_vm._s(_vm.txt('ls_i_marke' + _vm.currentBrand.iId))}})]):_vm._e(),_c('div',{staticClass:"cell small-12"},[_c('ul',{staticClass:"facet-brands"},[_vm._l((_vm.notEmptyBrands),function(oBrand){return _c('li',{key:oBrand.iId,staticClass:"fbrand",class:{
          selected: oBrand.iId === _vm.iSelectedBrand,
          'eo-brand-more': _vm.more,
        },on:{"click":function($event){return _vm.brandClick(oBrand)}}},[_c('img',{staticClass:"brand-logo hide-for-small-only",class:{ rayban: _vm.isRayBan(oBrand) },attrs:{"src":_vm.getBrandSvg(oBrand),"alt":""}}),_c('div',{staticClass:"grid-x pricewrap"},[_c('div',{staticClass:"show-for-small-only eo-brand-radio"},[_c('div',{staticClass:"radio"},[_c('input',{staticClass:"show-for-small-only radio",attrs:{"id":oBrand.iId,"type":"radio","name":"brand"},domProps:{"checked":oBrand.iId === _vm.iSelectedBrand}}),_c('label',{attrs:{"for":oBrand.iId}})])]),_c('div',{staticClass:"show-for-small-only eo-brand-name flex-1"},[_c('img',{staticClass:"brand-logo show-for-small-only",class:{
                rayban:
                  _vm.isRayBan(oBrand) ||
                  oBrand.iId === 258 ||
                  oBrand.iId === 254,
              },staticStyle:{"min-width":"2.5rem"},attrs:{"src":_vm.getBrandSvg(oBrand),"alt":""}})]),_c('div',{staticClass:"small-6 medium-12 fromprice flex-0 flex-shrink"},[_c('div',[_c('em',[_vm._v(_vm._s(_vm.txt('ls_from')))]),_vm._v(" "+_vm._s(_vm._f("formatPrice")(Math.max(0, oBrand.dMinPrice * 2)))+" ")])])])])}),_c('li',{staticClass:"m-fbrands-lastli"})],2),(_vm.getBrands.length > 3)?_c('div',{staticClass:"eo-brands-more"},[_c('span',{on:{"click":function($event){_vm.more = !_vm.more}}},[(_vm.more)?_c('b',[_vm._v(_vm._s(_vm.txt('ls_more')))]):_c('b',[_vm._v(_vm._s(_vm.txt('ls_less')))])])]):_vm._e()])])
}
var staticRenderFns = []

export { render, staticRenderFns }