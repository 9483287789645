export default {
  namespaced: true,

  state: {
    innerWidth: screen.width,
  },
  getters: {},
  mutations: {
    setInnerWidth(state, payload) {
      state.innerWidth = payload.innerWidth;
    },
  },
  actions: {
    updateInnerWidth({ commit }) {
      commit('setInnerWidth', { innerWidth: screen.width });
    },
  },
};
