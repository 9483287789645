<template>
  <div
    class="eo-prescription-pass-select"
    :class="{
      error:
        !fPropertyValue && getPlaceholder === txt('ls_required') && errorCode,
    }"
  >
    <multiselect
      ref="multiselect"
      v-model="fPropertyValue"
      class="select-multiselect"
      :options="aOptions"
      :show-labels="false"
      :allow-empty="false"
      label="label"
      track-by="value"
      :placeholder="getPlaceholder"
      open-direction="bottom"
      @open="scrollToValue"
    >
      <template slot="singleLabel" slot-scope="props">
        {{ props.option.label }}
      </template>
      <template slot="option" slot-scope="props">
        <div>
          {{ props.option.label }}
        </div>
      </template>
    </multiselect>
    <select v-model="fPropertyValue" class="select-select w-full border-black">
      <option value="" disabled selected hidden>
        {{ getPlaceholder }}
      </option>
      <option
        v-for="(option, i) in aOptions"
        :key="i"
        :value="{ label: option.label, value: option.value }"
      >
        {{ option.label }}
      </option>
    </select>
  </div>
</template>

<script>
import Multiselect from 'vue-multiselect';

import { mapState } from 'vuex';

import translation from '../../mixins/translation';

export default {
  name: 'PrescriptionPassSelect',
  components: { Multiselect },
  mixins: [translation],
  props: {
    property: {
      type: String,
      default: '',
    },
    unit: {
      type: String,
      default: '',
    },
    decimals: {
      type: Number,
      default: 0,
    },
    signed: {
      type: Boolean,
      default: false,
    },
    placeholder: {
      type: String,
      default: '',
    },
    optional: {
      type: Boolean,
      default: false,
    },
    loaded: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {};
  },
  computed: {
    ...mapState({
      errorCode: (state) => state.error.code,
    }),
    fPropertyValue: {
      get() {
        const query = this.$store.state.query;
        const value = query.oConditions[this.property];
        if (!value && value !== 0) {
          return false;
        }
        return this.aOptions.find((option) => {
          return option.value === Number(value);
        });
      },
      set(option) {
        const fValue = option.value;

        if (fValue !== null && fValue !== '') {
          this.$store.dispatch('query/setCondition', {
            sKey: this.property,
            mValue: fValue,
          });
        }
      },
    },
    oSettings() {
      return {
        highlight: false,
        selected: 1,
        placeholder: this.placeholder ? this.placeholder : '',
      };
    },

    getPlaceholder() {
      if (this.optional) {
        return this.txt('ls_optional');
      }
      return this.txt('ls_required');
    },

    aOptions() {
      const aOptions = [];
      const oFormatter = new Intl.NumberFormat('de-DE', {
        minimumFractionDigits: this.decimals ? this.decimals : 0,
      });
      const oComponent = this;
      const oFacetMap = {
        fSphereL: 'aSpheres',
        fSphereR: 'aSpheres',
        fCylinderL: 'aCylinders',
        fCylinderR: 'aCylinders',
        fAxisL: 'aAxes',
        fAxisR: 'aAxes',
        fAdditionL: 'aAddition',
        fAdditionR: 'aAddition',
      };
      let sProperty;
      if (oFacetMap[this.property]) {
        sProperty = oFacetMap[this.property];
      } else {
        sProperty = this.property;
      }

      const facet = this.$store.state.result.oFacets[sProperty];
      facet.aValues.forEach((value) => {
        let sPrefix = '';
        if (oComponent.signed) {
          if (value > 0) {
            sPrefix = '+ ';
          } else {
            sPrefix = value === 0 ? '± ' : '';
          }
        } else {
          sPrefix = '';
        }

        let sLabel = '';
        if (oComponent.decimals && oComponent.decimals > 0) {
          sLabel = oFormatter.format(value).replace('-', '- ');
        } else {
          sLabel = String(value);
        }

        let label = sPrefix + '' + sLabel;
        if (oComponent.unit && oComponent.unit.length > 0) {
          label = sPrefix + '' + sLabel + '' + oComponent.unit;
        }

        aOptions.push({ label, value });
      });
      return aOptions;
    },
  },
  mounted() {},
  methods: {
    scrollToValue() {
      setTimeout(() => {
        let propertyValue = { value: 0 };
        if (this.fPropertyValue) {
          propertyValue = this.fPropertyValue;
        }

        const index = this.aOptions.findIndex((option) => {
          return option.value === Number(propertyValue.value);
        });
        const element = this.$refs['multiselect'].$el.children[2];
        element.scrollTop = Math.min((index - 4) * 28);
      }, 0);
    },
  },
};
</script>

<style>
@media print, screen and (max-width: 880px) {
  .select-multiselect {
    display: none;
  }

  .select-select {
    display: block;
  }
}
@media print, screen and (min-width: 880px) {
  .select-multiselect {
    display: block;
  }

  .select-select {
    display: none;
  }
}

.eo-prescription-pass-select {
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 113px;
}

.eo-prescription-pass-select.error .multiselect__tags {
  border-color: red !important;
}

@media print, screen and (max-width: 380px) {
  .eo-prescription-pass-select {
    min-width: 83px;
  }
}

.multiselect {
  cursor: pointer;
}

select option[data-default] {
  color: #888;
}
</style>
