import Cookies from 'js-cookie';
import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store/index.js';

import VueCustomElement from 'vue-custom-element';
import VueSlideToggle from 'vue-slide-toggle';
import 'document-register-element/build/document-register-element';
import Notifications from 'vue-notification';
import VueScrollTo from 'vue-scrollto';

import './assets/css/tailwind.scss';

Vue.config.productionTip = false;

Vue.filter('formatPrice', function (fValue) {
  const currency = Cookies.get('currency') || 'EUR';
  const locale = Cookies.get('locale') || 'de_DE';

  return new Intl.NumberFormat(locale.replace('_', '-'), {
    style: 'currency',
    currency,
  }).format(fValue);
  // const val = (fValue / 1).toFixed(2).replace('.', ',');
  // return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.');
});

Vue.mixin({
  methods: {
    txt: function (sIndicator) {
      const getter = 'translation/getTranslation';
      const getTranslations = this.$store.getters[getter];
      if (!getTranslations) {
        return '';
      }
      return getTranslations[sIndicator];
    },
  },
});

Vue.use(VueCustomElement);
Vue.use(VueSlideToggle);
Vue.use(Notifications);
Vue.use(VueScrollTo, {
  x: false,
  y: true,
});

App.store = store;
App.router = router;

Vue.customElement('lens-system', App);
